// src/components/general/AllEventsDialog.tsx

import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogClose,
} from "../ui/dialog";
import { Card } from "../ui/card";
import { Badge } from "../ui/badge";
import { ScrollArea } from "../ui/scroll-area";
import { Button } from "../ui/button";
import dayjs from "dayjs";
import { DisplayHappening } from "./calendar-feature"; // Ensure this type includes 'variant', 'resourceUrl', etc.
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../ui/tabs";
import { DotsHorizontalIcon, BookmarkFilledIcon } from "@radix-ui/react-icons"
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
  } from "../ui/dropdown-menu"

interface AllEventsDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  groupedEvents: Record<string, DisplayHappening[]>;
}

const formatDate = (date: string | number | dayjs.Dayjs | Date | null | undefined) => {
  return dayjs(date).format("MMMM D") + dayjs(date).format("Do").slice(-2) + ", " + dayjs(date).format("YYYY");
};

const getHappeningBadgeText = (happening: DisplayHappening, selectedDate: Date): string => {
  const selectedDay = dayjs(selectedDate).startOf('day');
  const eventStart = dayjs(happening.starttime).startOf('day');
  const eventEnd = dayjs(happening.endtime).startOf('day');

  if (selectedDay.isSame(eventStart, 'day') && selectedDay.isSame(eventEnd, 'day')) {
    // Single-day event
    return happening.allday
      ? "All Day"
      : `${happening.starttime.format('h:mm A')} - ${happening.endtime.format('h:mm A')}`;
  } else if (selectedDay.isSame(eventStart, 'day')) {
    // Start day of multi-day event
    const lastDay = dayjs(eventEnd).format('M/D');
    return happening.allday
      ? "All Day"
      : `${happening.starttime.format('h:mm A')} - ${lastDay}`;
  } else if (selectedDay.isSame(eventEnd, 'day')) {
    // End day of multi-day event
    const firstDay = dayjs(eventStart).format('M/D');
    return happening.allday
      ? "All Day"
      : `${firstDay} - ${happening.endtime.format('h:mm A')}`;
  } else {
    const firstDay = dayjs(eventStart).format('M/D');
    const lastDay = dayjs(eventEnd).format('M/D');
    return `${firstDay} - ${lastDay}`;
  }
};

const AllEventsDialog: React.FC<AllEventsDialogProps> = ({ open, onOpenChange, groupedEvents }) => {

  // Helper functions to categorize events
  const categorizeEvents = () => {
    const today = dayjs().startOf('day');
    const upcoming: Record<string, DisplayHappening[]> = {};
    const previous: Record<string, DisplayHappening[]> = {};

    Object.entries(groupedEvents).forEach(([date, events]) => {
      const eventDate = dayjs(date, "MM/DD/YYYY").startOf('day');
      if (eventDate.isBefore(today)) {
        previous[date] = events;
      } else {
        upcoming[date] = events;
      }
    });

    return { upcoming, previous };
  };

  const { upcoming, previous } = categorizeEvents();

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="bg-white w-4/12 max-w-4/12 overflow-y-auto max-h-[90vh]">
        <DialogHeader>
          <DialogTitle>All Events & Deals</DialogTitle>
          <DialogDescription>
            Browse through all your events and deals, organized by day.
          </DialogDescription>
        </DialogHeader>
        <Tabs defaultValue="upcoming" className="w-full">
          <TabsList className="w-full grid grid-cols-2">
            <TabsTrigger value="upcoming" className="text-center text-xs">
              Upcoming
            </TabsTrigger>
            <TabsTrigger value="previous" className="text-center text-xs">
              Previous
            </TabsTrigger>
          </TabsList>
          <TabsContent value="upcoming">
            {Object.keys(upcoming).length === 0 ? (
              <p className="text-center text-sm text-gray-500 mt-4">No upcoming events.</p>
            ) : (
              <ScrollArea className="h-[60vh] w-full mt-2">
                <div className="space-y-4">
                  {Object.entries(upcoming).map(([date, events]) => (
                    <div key={date}>
                      <h2 className="text-sm font-bold pb-1 mb-2">{formatDate(date)}</h2>
                      {events.map((happening) => (
                        <Card key={happening.id} className="pt-2 pl-3 pr-3 pb-2.5 drop-shadow-sm mb-2">
                          <div className="flex items-center space-x-1">
                            {happening.variant === "event" && (
                              <Badge variant="outline" className="text-xs mr-1 mb-1 font-bold bg-green-500 text-white px-2">
                                EVENT
                              </Badge>
                            )}
                            {happening.variant === "deal" && (
                              <Badge variant="outline" className="text-xs mr-1 mb-1 font-bold bg-violet-500 text-white px-2">
                                DEAL
                              </Badge>
                            )}
                            <Badge
                              variant="outline"
                              className="text-xs mb-1 px-2"
                            >
                              {getHappeningBadgeText(happening, dayjs(date, "MM/DD/YYYY").toDate())}
                            </Badge>
                          </div>
                          <h3 className="text-sm font-bold">{happening.name}</h3>
                          <p className="text-xs text-gray-600">{happening.description}</p>
                          {happening.resource && (
                            <div>
                              <a href={happening.resource} target="_blank" rel="noopener noreferrer" className="text-blue-500 text-xs font-bold">
                                learn more »
                              </a>
                            </div>
                          )}
                        </Card>
                      ))}
                    </div>
                  ))}
                </div>
              </ScrollArea>
            )}
          </TabsContent>
          <TabsContent value="previous">
            {Object.keys(previous).length === 0 ? (
              <p className="text-center text-sm text-gray-500 mt-4">No previous events.</p>
            ) : (
              <ScrollArea className="h-[60vh] w-full mt-2">
                <div className="space-y-4">
                  {Object.entries(previous).map(([date, events]) => (
                    <div key={date}>
                      <h2 className="text-sm font-bold pb-1 mb-2">{formatDate(date)}</h2>
                      {events.map((happening) => (
                        <Card key={happening.id} className="pt-2 pl-3 pr-3 pb-2.5 drop-shadow-sm mb-2">
                          <div className="flex items-center space-x-1">
                            {happening.variant === "event" && (
                              <Badge variant="outline" className="text-xs mr-1 mb-1 font-bold bg-green-500 text-white px-2">
                                EVENT
                              </Badge>
                            )}
                            {happening.variant === "deal" && (
                              <Badge variant="outline" className="text-xs mr-1 mb-1 font-bold bg-violet-500 text-white px-2">
                                DEAL
                              </Badge>
                            )}
                            <Badge
                              variant="outline"
                              className="text-xs mb-1 px-2"
                            >
                              {getHappeningBadgeText(happening, dayjs(date, "MM/DD/YYYY").toDate())}
                            </Badge>
                          </div>
                          <h3 className="text-sm font-bold">{happening.name}</h3>
                          <p className="text-xs text-gray-600">{happening.description}</p>
                          {happening.resource && (
                            <div>
                              <a href={happening.resource} target="_blank" rel="noopener noreferrer" className="text-blue-500 text-xs font-bold">
                                Learn more »
                              </a>
                            </div>
                          )}
                        </Card>
                      ))}
                    </div>
                  ))}
                </div>
              </ScrollArea>
            )}
          </TabsContent>
        </Tabs>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">Close</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default AllEventsDialog;
