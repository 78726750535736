import { collection, getDocs } from "firebase/firestore";
import { db } from '../config/firebase';
import { Building, HoursPeriod, HoursProfile, CalendarEvent, DayNote, Banner, Resource, Happening } from '../lib/types';
import dayjs from 'dayjs';

export async function fetchBuildings(schoolId: string): Promise<Building[]> {
    try {
        const buildingsCollection = collection(db, "schools", schoolId, "facilities");
        const buildingSnapshot = await getDocs(buildingsCollection);
    
        const buildingsList: Building[] = await Promise.all(buildingSnapshot.docs.map(async doc => {
            const buildingData = doc.data();
            const profilesCollection = collection(doc.ref, "profiles");
            const profilesSnapshot = await getDocs(profilesCollection);

            const eventsCollection = collection(doc.ref, "events");
            const eventsSnapshot = await getDocs(eventsCollection);

            const notesCollection = collection(doc.ref, "notes");
            const notesSnapshot = await getDocs(notesCollection);

            const resourcesCollection = collection(doc.ref, "resources");
            const resourcesSnapshot = await getDocs(resourcesCollection);

            const happeningsCollection = collection(doc.ref, "happenings");
            const happeningsSnapshot = await getDocs(happeningsCollection);

            const profiles = await Promise.all(profilesSnapshot.docs.map(async profileDoc => {
                const profileData = profileDoc.data();

                const periodsCollection = collection(profileDoc.ref, "periods");
                const periodsSnapshot = await getDocs(periodsCollection);
                const periods = periodsSnapshot.docs.map(periodDoc => {
                    const periodData = periodDoc.data();
                    return {
                        id: periodDoc.id,
                        ...periodData,
                        starttime: dayjs(periodData.starttime),
                        endtime: dayjs(periodData.endtime)
                    } as HoursPeriod;
                });

                return {
                    id: profileDoc.id,
                    ...profileData,
                    periods
                } as HoursProfile;
            }));

            const events = eventsSnapshot.docs.map(eventDoc => {
                const eventData = eventDoc.data();
                return {
                    id: eventDoc.id,
                    profileId: eventData.profileId,
                    ...eventData,
                    startDate: dayjs(eventData.startDate),
                    endDate: eventData.endDate ? dayjs(eventData.endDate) : undefined
                } as CalendarEvent;
            });

            const notes = notesSnapshot.docs.map(noteDoc => {
                const noteData = noteDoc.data();
                return {
                    id: noteDoc.id,
                    ...noteData,
                    date: dayjs(noteData.date)
                } as DayNote;
            });

            const resources = resourcesSnapshot.docs.map(resourceDoc => {
                const resourceData = resourceDoc.data();
                return {
                    id: resourceDoc.id,
                    ...resourceData,
                } as Resource;
            });
            
            const happenings = happeningsSnapshot.docs.map(happeningDoc => {
                const happeningData = happeningDoc.data();
                return {
                    id: happeningDoc.id,
                    ...happeningData,
                    starttime: dayjs(happeningData.starttime),
                    endtime: dayjs(happeningData.endtime),
                } as Happening;
            });

            const banner: Banner = buildingData.banner || {
                enabled: false,
                variant: 'info',
                message: ''
            };

            return {
                id: doc.id,
                fullname: buildingData.fullname,
                shortname: buildingData.shortname,
                description: buildingData.description,
                address: buildingData.address,
                contact: buildingData.contact,
                phone: buildingData.phone,
                parentId: buildingData.parentId,
                category: buildingData.category,
                website: buildingData.website,
                profiles,
                events,
                notes,
                resources,
                happenings,
                banner
            } as Building;
        }));

        return buildingsList;
    } catch (error) {
        console.error("Error fetching buildings: ", error);
        return [];
    }
}
