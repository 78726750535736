import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { Cross2Icon } from "@radix-ui/react-icons";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "../ui/card";
import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from "../ui/table";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogClose, DialogTrigger } from "../ui/dialog";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { Button } from "../ui/button";
import { HoursProfile, HoursPeriod } from "../../lib/types";
import { Cross } from "lucide-react";
import { Space, TimePicker } from 'antd';
import dayjs, {Dayjs} from 'dayjs';
import { CircleHelp, PlusCircle } from "lucide-react";
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat);

interface PeriodsSelectorProps {
    options: HoursProfile | null;
    onUpdate: (updatedOptions: HoursProfile) => void;
    isNew?: boolean;
}

export default function PeriodsSelector({ options, onUpdate, isNew=false}: PeriodsSelectorProps) {
    const baseProfile: HoursProfile = {
        id: uuidv4(),
        name: "",
        description: "",
        color: "",
        periods: []
    };
    const [profile, setProfile] = useState<HoursProfile>(options || baseProfile);
    const [periodRows, setPeriodRows] = useState<HoursPeriod[]>(isNew ? [] : profile.periods || []);

    const addPeriodRow = () => {
        ////console.log("adding new period row");
        const newPeriodRow: HoursPeriod = {
            id: uuidv4(),
            name: "",
            description: "",
            starttime: dayjs().hour(9).minute(0), // Default start time
            endtime: dayjs().hour(17).minute(0), // Default end time
        };
        setPeriodRows([...periodRows, newPeriodRow]);
        onUpdate({ ...profile, periods: periodRows });
    };

    const removePeriodRow = (id: string) => {
        const updatedPeriodRows = periodRows.filter(row => row.id !== id);
        setPeriodRows(updatedPeriodRows);
        onUpdate({ ...profile, periods: updatedPeriodRows });
    };

    const updatePeriodRow = (id: string, updatedRow: HoursPeriod) => {
        //("registering update period row");
        const updatedPeriodRows = periodRows.map(row => {
            if (row.id === id) {
                return updatedRow;
            }
            return row;
        });
        setPeriodRows(updatedPeriodRows);
        ////console.log("finished update period row");
        onUpdate({ ...profile, periods: updatedPeriodRows });
    };

    const handleTimeChange = (id: string, dates: [dayjs.Dayjs | null, dayjs.Dayjs | null], dateStrings: [string, string]) => {
        ////console.log("you changed a time");
        const updatedPeriodRows = periodRows.map(row => {
            if (row.id === id) {
                //("sending to update period row" + row.id);
                ////console.log(dateStrings);
                return { ...row, starttime: dates[0] || dayjs().hour(9).minute(0), endtime: dates[1] || dayjs().hour(17).minute(0) };
            }
            return row;
        });
        setPeriodRows(updatedPeriodRows);
        ////console.log("finished update time row");
        onUpdate({ ...profile, periods: updatedPeriodRows });
    };

    return (
        <div>
            <DialogHeader className="mb-6 mt-2">
                <div className="flex items-center space-x-2">
                    <DialogTitle>Customize Periods</DialogTitle>
                    <Dialog>
                        <DialogTrigger asChild>
                            <CircleHelp className="h-4 w-4 mb-0.2 color-gray align-top" style={{ color: 'gray' }} />
                        </DialogTrigger>
                        <DialogContent className="bg-white w-4/12 max-w-6/12 overflow-y-scroll max-h-[90vh]">
                            <DialogHeader>
                                <DialogTitle>What are periods?</DialogTitle>
                                <DialogDescription>
                                    A period is a time range during this specific daily profile that a facility is open — for example, "lunch." Many facilities will have multiple open periods spread throughout the day (e.g., "Lunch" and, a few hours later, "Dinner"). Others may be open without interruption (e.g., one period named "Open" from 9:00 AM to 5:00 PM). Note that periods may overlap (e.g. "Hot Lunch" from 12:00 PM to 1:00 PM and "Salad Bar" from 12:30 PM to 1:30 PM).
                                </DialogDescription>
                            </DialogHeader>
                        </DialogContent>
                    </Dialog>
                </div>
            </DialogHeader>
            {periodRows.map(row => (
                <div key={row.id} className="p-2 bg-white rounded-lg drop-shadow-md mb-4 border border-stroke">
                    <div className="flex items-center space-x-1 mb-2">
                        <Input
                            className="w-6/12"
                            value={row.name}
                            onChange={e => updatePeriodRow(row.id, { ...row, name: e.target.value })}
                            placeholder="Name"
                        />
                        <TimePicker.RangePicker
                            className="w-5/12 py-1.5"
                            value={[row.starttime, row.endtime] as [Dayjs | null, Dayjs | null]}
                            id={row.id}
                            use12Hours
                            format="h:mm a"
                            allowClear={false}
                            onChange={(dates, dateStrings) => handleTimeChange(row.id, dates as [Dayjs | null, Dayjs | null], dateStrings)}
                        />
                        <Button 
                            variant="ghost" 
                            className="flex-grow-0 flex-shrink-0 w-1/12 h-full" 
                            onClick={() => removePeriodRow(row.id)}
                        >
                            <Cross2Icon className="h-4 w-4" style={{ color: 'red' }} />
                        </Button>
                    </div>
                    <div className="flex items-center space-x-2">
                        <Input
                            value={row.description}
                            onChange={e => updatePeriodRow(row.id, { ...row, description: e.target.value })}
                            placeholder="Description (optional)"
                        />
                    </div>
                </div>
            ))}
            <Button className="mt-2 self-end" onClick={addPeriodRow} variant="outline">
                <div className="flex items-center space-x-0">
                    <PlusCircle className="h-4 w-4 color-gray align-top mr-2" />
                    <p>Add Period</p>
                </div>
            </Button>
        </div>
    );
}