import React, { useState, useEffect } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { Calendar } from '../ui/calendar'; // Assume you're using this calendar
import { Card } from '../ui/card'; // ShadCN card component
import { TabsContent } from '../ui/tabs'; // ShadCN TabsContent component
import { Building, HoursProfile, HoursPeriod } from '@/lib/types'; // Import your data types
import { Badge } from '../ui/badge';
import { DotFilledIcon } from '@radix-ui/react-icons';

interface FacilityHoursProps {
    building: Building;
}

const FacilityHours: React.FC<FacilityHoursProps> = ({ building }) => {
    const [theDate, setTheDate] = React.useState<Date | undefined>(new Date());
    const [selectedProfile, setSelectedProfile] = useState<HoursProfile | null>(null);
    const [selectedPeriod, setSelectedPeriod] = useState<HoursPeriod | null>(null);
    const [currentProfile, setCurrentProfile] = useState<HoursProfile | null>(null);
    const [currentPeriod, setCurrentPeriod] = useState<HoursPeriod | null>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const modifiers: Record<string, Date[]> = {};

    // Function to find the active profile for a specific date
    const findActiveProfile = (selectedDate: Dayjs) => {
        if (!building.events || building.events.length === 0) {
            return null; // No events available
        }
    
        // Look for a matching event
        const matchingEvent = building.events.find(event => {
            if (!event.doesRepeat) {
                // If the event does not repeat, just check if the startDate matches the selectedDate
                return selectedDate.isSame(event.startDate, 'day');
            } else if (event.recurrence && event.recurrence.actualOccurences) {
                // If the event repeats, check if selectedDate is in the actual occurrences
                return event.recurrence.actualOccurences.some(occurrence => 
                    selectedDate.isSame(occurrence.date, 'day')
                );
            }
            return false; // No match found
        });
    
        // If a matching event is found, find the associated profile
        if (matchingEvent) {
            return building.profiles.find(profile => profile.id === matchingEvent.profileId) || null;
        }
    
        return null; // No matching event or profile found
    };

    // Function to find the active period for the current time
    const findCurrentPeriod = (profile: HoursProfile) => {
        if (!profile.periods || profile.periods.length === 0) {
            return null; // No periods available, implying the facility is closed all day
        }
    
        const now = dayjs(); // Get current time
        return profile.periods.find(period => 
            now.isAfter(period.starttime) && now.isBefore(period.endtime)
        ) || null;
    };

    // Effect to update profile and period when date changes
    useEffect(() => {
        const date = dayjs(theDate);
        const activeProfile = findActiveProfile(date);
        setSelectedProfile(activeProfile);
        if (date.isSame(dayjs(), 'day')) {
            setCurrentProfile(activeProfile);
        }

        if (activeProfile) {
            const activePeriod = findCurrentPeriod(activeProfile);
            setSelectedPeriod(activePeriod);
            if (date.isSame(dayjs(), 'day')) {
                setCurrentPeriod(activePeriod);
                setIsOpen(!!activePeriod); // If there's an active period, the facility is open
            }
        } else {
            setCurrentPeriod(null);
            setSelectedPeriod(null);
            setIsOpen(false); // No active profile, so facility is closed
        }
    }, [theDate, building.profiles]);

    building.events.forEach(event => {
        const profile = building.profiles.find(profile => profile.id === event.profileId);
        if (profile) {
            const colorClass = `profile-color-${profile.color.replace('#', '')}`;

            if (!modifiers[colorClass]) {
                modifiers[colorClass] = [];
            }

            if (event.doesRepeat && event.recurrence?.actualOccurences) {
                event.recurrence.actualOccurences.forEach(occurrence => {
                    modifiers[colorClass].push(occurrence.date);
                });
            } else {
                modifiers[colorClass].push(event.startDate);
            }
        }
    });

    // Inject dynamic styles for each profile color
    useEffect(() => {
        if (building.profiles) {
            building.profiles.forEach(profile => {
                const color = profile.color.replace('#', '');
                if (!document.getElementById(`profile-style-${color}`)) {
                    const style = document.createElement('style');
                    style.id = `profile-style-${color}`;
                    style.innerHTML = `
                        .profile-color-${color} {
                            background-color: ${profile.color} !important;
                            color: white !important;
                        }
                    `;
                    document.head.appendChild(style);
                    console.log("hello");
                }
            });
        }
    }, [building.profiles]);

    return (
        <TabsContent value="facility-hours" className="mt-4">
            <div className="flex">
                {/* Calendar */}
                <Calendar
                    mode="single"
                    selected={theDate}
                    onSelect={setTheDate}
                    className="rounded-md border"
                    modifiers={modifiers} // Add profile colors as modifiers
                />

                {/* Hours Information */}
                <div className="flex-grow h-full pl-4">
                    {/* Profile Name and Description */}
                    {selectedProfile ? (
                        <div>
                            {/* Current Open/Closed Status */}
                            <div className="mb-3">
                                {isOpen ? (
                                   <Badge variant="default" className='bg-green-500 pl-1 pr-2'>
                                        <style jsx>{`
                                        @keyframes blink {
                                            0% { opacity: 1; }
                                            50% { opacity: 0.4; }
                                            100% { opacity: 1; }
                                        }

                                        .blinking-dot {
                                            animation: blink 2.5s infinite;
                                        }
                                        `}</style>

                                        <DotFilledIcon className='pl-0 ml-0 h-4 w-4 blinking-dot' /> 
                                        <div className='mr-1'>CURRENTLY OPEN </div>{" "}
                                        <div className='font-light'> until {dayjs(currentPeriod?.endtime).format('h:mm A')}</div>
                                    </Badge>
                                ) : (
                                    <Badge variant="destructive" className='pl-1 pr-2'>
                                        <style jsx>{`
                                        @keyframes blink {
                                            0% { opacity: 1; }
                                            50% { opacity: 0.4; }
                                            100% { opacity: 1; }
                                        }

                                        .blinking-dot {
                                            animation: blink 2.5s infinite;
                                        }
                                        `}</style>

                                        <DotFilledIcon className='pl-0 ml-0 h-4 w-4 blinking-dot' /> 
                                        CURRENTLY CLOSED
                                    </Badge>
                                )}
                            </div>
                            <Card className='w-full p-3'>
                                <p className='text-xs font-semibold mb-2'>for {dayjs(theDate).format("MMMM Do, YYYY")}</p>
                                <div className='border-l-4 pl-2 py-1' style={{ borderColor: selectedProfile.color }}>
                                    <h2 className="text-md font-bold">{selectedProfile.name}</h2>
                                    <p className="text-xs text-gray-500">{selectedProfile.description}</p>
                                </div>

                                {/* List of Periods */}
                                <div className="mt-4 text-sm">
                                    {selectedProfile.periods && selectedProfile.periods.length > 0 ? (
                                        <ul>
                                            {selectedProfile.periods.map((period) => (
                                                <li className="pb-2" key={period.id}>
                                                    <span className='font-bold text-xs text-gray-500 pr-1.5'>{period.name.toLowerCase()}</span> 
                                                    <Badge variant="outline">{dayjs(period.starttime).format('h:mm A')}</Badge> 
                                                    {"  -  "}<Badge variant="outline">{dayjs(period.endtime).format('h:mm A')}</Badge>
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <p>The facility is closed all day.</p>
                                    )}
                                </div>
                            </Card>
                        </div>
                    ) : (
                        <p className="text-sm text-gray-500">No hours data is available for this date.</p>
                    )}
                </div>
            </div>
        </TabsContent>
    );
};

export default FacilityHours;
