"use client"

import {
  ColumnDef,
  flexRender,
  SortingState,
  getSortedRowModel,
  getCoreRowModel,
  ColumnFiltersState,
  getFilteredRowModel,
  useReactTable,
  getPaginationRowModel,
} from "@tanstack/react-table"
import * as React from "react"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table"
import { Button } from "../../../components/ui/button"
import { Input } from "../../../components/ui/input"
import BuildingDialog from "../../../components/general/building-dialog"; // Import your dialog component
import { Building } from "@/lib/types"
import { PlusCircleIcon } from "lucide-react"
import { addBuildingToFirebase } from "../../../lib/savebuilding"
import { backupAndDeleteFacilities } from "../../../lib/deletefacilities"
import { getColumns } from "./columns"
import { LoadingSpinner } from "../../../components/general/loading"
import { useToast } from "../../../components/ui/use-toast"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogClose,
  DialogTrigger,
} from "../../../components/ui/dialog"
import { useState } from "react"
import { useAuth } from '../../../context/AuthContext';

interface DataTableProps<TData, TValue> {
  columns: (refreshBuildings: () => void) => ColumnDef<TData, TValue>[]; // Change columns type to a function
  data: TData[];
  refreshBuildings: () => void; // Add refreshBuildings as a prop
}

export function DataTable<TData, TValue>({
  columns,
  data,
  refreshBuildings, // Destructure it here
}: DataTableProps<TData, TValue>) {
  const [sorting, setSorting] = React.useState<SortingState>([])
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  )
  const [rowSelection, setRowSelection] = React.useState({})
  const { toast } = useToast();
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [selectedBuilding, setSelectedBuilding] = React.useState<Building | null>(null);
  const { user } = useAuth();
  const school = user?.customClaims?.school;
  
  const table = useReactTable({
    data,
    columns: columns(refreshBuildings),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      rowSelection,
    },
  })

  const selectedRowCount = Object.keys(rowSelection).length;
  const selectedBuildingIds = Object.keys(rowSelection);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

  const [isLoading, setIsLoading] = React.useState(true); // Add loading state

  React.useEffect(() => {
    if (data.length) {
      setIsLoading(false); // Data is loaded
    }
  }, [data]);

  const handleDeleteBuildings = async () => {
    // Get the selected rows
    const selectedRows = Object.keys(rowSelection);
    
    // Map selected rows to the actual building IDs
    const selectedBuildingIds = selectedRows.map((rowId) => {
      const row = table.getRowModel().rows.find((r) => r.id === rowId);
      return row?.original.id; // Get the building's ID from the original data
    }).filter(Boolean); // Filter out any undefined values
    
    if (selectedBuildingIds.length > 0) {
      try {
        // Call the backup and delete function with the selected building IDs
        await backupAndDeleteFacilities(selectedBuildingIds, school);
        console.log("Selected buildings backed up and deleted:", selectedBuildingIds);
  
        // Optionally, refresh the table or provide feedback to the user after deletion
        await refreshBuildings();
        toast({
            variant: "destructive",
            title: "Facilities deleted.",
            description: "They're no longer in our system.",
        })
      } catch (error) {
        console.error("Error deleting buildings:", error);
      }
    } else {
      console.log("No buildings selected for deletion.");
    }
  };


  return (
    <div>
        <div className="flex items-center justify-end space-x-2 py-2 pb-6">
          <div className="flex-1 items-center">
              <Input
              placeholder="Filter by facility name..."
              value={(table.getColumn("fullname")?.getFilterValue() as string) ?? ""}
              onChange={(event) =>
                  table.getColumn("fullname")?.setFilterValue(event.target.value)
              }
              className="max-w-sm"
              />
          </div>
            <div>
              <BuildingDialog
                building={selectedBuilding}  // Pass null when adding a new building
                facilityOptions={data as unknown as Building[]}  // List of buildings
                onSave={async (newBuilding) => {
                  try {
                    await addBuildingToFirebase(newBuilding, school);  // Save to Firebase (you will implement this)
                    console.log("New building added:", newBuilding);
                    await refreshBuildings();  // Refresh the table after adding a building
                    toast({
                        variant: "celebratory",
                        title: "Facility created!",
                        description: "It's time to assign its first managers.",
                    })
                  } catch (error) {
                    console.error("Error adding building:", error);
                  }
                }}
                trigger={<Button
                  variant="outline"
                  size="sm"
                  onClick={() => {
                    setSelectedBuilding(null);  // Pass null to indicate we're adding a new building
                    setIsDialogOpen(true);
                    console.log(isDialogOpen);  // Open the dialog
                  }}
                  >
                    <PlusCircleIcon className="h-[0.8rem] w-[0.8rem] mr-2"></PlusCircleIcon>
                  Add Building
                  </Button>}  // No trigger needed
              />
              </div>

            {/* Conditionally render the buttons based on selected rows */}
            {selectedRowCount > 0 && (
              <>
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => console.log("Assign managers")}
                >
                  Assign Managers
                </Button>

                <Button
                  variant="destructive"
                  size="sm"
                  onClick={() => setDeleteConfirmationOpen(true)}  // Call the delete function here
                >
                  Delete
                </Button>
              </>
            )}
        </div>

        <Dialog open={deleteConfirmationOpen} onOpenChange={setDeleteConfirmationOpen}>
        <DialogContent className="w-5/12">
          <DialogHeader>
            <DialogTitle>Confirm Deletion</DialogTitle>
            <DialogDescription>
              Are you sure you want to delete these facilities? You can't undo this.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <DialogClose asChild>
              <Button type="button" onClick={handleDeleteBuildings} variant="destructive">
                Confirm Delete
              </Button>
            </DialogClose>
            <DialogClose asChild>
              <Button type="button" onClick={() => setDeleteConfirmationOpen(false)}>
                Cancel
              </Button>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </Dialog>
        
        <div className="rounded-md border">
        <Table>
            <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                    return (
                    <TableHead key={header.id}>
                        {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                            )}
                    </TableHead>
                    )
                })}
                </TableRow>
            ))}
            </TableHeader>
            <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={7} className="h-10 w-full text-center">
                  <div className="flex justify-center items-center py-10 h-full">
                    <LoadingSpinner />
                  </div>
                </TableCell>
              </TableRow>
            ) : table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                >
                    {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                    ))}
                </TableRow>
                ))
            ) : (
                <TableRow>
                  <TableCell colSpan={7} className="h-10 w-full text-center">
                    <div className="flex justify-center items-center py-10 h-full">
                       No facilities matched that name.
                    </div>
                  </TableCell>
                </TableRow>
            )}
            </TableBody>
        </Table>
        </div>
        <div className="flex items-center justify-end space-x-2 py-4">
            <p className="flex-1 text-xs pl-1 text-muted-foreground">
                {table.getFilteredSelectedRowModel().rows.length} of{" "}
                {table.getFilteredRowModel().rows.length} row(s) selected.
            </p>
            <Button
            variant="outline"
            size="sm"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
            >
            Previous
            </Button>
            <Button
            variant="outline"
            size="sm"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
            >
            Next
            </Button>

           
        </div>
  </div>
  )
}