// src/components/general/UserActions.tsx

import React, { useState } from 'react';
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
} from '../ui/dropdown-menu';
import { Button } from '../ui/button';
import { MoreHorizontal } from 'lucide-react';
import { useToast } from '../ui/use-toast';
import { useAuth } from '../../context/AuthContext';
import { httpsCallable, getFunctions } from 'firebase/functions';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogClose,
} from '../ui/dialog';

import { User } from '../../lib/types'; // Ensure the User type is correctly defined

interface UserActionsProps {
  user: User; // The user object
  refreshUsers: () => void | Promise<void>; // Function to refresh the users list
  handleOpenDialog: (user: User) => void; // Function to handle opening the edit dialog (currently does nothing)
}

const UserActions: React.FC<UserActionsProps> = ({ user, refreshUsers, handleOpenDialog }) => {
  const { toast } = useToast();
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const { user: currentUser } = useAuth();
  const school = currentUser?.customClaims?.school;
  const functions = getFunctions();

  const handleDelete = async () => {
    try {
      // Call the Firebase Function to delete the user
      const deleteUser = httpsCallable(functions, 'deleteUser');

      await deleteUser({ email: user.email, school: school });

      toast({
        variant: 'destructive',
        title: 'User Deleted',
        description: `User ${user.email} has been deleted successfully.`,
      });

      refreshUsers(); // Refresh the users list
      setDeleteConfirmationOpen(false); // Close the confirmation dialog
    } catch (error: any) {
      console.error(`Error deleting user ${user.email}:`, error);
      toast({
        variant: 'destructive',
        title: 'Deletion Failed',
        description: error.message || 'An error occurred while deleting the user.',
      });
    }
  };

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="h-8 w-8 p-0">
            <span className="sr-only">Open menu</span>
            <MoreHorizontal className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuLabel>Actions</DropdownMenuLabel>
          <DropdownMenuItem onClick={() => handleOpenDialog(user)}>
            Edit facilities
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem
            className="text-red-500 cursor-pointer"
            onClick={() => setDeleteConfirmationOpen(true)}
          >
            Delete User
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteConfirmationOpen} onOpenChange={setDeleteConfirmationOpen}>
        <DialogContent className="w-5/12">
          <DialogHeader>
            <DialogTitle>Confirm Deletion</DialogTitle>
            <DialogDescription>
              Are you sure you want to delete user <strong>{user.email}</strong>? This action cannot be undone.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <DialogClose asChild>
              <Button type="button" onClick={handleDelete} variant="destructive">
                Confirm Delete
              </Button>
            </DialogClose>
            <DialogClose asChild>
              <Button type="button" onClick={() => setDeleteConfirmationOpen(false)}>
                Cancel
              </Button>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UserActions;
