import { Skeleton } from "../ui/skeleton";

function SkeletonComponent() {
  return (
    <div className="rounded-lg border text-card-foreground shadow-sm bg-white">
      <div className="flex flex-col space-y-1.5 p-6">
        <Skeleton className="h-8 w-3/4" /> {/* Skeleton for the h3 element */}
        <Skeleton className="h-4 w-full" /> {/* Skeleton for the first line of the p element */}
        <Skeleton className="h-4 w-1/2" /> {/* Skeleton for the second line of the p element */}
        <Skeleton className="h-4 w-1/4" /> {/* Skeleton for the button element */}
      </div>
    </div>
  );
}

export default SkeletonComponent;
