import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogClose,
  DialogTrigger,
} from "../ui/dialog";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { XIcon, PlusCircleIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { Building, Resource } from "@/lib/types"; // Assuming the Building and Resource interfaces are here
import { v4 as uuidv4 } from "uuid";
import { useToast } from "../ui/use-toast"; // Import your toast hook

interface EditResourceLinksDialogProps {
  building: Building;
  onUpdate: (updatedBuilding: Building) => void;
  isOpen: boolean; // State for controlling open/close
  setIsOpen: (open: boolean) => void; // Function to update the open state
}

const EditResourceLinksDialog: React.FC<EditResourceLinksDialogProps> = ({ building, onUpdate, isOpen, setIsOpen }) => {
  const [resourceBlocks, setResourceBlocks] = useState<Resource[]>([]);
  const { toast } = useToast();

  useEffect(() => {
    setResourceBlocks(building.resources || []);
  }, [building.resources]);

  const addResourceBlock = () => {
    setResourceBlocks([...resourceBlocks, { id: uuidv4(), title: "", url: "" }]);
  };

  const updateResourceBlock = (index: number, updatedResource: Resource) => {
    const updatedBlocks = resourceBlocks.map((resource, i) => (i === index ? updatedResource : resource));
    setResourceBlocks(updatedBlocks);
  };

  const removeResourceBlock = (index: number) => {
    const updatedBlocks = resourceBlocks.filter((_, i) => i !== index);
    setResourceBlocks(updatedBlocks);
  };

  const handleSave = () => {
    // Filter out resources with empty title or URL
    const validResources = resourceBlocks.filter(
      (resource) => resource.title.trim() !== "" && resource.url.trim() !== ""
    );

    // Optionally, notify the user about the removed invalid resources
    if (validResources.length !== resourceBlocks.length) {
      toast({
        variant: "destructive",
        title: "Incomplete resources removed.",
        description: "Only resources with both title and URL have been saved.",
      });
    }

    onUpdate({
      ...building,
      resources: validResources,
    });
    setResourceBlocks(validResources);
    setIsOpen(false); // Close the dialog after saving
    toast({
      variant: "default",
      title: "Resources saved.",
      description: "Remember to hit save for your facility!",
    });
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="bg-white w-7/12 max-w-7/12">
        <DialogHeader>
          <DialogTitle>Edit Resource Links</DialogTitle>
          <DialogDescription>
            Manage links to external resources such as menus or your department's webpage.
          </DialogDescription>
        </DialogHeader>
        <div className="space-y-3">
          {resourceBlocks.map((resource, index) => (
            <div key={index} className="p-2 bg-white rounded-lg drop-shadow-sm mb-1 border border-stroke">
              <div className="flex items-center space-x-1">
                <Input
                  className="w-4/12"
                  value={resource.title}
                  onChange={(e) => updateResourceBlock(index, { ...resource, title: e.target.value })}
                  placeholder="Resource Name"
                />
                <Input
                  className="w-7/12 text-blue-500"
                  value={resource.url}
                  onChange={(e) => updateResourceBlock(index, { ...resource, url: e.target.value })}
                  placeholder="Resource URL"
                />
                <Button
                  variant="outline"
                  className="flex-grow-0 w-1/12 flex-shrink-0 h-full"
                  onClick={() => removeResourceBlock(index)}
                >
                  <XIcon className="h-4 w-4" style={{ color: "red" }} />
                </Button>
              </div>
            </div>
          ))}
          <div className="mt-2">
            <Button onClick={addResourceBlock} variant="outline" size="sm">
              <PlusCircleIcon className="h-4 w-4 mr-2" />
              Add Resource Link
            </Button>
          </div>
        </div>
        <DialogFooter>
          <Button variant="outline" onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default EditResourceLinksDialog;
