import { collection, getDocs } from "firebase/firestore";
import { db } from '../config/firebase';
import { User } from './types';

export async function fetchUsers(schoolId: string): Promise<User[]> {
    try {
        // Reference to the users collection within the specific school
        const usersCollection = collection(db, "schools", schoolId, "users");
        const usersSnapshot = await getDocs(usersCollection);

        // Map through the snapshot and convert to the User type
        const usersList: User[] = usersSnapshot.docs.map(doc => {
            const userData = doc.data();
            return {
                email: userData.email,
                role: userData.role,
            } as User;
        });

        return usersList;
    } catch (error) {
        console.error("Error fetching users: ", error);
        return [];
    }
}