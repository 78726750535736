import BuildingDialog from "../general/building-dialog"; // Import the new dialog component
import {
  Card,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../ui/card";
import { Building } from "../../lib/types";

interface BuildingInformationProps {
  options: Building;
  facilityOptions: Building[];
  onUpdate: (updatedOptions: Building) => void;
}

export default function BuildingInformation({
  options,
  facilityOptions,
  onUpdate,
}: BuildingInformationProps) {
  const arr = [options.description, <br />, options.address];

  return (
    <Card className="bg-white">
      <CardHeader>
        <CardTitle>{options.fullname}</CardTitle>
        <CardDescription className="line-clamp-2">{arr}</CardDescription>
        <BuildingDialog
          building={options}
          facilityOptions={facilityOptions}
          onSave={onUpdate}
          trigger={<button className="flex items-center text-xs text-gray-500">edit building information</button>}
        />
      </CardHeader>
    </Card>
  );
}
