// src/pages/PublicRoute/PublicRoute.tsx

import React, { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from "../../context/AuthContext";

interface PublicRouteProps {
  element: ReactElement;
  redirectTo?: string;
}

const PublicRoute: React.FC<PublicRouteProps> = ({ element, redirectTo = '/console' }) => {
  const { user, loading } = useAuth();

  // Debugging Logs
  console.log("PublicRoute - Loading:", loading);
  console.log("PublicRoute - User:", user);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (user) {
    const school = user.customClaims?.school;
    console.log(`PublicRoute - Redirecting to: /${school}/console`);
    if (school) {
      return <Navigate to={`/${school}/console`} replace />;
    } else {
      // Optional: Handle cases where the user doesn't have a school association
      return <Navigate to="/not-found" replace />;
    }
  }

  return element;
};

export default PublicRoute;