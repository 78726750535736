import '../../App.css';
import MenuBar from '../../components/menu-bar';
import { Link, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import {
    Card,
    CardDescription,
    CardHeader,
    CardTitle,
} from "../../components/ui/card"
import { fetchBuildings } from '../../lib/fetchbuildings';
import { Building } from '@/lib/types';
import { useState, useEffect } from 'react';
import { Input } from "../../components/ui/input"
import { MapPinIcon } from "lucide-react";
import { useAuth } from '../../context/AuthContext';
import Footer from '../../components/footer-main';

const categoryColors = {
    DINING: "bg-green-100 text-green-800",
    CAFE: "bg-teal-100 text-teal-800",
    SHOP: "bg-orange-100 text-orange-800",
    ACADEMIC: "bg-blue-100 text-blue-800",
    RESIDENTIAL: "bg-yellow-100 text-yellow-800",
    RECREATIONAL: "bg-purple-100 text-purple-800",
    ATHLETIC: "bg-red-100 text-red-800",
    ADMINISTRATIVE: "bg-gray-100 text-gray-800",
    OTHER: "bg-indigo-100 text-indigo-800",
  };

const getCurrentDate = () => {
    const now = dayjs();
    const day = now.date();
    let suffix;

    if (day % 10 === 1 && day !== 11) {
        suffix = 'st';
    } else if (day % 10 === 2 && day !== 12) {
        suffix = 'nd';
    } else if (day % 10 === 3 && day !== 13) {
        suffix = 'rd';
    } else {
        suffix = 'th';
    }

    return now.format(`MMMM D[${suffix}].`);
};

function ManagementPage() {
    const [buildingOptions, setBuildingOptions] = useState<Building[]>([]);
    const [filterText, setFilterText] = useState<string>("");
    const [activeCategory, setActiveCategory] = useState<string>(""); // New state for category filter

    const handleFilterTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilterText(e.target.value);
    };

    // Filter buildings by category and search text
    const filteredBuildings = buildingOptions.filter((building) => {
        const matchesSearch = building.fullname.toLowerCase().includes(filterText.toLowerCase());
        const matchesCategory = activeCategory === "" || building.category === activeCategory;
        return matchesSearch && matchesCategory;
    });

    const navigate = useNavigate();
    const { user } = useAuth();
    const school = user?.customClaims?.school;

    const handleCardClick = (buildingId: string) => {
        navigate(`/${school}/hours?buildingId=${buildingId}`); // Navigate to hours page with query parameter
    };

    useEffect(() => {
        async function loadBuildings() {
            const buildings = await fetchBuildings(school);
            setBuildingOptions(buildings);
        }

        loadBuildings();
    }, []);

    return (
        <div className="flex flex-col h-screen">
            <MenuBar />
            <div className="flex flex-grow">

                {/* Sidebar */}
                <div className="space-y-4 pl-[4rem] pt-10 flex flex-col justify-between h-full w-1/6">
                    <div>
                        <h2 className="text-3xl font-bold tracking-tight">Manage</h2>
                        <p className="text-gray-500 text-sm mb-5 font-semibold">{getCurrentDate()}</p>

                        <nav className="flex flex-col text-left gap-3 text-sm pt-4 text-muted-foreground">
                            <button 
                                onClick={() => setActiveCategory("")} 
                                className={`text-left ${activeCategory === "" ? 'text-black font-semibold' : ''}`}>
                                All Facilities
                            </button>
                            <button 
                                onClick={() => setActiveCategory("DINING")} 
                                className={`text-left ${activeCategory === "DINING" ? 'text-black font-semibold' : ''}`}>
                                Dining
                            </button>
                            <button 
                                onClick={() => setActiveCategory("CAFE")} 
                                className={`text-left ${activeCategory === "CAFE" ? 'text-black font-semibold' : ''}`}>
                                Paid Cafè
                            </button>
                            <button 
                                onClick={() => setActiveCategory("ACADEMIC")} 
                                className={`text-left ${activeCategory === "ACADEMIC" ? 'text-black font-semibold' : ''}`}>
                                Academic
                            </button>
                            <button 
                                onClick={() => setActiveCategory("RESIDENTIAL")} 
                                className={`text-left ${activeCategory === "RESIDENTIAL" ? 'text-black font-semibold' : ''}`}>
                                Residential
                            </button>
                            <button 
                                onClick={() => setActiveCategory("RECREATIONAL")} 
                                className={`text-left ${activeCategory === "RECREATIONAL" ? 'text-black font-semibold' : ''}`}>
                                Recreational
                            </button>
                            <button 
                                onClick={() => setActiveCategory("ATHLETIC")} 
                                className={`text-left ${activeCategory === "ATHLETIC" ? 'text-black font-semibold' : ''}`}>
                                Athletic
                            </button>
                            <button 
                                onClick={() => setActiveCategory("ADMINISTRATIVE")} 
                                className={`text-left ${activeCategory === "ADMINISTRATIVE" ? 'text-black font-semibold' : ''}`}>
                                Administrative
                            </button>
                            <button 
                                onClick={() => setActiveCategory("SHOP")} 
                                className={`text-left ${activeCategory === "SHOP" ? 'text-black font-semibold' : ''}`}>
                                Shop
                            </button>
                            <button 
                                onClick={() => setActiveCategory("OTHER")} 
                                className={`text-left ${activeCategory === "OTHER" ? 'text-black font-semibold' : ''}`}>
                                Other
                            </button>
                        </nav>
                    </div>

                    <div>
                        <nav className="grid gap-4 text-sm pb-[4rem] text-muted-foreground">
                            <Link to="#">Documentation</Link>
                            <Link to="#">Get Support</Link>
                        </nav>
                    </div>
                </div>

                {/* Main Content */}
                <div className="space-y-4 pl-[4rem] pt-10 w-full">
                    <div>
                        <span className="text-4xl font-bold tracking-tight">My Facilities</span>
                        <span className="text-sm font-light text-gray-500 pl-3">see all</span>
                    </div>
                    <div className="mb-6 pb-4">
                        <Input
                            type="text"
                            placeholder="Search by name..."
                            value={filterText}
                            onChange={handleFilterTextChange}
                            className="w-4/12 py-2 mt-3 bg-white"
                        />
                        {filteredBuildings.length !== 0 && (
                            <p className="text-xs pt-2 pl-1 text-gray-500">
                                {filteredBuildings.length} facilities found.
                            </p>
                        )}
                        {filteredBuildings.length === 0 && buildingOptions.length === 0 && (
                            <p className="text-xs pt-2 pl-1 text-gray-500">
                                Loading your facilities...
                            </p>
                        )}
                        {filteredBuildings.length === 0 && buildingOptions.length !== 0 && (
                            <p className="text-xs pt-2 pl-1 text-gray-500">
                                We couldn't find any matching facilities.
                            </p>
                        )}
                    </div>
                    
                    <div className="flex flex-wrap gap-4">
                        {filteredBuildings.map((building) => (
                            <Card
                                key={building.id}
                                className="w-5/12 cursor-pointer relative" // Ensure relative positioning for absolute child
                                onClick={() => handleCardClick(building.id)}
                            >
                                {/* Category Tag */}
                                <span
                                    className={`
                                        absolute bottom-4 right-4 px-2 py-1 rounded-full text-xs font-semibold
                                        ${categoryColors[building.category] || "bg-gray-100 text-gray-800"}
                                    `}
                                >
                                    {building.category}
                                </span>

                                <CardHeader>
                                    <CardTitle>{building.fullname}</CardTitle>
                                    <CardDescription className="line-clamp-2">{building.description}</CardDescription>
                                    <CardDescription className="text-xs flex items-center">
                                        <MapPinIcon className="h-3 w-3 mr-1 text-gray-500" />
                                        {building.address}
                                    </CardDescription>
                                </CardHeader>
                            </Card>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ManagementPage;
