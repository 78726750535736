import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogClose,
  DialogTrigger,
} from "../ui/dialog";
import { Button } from "../ui/button";
import { Label } from "../ui/label";
import { Input } from "../ui/input";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import BuildingSelector from "../hours/building-selector";
import { Building, FacilityCategory } from "../../lib/types";
import { useState, useEffect, useRef } from "react";
interface BuildingDialogProps {
  building: Building | null;
  facilityOptions: Building[];
  onSave: (updatedBuilding: Building) => void;
  trigger?: React.ReactNode; // Keep this optional, so you can manually control the dialog too
  open?: boolean; // Add optional open prop for manual control
  onOpenChange?: (open: boolean) => void; // Add optional callback for state change
}

const BuildingDialog = ({ building, facilityOptions, onSave, trigger, open, onOpenChange }: BuildingDialogProps) => {
  const [editedBuilding, setEditedBuilding] = useState<Building>(
    building || {  // Initialize empty building object if in "create" mode
      id: "",
      fullname: "",
      shortname: "",
      description: "",
      address: "",
      phone: "",
      website: "",
      contact: "",
      category: "OTHER" as FacilityCategory,
      profiles: [],
      events: [],
      notes: [],
      happenings: [],
      resources: [],
      banner: {
        enabled: false,
        variant: "info",
        message: ""
      }
    }
  );
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  useEffect(() => {
    if (building) {
      setEditedBuilding(building);  // If editing, use the building data
    }
  }, [building]);

  const wipeDialog = () => {
    // Reset the form when the dialog opens or the building changes
    if (building) {
      setEditedBuilding(building);  // Edit an existing building
    } else {
      setEditedBuilding({
        id: "",
        fullname: "",
        shortname: "",
        description: "",
        address: "",
        phone: "",
        website: "",
        contact: "",
        category: "OTHER" as FacilityCategory,
        profiles: [],
        events: [],
        notes: [],
        happenings: [],
        resources: [],
        banner: {
          enabled: false,
          variant: "info",
          message: ""
        }
      });  // Initialize an empty building for adding a new one
    }
    if (onOpenChange) {
      onOpenChange(false);
    }
  };

  useEffect(() => {
    const { fullname, shortname, description, address } = editedBuilding;
    setIsSaveDisabled(!fullname || !shortname || !address);
  }, [editedBuilding]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setEditedBuilding((prevBuilding) => ({
      ...prevBuilding,
      [name]: value,
    }));
  };

  const handleSaveClick = () => {
    if (!isSaveDisabled) {
      onSave(editedBuilding);
    }
  };

  return (
    <Dialog open={open} onOpenChange={wipeDialog}>
      {/* Conditionally render the trigger if it's provided */}
      {trigger && <DialogTrigger asChild>{trigger}</DialogTrigger>}
      <DialogContent
        onInteractOutside={(e) => {
          e.preventDefault();
        }}
        className="bg-white w-5/12 max-w-6/12 overflow-y-scroll max-h-[90vh]"
      >
        <DialogHeader>
          <DialogTitle>{building ? "Edit Facility Information" : "Add New Facility"}</DialogTitle>
          <DialogDescription>
            {building ? "Edit your facility's information here. Starred fields may not be left blank." : "Fill in the below fields to create a new facility. Then, invite users to manage it."}
          </DialogDescription>
        </DialogHeader>
        <div className="grid grid-cols-1 gap-4 py-2">
          <div className="grid grid-cols-1 items-center gap-1">
            <Label htmlFor="fullname" className="mb-0">
              <span>Full Name<sup>*</sup></span>
            </Label>
            <DialogDescription className="text-xs">
              e.g. "Elm Street Dining Hall"
            </DialogDescription>
            <Input
              id="fullname"
              name="fullname"
              required
              value={editedBuilding.fullname}
              onChange={handleInputChange}
              className="w-full mb-1"
            />
          </div>

          <div className="grid grid-cols-1 items-center gap-1">
            <Label htmlFor="shortname">
              <span>Short Name<sup>*</sup></span>
            </Label>
            <DialogDescription className="text-xs">
              e.g. "Elm Street"
            </DialogDescription>
            <Input
              id="shortname"
              name="shortname"
              required
              value={editedBuilding.shortname}
              onChange={handleInputChange}
              className="w-full mb-1"
            />
          </div>

          <div className="grid grid-cols-1 items-center gap-1">
            <Label htmlFor="description">
              Description
            </Label>
            <DialogDescription className="text-xs">
              e.g. "Dining hall on the south side of campus."
            </DialogDescription>
            <Input
              id="description"
              name="description"
              required
              value={editedBuilding.description}
              onChange={handleInputChange}
              className="w-full mb-1"
            />
          </div>

          <div className="grid grid-cols-1 items-center gap-1">
            <Label htmlFor="website">
              Website
            </Label>
            <DialogDescription className="text-xs">
              e.g. "https://www.exeter.edu/community/dining-exeter"
            </DialogDescription>
            <Input
              id="website"
              name="website"
              value={editedBuilding.website}
              onChange={handleInputChange}
              className="w-full mb-1"
            />
          </div>

          <div className="grid grid-cols-1 items-center gap-1">
            <Label htmlFor="contact">
              Primary Contact
            </Label>
            <DialogDescription className="text-xs">
              e.g. "jdoe@exeter.edu"
            </DialogDescription>
            <Input
              id="contact"
              name="contact"
              value={editedBuilding.contact}
              onChange={handleInputChange}
              className="w-full mb-1"
            />
          </div>

          <div className="grid grid-cols-1 items-center gap-1">
            <Label htmlFor="phone">
              Phone
            </Label>
            <DialogDescription className="text-xs">
              e.g. "(603) 772-4311"
            </DialogDescription>
            <Input
              id="phone"
              name="phone"
              value={editedBuilding.phone}
              onChange={handleInputChange}
              className="w-full mb-1"
            />
          </div>

          <div className="grid grid-cols-1 items-center gap-1">
            <div>
              <Label htmlFor="message"><span>Street Address<sup>*</sup></span></Label>
            </div>
            <DialogDescription className="text-xs">
              e.g. "20 Main Street, Exeter, NH, USA"
            </DialogDescription>
            <div className="w-full">
              <form autoComplete="off" className="w-full block">
                <GooglePlacesAutocomplete
                  className="w-full block rounded-md border border-gray-100 bg-white px-3 py-3 text-sm shadow-sm placeholder:text-gray-200 focus:border-gray-200 focus:outline-none focus:ring-1 focus:ring-gray-500"
                  apiKey={apiKey}
                  autocompletionRequest={{
                    componentRestrictions: {
                      country: ["us"],
                    },
                  }}
                  selectProps={{
                    defaultInputValue: editedBuilding.address,
                    onChange: (o: { [x: string]: { [x: string]: string } }) => {
                      let placeId = "";
                      if (o) {
                        placeId = o["value"]["description"];
                      }
                      const syntheticEvent = {
                        target: {
                          name: "address",
                          value: placeId,
                        },
                      };
                      handleInputChange(syntheticEvent as React.ChangeEvent<HTMLInputElement>);
                      console.log(placeId);
                    },
                  }}
                />
              </form>
            </div>
          </div>

          <div className="grid grid-cols-1 items-center gap-1">
            <div className="mt-1">
              <Label htmlFor="message">Facility Category</Label>
            </div>
            <DialogDescription className="text-xs">
              e.g. "Dining"
            </DialogDescription>
            <Select
              value={editedBuilding.category}
              onValueChange={(value) => {
                const syntheticEvent = {
                  target: {
                    name: "category",
                    value: value as FacilityCategory,
                  },
                };
                handleInputChange(syntheticEvent as React.ChangeEvent<HTMLInputElement>);
              }}
            >
              <SelectTrigger className="w-full">
                <SelectValue placeholder="Select category..." />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="DINING">Dining</SelectItem>
                <SelectItem value="CAFE">Paid Cafè</SelectItem>
                <SelectItem value="ACADEMIC">Academic</SelectItem>
                <SelectItem value="RESIDENTIAL">Residential</SelectItem>
                <SelectItem value="RECREATIONAL">Recreational</SelectItem>
                <SelectItem value="ATHLETIC">Athletic</SelectItem>
                <SelectItem value="ADMINISTRATIVE">Administrative</SelectItem>
                <SelectItem value="SHOP">Shop</SelectItem>
                <SelectItem value="OTHER">Other</SelectItem>
              </SelectContent>
            </Select>
          </div>

          <div className="grid grid-cols-1 items-center gap-1">
            <div className="mt-1">
              <Label htmlFor="phone">Parent Facility</Label>
            </div>
            <DialogDescription className="text-xs">
              for facilities located in other buildings (i.e., cafè in campus center)
            </DialogDescription>
            <div className="flex items-center gap-2">
              <BuildingSelector
                options={facilityOptions}
                value={editedBuilding.parentId || ""}
                onBuildingChange={(buildingId) => {
                  const syntheticEvent = {
                    target: {
                      name: "parentId",
                      value: buildingId,
                    },
                  };
                  handleInputChange(syntheticEvent as React.ChangeEvent<HTMLInputElement>);
                }}
              />

              <Button
                variant="outline"
                onClick={() => {
                  const syntheticEvent = {
                    target: {
                      name: "parentId",
                      value: "",
                    },
                  };
                  handleInputChange(syntheticEvent as React.ChangeEvent<HTMLInputElement>);
                }}
              >
                Clear
              </Button>
            </div>
          </div>
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button type="submit" onClick={handleSaveClick} disabled={isSaveDisabled}>
              Save
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default BuildingDialog;
