import '../../App.css';
import MenuBar from '../../components/menu-bar';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import {
    Card,
    CardContent,
  } from "../../components/ui/card"
import { fetchUsers } from '../../lib/fetchusers';
import { User } from '@/lib/types';
import { useState, useEffect } from 'react';
import React from 'react';
import { useAuth } from '../../context/AuthContext';
import { DataTable } from './Users/data-table';
import { getColumns } from './Users/columns';

const getCurrentDate = () => {
    const now = dayjs();
    const day = now.date();
    let suffix;

    if (day % 10 === 1 && day !== 11) {
        suffix = 'st';
    } else if (day % 10 === 2 && day !== 12) {
        suffix = 'nd';
    } else if (day % 10 === 3 && day !== 13) {
        suffix = 'rd';
    } else {
        suffix = 'th';
    }

    return now.format(`MMMM D[${suffix}].`);
};

function UsersAdminPage() {

    const [userOptions, setUserOptions] = useState<User[]>([]);
    const { user } = useAuth();
    const school = user?.customClaims?.school;

    useEffect(() => {
        async function loadUsers() {
            const users = await fetchUsers(school);
            setUserOptions(users);
        }
        loadUsers();
    }, []);

    const refreshUsers = async () => {
        const buildings = await fetchUsers(school);
        setUserOptions(buildings);
    };

    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const [selectedUser, setSelectedUser] = React.useState<User | null>(null);

    const handleOpenDialog = (user: User) => {
        setSelectedUser(user); // Set the building to be edited
        setIsDialogOpen(true); // Open the dialog
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false); // Close the dialog
        setSelectedUser(null); // Clear the selected building
    };

    return (
        <div className="flex flex-col h-screen">
        <MenuBar />
        <div className="flex flex-grow">
    
            <div className="space-y-4 pl-[4rem] pt-10 flex flex-col justify-between h-full w-1/6">
    
                <div>
                    <h2 className="text-3xl font-bold tracking-tight">Admin</h2>
                    <p className="text-gray-500 text-sm font-semibold mb-5">{getCurrentDate()}</p>

                    <nav className="grid gap-3 text-sm pt-4 text-muted-foreground">
                    <Link to={`/${school}/admin`}>Edit Facilities</Link>
                        <Link to="#" className='font-semibold text-black'>Edit Users</Link>
                        <Link to="#">Manage Plan</Link>
                    </nav>
                </div>
    
                <div>
                    <nav className="grid gap-4 text-sm pb-[4rem] text-muted-foreground">
                        <Link to="#">Documentation</Link>
                        <Link to="#">Get Support</Link>
                    </nav>
                </div>
    
            </div>
            <div className="space-y-4 pl-[4rem] pt-10 w-full">
                <h2 className="text-4xl font-bold tracking-tight">Edit Users</h2>
                <Card className="w-11/12">
                    <CardContent className="mt-7">
                        <DataTable 
                            columns={getColumns(handleOpenDialog)}
                            data={userOptions} 
                            refreshUsers={refreshUsers} 
                            /> 
                    </CardContent>
                </Card>
            </div>
        </div>
    </div>
    );
}

export default UsersAdminPage;