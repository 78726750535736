import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
  } from "../../components/ui/card"
import { Button } from "../../components/ui/button";
import { Link } from "react-router-dom";
import { useAuth } from '../../context/AuthContext'

export default function NotFoundPage() {
    const { user } = useAuth();
  const school = user?.customClaims?.school;

    return (
        <div className="App h-screen flex items-center justify-center">
        <Card className="w-[380px] bg-white">
            <CardHeader className="">
            <CardTitle className="truenoTitle text-4xl leading-tight">404: Not Found</CardTitle>
            <p>The page you are looking for does not exist.</p>
            </CardHeader>
            <CardFooter>
                <Link to={`/${school}/console`}  className="w-full">
                    <Button className="w-full bg-black text-white" >
                        Go Home
                    </Button>
                </Link>
            </CardFooter>
        </Card>
      </div>
    );
}