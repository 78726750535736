import * as React from "react"
import { Link, useLocation } from "react-router-dom"; // Import useLocation
import { cn } from "../lib/utils"
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  navigationMenuTriggerStyle,
} from "../components/ui/navigation-menu"
import { useAuth } from '../context/AuthContext'

export function MainNav() {
  const location = useLocation(); // Get the current location

  // Helper function to determine if the link is active
  const isActive = (path: string) => location.pathname === path;

  const { user } = useAuth();
  const school = user?.customClaims?.school;

  return (
    <NavigationMenu>
      <NavigationMenuList>
        <NavigationMenuItem>
          <Link to={`/${school}`}>
            <NavigationMenuLink 
              className={cn(
                navigationMenuTriggerStyle(),
                isActive(`/${school}`) ? "font-bold" : "font-normal" // Apply bold if active
              )}
            >
              View
            </NavigationMenuLink>
          </Link>
          <Link to={`/${school}/console`}>
            <NavigationMenuLink 
              className={cn(
                navigationMenuTriggerStyle(),
                (isActive(`/${school}/console`) || isActive(`/${school}/hours`)) ? "font-bold" : "font-normal" // Apply bold if active
              )}
            >
              Manage
              
            </NavigationMenuLink>
          </Link>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <Link to={`/${school}/admin`}>
            <NavigationMenuLink 
              className={cn(
                navigationMenuTriggerStyle(),
                (isActive(`/${school}/admin`) || isActive(`/${school}/admin/users`)) ? "font-bold" : "font-normal" // Apply bold if active
              )}
            >
              Admin
            </NavigationMenuLink>
          </Link>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <Link to={`/docs`}>
            <NavigationMenuLink 
              className={cn(
                navigationMenuTriggerStyle(),
                isActive("/docs") ? "font-bold" : "font-normal" // Apply bold if active
              )}
            >
              Help
            </NavigationMenuLink>
          </Link>
        </NavigationMenuItem>
      </NavigationMenuList>
    </NavigationMenu>
  )
}
