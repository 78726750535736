// src/components/general/calendar-feature.tsx

import { Building, HoursProfile, CalendarEvent, Occurence, Conflict, DayOfWeek, DayNote } from "../../lib/types";
import Calendar from 'react-calendar';
import {
    Card,
    CardDescription,
    CardHeader,
    CardTitle,
} from "../ui/card";
import styled from 'styled-components';
import './calendar.css';
import { Textarea } from "../ui/textarea";
import { Label } from "../ui/label";
import { Badge } from "../ui/badge";
import { Separator } from "../ui/separator";
import { Button } from "../ui/button";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Dayjs } from "dayjs";
import React from "react";
import DayNoteComponent from "./day-note-component";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogClose,
    DialogTrigger,
} from "../ui/dialog";
import { useToast } from "../ui/use-toast";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
  } from "../ui/dropdown-menu"
  import { DotsHorizontalIcon, BookmarkFilledIcon } from "@radix-ui/react-icons"
import HappeningDialog from "../general/happening-dialog";
interface CalendarFeatureProps {
    options: Building;
    onUpdate: (updatedOptions: Building) => void;
}

export interface DisplayHappening {
    id: string;
    name: string;
    description: string;
    starttime: Dayjs;
    endtime: Dayjs;
    allday: boolean;
    resource: string;
    variant: string;
  }

const CalendarFeature: React.FC<CalendarFeatureProps> = ({ options, onUpdate }) => {
    const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const [deleteConfirmationOpen2, setDeleteConfirmationOpen2] = useState(false);

    const [eventDeleteConfirmationOpen, setEventDeleteConfirmationOpen] = useState(false);
    const [eventToDelete, setEventToDelete] = useState<DisplayHappening | null>(null);

    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [happeningToEdit, setHappeningToEdit] = useState<DisplayHappening | null>(null);

    const handleEventEditClick = (happening: DisplayHappening) => {
        setHappeningToEdit(happening);
        setEditDialogOpen(true);
      };
    
      const handleEventSave = (updatedBuilding: Building) => {
        onUpdate(updatedBuilding);
        setEditDialogOpen(false);
        toast({
          title: "Event updated",
          description: "Your event has been successfully updated.",
        });
      };
    

    const { toast } = useToast();
    const [version, setVersion] = useState(0);

    // New state variables for selected series
    const [selectedSeriesDates, setSelectedSeriesDates] = useState<Date[]>([]);
    const [selectedSeriesColor, setSelectedSeriesColor] = useState<string | null>(null);

    const handleEventDeleteClick = (happening: DisplayHappening) => {
        console.log("happeing");
        setEventToDelete(happening);
        setEventDeleteConfirmationOpen(true);
      };
      
      const handleEventConfirmDelete = () => {
        if (eventToDelete) {
          // Assuming you have a state or prop that holds the list of happenings
          // For example, if you have `happenings` in state:
          const updatedHappenings = options.happenings.filter(event => event.id !== eventToDelete.id);
          onUpdate({ ...options, happenings: updatedHappenings });
      
          // If the happenings are managed via a parent component, use a callback
          // onDelete(eventToDelete.id);
      
          // Optionally, show a toast notification
          toast({
            title: "Event Deleted",
            description: `${eventToDelete.name} has been deleted.`,
          });

          setVersion((prev) => prev + 1);
      
          // Reset the delete state
          setDeleteConfirmationOpen(false);
          setEventToDelete(null);
        }
      };
      
      const handleCancelDelete = () => {
        setDeleteConfirmationOpen(false);
        setEventToDelete(null);
      };

    // Inside CalendarFeature component

    const getHappeningBadgeText = (happening: DisplayHappening, selectedDate: Date): string => {
        const selectedDay = dayjs(selectedDate).startOf('day');
        const eventStart = dayjs(happening.starttime).startOf('day');
        const eventEnd = dayjs(happening.endtime).startOf('day');

        if (selectedDay.isSame(eventStart, 'day') && selectedDay.isSame(eventEnd, 'day')) {
            // Single-day event
            return happening.allday
                ? "All Day"
                : `${happening.starttime.format('h:mm A')} - ${happening.endtime.format('h:mm A')}`;
        } else if (selectedDay.isSame(eventStart, 'day')) {
            // Start day of multi-day event
            const lastDay = dayjs(eventEnd).format('M/D');
            return happening.allday
                ? "All Day"
                : `${happening.starttime.format('h:mm A')} - ${lastDay}`;
        } else if (selectedDay.isSame(eventEnd, 'day')) {
            // End day of multi-day event
            const firstDay = dayjs(eventStart).format('M/D');
            return happening.allday
                ? "All Day"
                : `${firstDay} - ${happening.endtime.format('h:mm A')}`;
        } else {
            const firstDay = dayjs(eventStart).format('M/D');
            const lastDay = dayjs(eventEnd).format('M/D');
            return  ` ${firstDay} - ${lastDay}`;
        }
    };    

    // Extract actual occurrences and transform them to the calendar's event format
    const events = options.events.flatMap(event => {
        if (event.recurrence && event.recurrence.actualOccurences) {
            return event.recurrence.actualOccurences.map(occurrence => {
                const occurenceDate = dayjs(occurrence.date);
                const calendarEvent = {
                    id: `${event.id}-${occurenceDate.toISOString()}`,
                    profileId: event.profileId,
                    color: options.profiles.find(profile => profile.id === event?.profileId)?.color || 'gray',
                    date: occurenceDate.startOf('day'),
                    eventId: event.id,
                };
                return calendarEvent;
            });
        } else {
            // Handle one-time events without recurrence
            const oneTimeEvent = {
                id: `${event.id}-${event.startDate.toISOString()}`,
                profileId: event.profileId,
                color: options.profiles.find(profile => profile.id === event?.profileId)?.color || 'gray',
                date: event.startDate.startOf('day').add(1, 'hour'),
                eventId: event.id,
            };
            return [oneTimeEvent];
        }
    });

    const getEventForDate = (date: Date) => {
        return events.find(event => {
            const eventDate = event.date.toDate();
            return dayjs(eventDate).isSame(dayjs(date), 'day');
        });
    };

    const getHappeningsForDate = (date: Date | null): DisplayHappening[] => {
        if (!date) return [];
        
        const dayStart = dayjs(date).startOf('day');
        const dayEnd = dayjs(date).endOf('day');
        
        return options.happenings
            .filter(happening => {
            const start = dayjs(happening.starttime);
            const end = dayjs(happening.endtime);
        
            // Check if the happening occurs on the selected date
            return start.isBefore(dayEnd) && end.isAfter(dayStart);
            })
            .map(happening => ({
                id: happening.id,
                name: happening.name,
                description: happening.description,
                starttime: dayjs(happening.starttime),
                endtime: dayjs(happening.endtime),
                variant: happening.variant,
                allday: happening.allday,
                resource: happening.resource
            }));
    };

    const addDynamicStyle = (color: string, selected: boolean) => {
        const sanitizedColor = color.replace('#', '');
        const className = `highlight-${sanitizedColor}`;
        if (!document.getElementById(className)) {
            const style = document.createElement('style');
            style.id = className;
            style.innerHTML = `
                .${className} {
                    background-color: ${color};
                    color: white !important;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                }
            `;
            document.head.appendChild(style);
        }
        if (selected) {
            return `${className} selected-date`;
        }
        return className;
    };

    const formatDate = (date: string | number | dayjs.Dayjs | Date | null | undefined) => {
        return dayjs(date).format("MMMM D") + dayjs(date).format("Do").slice(-2) + ", " + dayjs(date).format("YYYY");
    };

    const getProfileNameForEvent = (eventId: string): string | undefined => {
        const event = options.events.find(event => event.id === eventId);
        return options.profiles.find(profile => profile.id === event?.profileId)?.name;
    };

    const getEventDetails = (eventId: string) => {
        return options.events.find(event => event.id === eventId);
    };

    const formatRecurrenceFrequency = (frequency: any) => {
        switch (frequency) {
            case 'DAILY':
                return 'day(s)';
            case 'WEEKLY':
                return 'week(s)';
            case 'MONTHLY':
                return 'month(s)';
            default:
                return '';
        }
    };

    const formatDaysOfWeek = (days: DayOfWeek[]): string => {
        const dayMap: Record<DayOfWeek, string> = {
            'SUNDAY': 'Su',
            'MONDAY': 'M',
            'TUESDAY': 'T',
            'WEDNESDAY': 'W',
            'THURSDAY': 'Th',
            'FRIDAY': 'F',
            'SATURDAY': 'Sa'
        };
    
        // Sort days by their order in the week (Sunday -> Saturday)
        const sortedDays = days.sort((a, b) => {
            const dayOrder: Record<DayOfWeek, number> = {
                'SUNDAY': 0,
                'MONDAY': 1,
                'TUESDAY': 2,
                'WEDNESDAY': 3,
                'THURSDAY': 4,
                'FRIDAY': 5,
                'SATURDAY': 6
            };
            return dayOrder[a] - dayOrder[b];
        });
    
        return sortedDays.map(day => dayMap[day]).join(', ');
    };

    const handleDeleteConfirmation = (eventId: string) => {
        console.log("deleting");
        const updatedEvents = options.events.filter(event => event.id !== eventId);
        console.log(updatedEvents);
        // Create a new building object with the updated events list
        const newBuilding = {
            ...options,
            events: updatedEvents
        };

        // Call onUpdate with the updated building
        onUpdate(newBuilding);

        toast({
            variant: "destructive",
            title: "Event series deleted.",
            description: "The profile has been removed from all days where it was applied in this series.",
        });
    };

    const handleSingleDeleteConfirmation = (eventId: string, selectedDate: Dayjs) => {
        const updatedEvents = options.events
            .map(event => {
                if (event.id === eventId && event.recurrence && event.recurrence.actualOccurences) {
                    // Filter out the occurrence that matches the selectedDate
                    const filteredOccurrences = event.recurrence.actualOccurences.filter(occurrence => {
                        const occurrenceDate = dayjs(occurrence.date);
                        const selectedDayjsDate = dayjs(selectedDate);
                        return !occurrenceDate.isSame(selectedDayjsDate, 'day');
                    });

                    // Check if there are any occurrences left after filtering
                    if (filteredOccurrences.length > 0) {
                        // Return the updated event with filtered occurrences
                        return {
                            ...event,
                            recurrence: {
                                ...event.recurrence,
                                actualOccurences: filteredOccurrences
                            }
                        };
                    } else {
                        // If no occurrences left, return null to indicate deletion
                        return null;
                    }
                }
                // Return the event as-is if it doesn't match the eventId or has no occurrences
                return event;
            })
            .filter(event => event !== null); // Remove any null events

        // Create a new building object with the updated events list
        const newBuilding = {
            ...options,
            events: updatedEvents as CalendarEvent[] // Type assertion to ensure proper type
        };

        // Call onUpdate with the updated building
        onUpdate(newBuilding);

        toast({
            variant: "destructive",
            title: "Occurence deleted.",
            description: "The profile has been removed from this day.",
        });
        refreshCalendar();
    };

    useEffect(() => {
        if (selectedDate) {
            handleDateClick(selectedDate);
        }
    }, [selectedDate, options.events, options.profiles]);

    const handleDateClick = (value: Date) => {
        setSelectedDate(value);
    
        const eventForDate = getEventForDate(value);
        if (eventForDate && eventForDate.eventId) {
            const eventDetails = options.events.find(e => e.id === eventForDate.eventId);
            if (eventDetails && eventDetails.recurrence && eventDetails.recurrence.actualOccurences) {
                // It's a recurring event; gather all occurrence dates
                const relatedEvents = events.filter(e => e.eventId === eventForDate.eventId);
                const relatedDates = relatedEvents.map(e => e.date.toDate());
                setSelectedSeriesDates(relatedDates);
    
                // Get the color from the profile
                const color = options.profiles.find(profile => profile.id === eventForDate.profileId)?.color || 'gray';
                setSelectedSeriesColor(color);
            } else {
                // It's a one-time event; clear series state
                setSelectedSeriesDates([]);
                setSelectedSeriesColor(null);
            }
        } else {
            // No event on the selected date; clear series state
            setSelectedSeriesDates([]);
            setSelectedSeriesColor(null);
        }
    };
    
    // Call this function programmatically when needed
    const refreshCalendar = () => {
        if (selectedDate) {
            handleDateClick(selectedDate);  // Re-run the logic for the selected date
        }
    };

    return (
        <div className="grid grid-cols-10 gap-4">
            <Card className="bg-white p-6 flex col-span-5 space-y-5 flex flex-col">
                <Calendar
                    key={version}
                    tileClassName={({ date, view }) => {
                        const isSelectedSeries = selectedSeriesDates.some(d => dayjs(d).isSame(date, 'day'));
                        const eventForDate = getEventForDate(date);
                        let classes = '';

                        if (isSelectedSeries && selectedSeriesColor) {
                            const className = `series-date highlight-${selectedSeriesColor.replace('#', '')}`;
                            classes += `${className} `;
                        }

                        if (eventForDate) {
                            const profile = options.profiles.find(profile => profile.id === eventForDate.profileId);
                            const color = profile?.color || 'gray';
                            const isSelectedDay = dayjs(date).isSame(selectedDate, 'day');

                            // Apply dynamic styling based on event's color and selection
                            classes += `${addDynamicStyle(color, isSelectedDay)} `;
                        }

                        if (dayjs(date).isSame(selectedDate, 'day')) {
                            classes += 'selected-date ';
                        }

                        return classes.trim();
                    }}
                    onClickDay={(value, event) => {
                        setSelectedDate(value);

                        const eventForDate = getEventForDate(value);
                        if (eventForDate && eventForDate.eventId) {
                            const eventDetails = options.events.find(e => e.id === eventForDate.eventId);
                            if (eventDetails && eventDetails.recurrence && eventDetails.recurrence.actualOccurences) {
                                // It's a recurring event; gather all occurrence dates
                                const relatedEvents = events.filter(e => e.eventId === eventForDate.eventId);
                                const relatedDates = relatedEvents.map(e => e.date.toDate());
                                setSelectedSeriesDates(relatedDates);

                                // Get the color from the profile
                                const color = options.profiles.find(profile => profile.id === eventForDate.profileId)?.color || 'gray';
                                setSelectedSeriesColor(color);
                            } else {
                                // It's a one-time event; clear series state
                                setSelectedSeriesDates([]);
                                setSelectedSeriesColor(null);
                            }
                        } else {
                            // No event on the selected date; clear series state
                            setSelectedSeriesDates([]);
                            setSelectedSeriesColor(null);
                        }
                    }}
                    value={selectedDate}
                    calendarType="gregory"
                />
            </Card>
            {happeningToEdit && (
                <HappeningDialog
                building={options}
                happening={happeningToEdit}
                onSave={handleEventSave}
                open={editDialogOpen}
                onOpenChange={setEditDialogOpen}
                />
            )}
            <Card className="bg-white p-6 col-span-5 space-y-5" style={{ height: '455px', overflowY: 'auto' }}>
                <div>
                    <h1 className="text-xl font-bold mb-2 ">
                        {selectedDate ? formatDate(selectedDate) : "Select a date"}
                    </h1>
                    {selectedDate ? (
                        <div className="mb-3">
                            {events.map(event => {
                                const eventDate = event.date.toDate();
                                if (
                                    dayjs(eventDate).isSame(selectedDate, 'day')
                                ) {
                                    const profileName = getProfileNameForEvent(event.eventId);
                                    const eventDetails = getEventDetails(event.eventId);
                                    if (!eventDetails) {
                                        return null; // Event details not found
                                    }
                                    // Determine the series dates or one-time event message
                                    const eventMessage = eventDetails.doesRepeat
                                        ? `Occurrence in a series from ${eventDetails.startDate.format('M/D')} to ${eventDetails.endDate?.format('M/D') || 'unknown end date'}.`
                                        : "One-time event.";
                                    return (
                                        <React.Fragment key={event.id}>
                                            <Card className="pt-2 pl-2 pr-2 pb-1.5 drop-shadow-sm">
                                                {eventDetails.doesRepeat && (
                                                    <p className="text-xs font-bold mb-1 text-gray-700">
                                                        {eventMessage}
                                                    </p>
                                                )}
                                                <Badge style={{ backgroundColor: event.color }} className={`mr-1`} variant="default">
                                                    {profileName}
                                                </Badge>
                                                {!eventDetails.doesRepeat && (
                                                    <Badge className={`mr-1`} variant="outline">
                                                        {eventDetails.startDate.format('MM/DD/YYYY')}
                                                    </Badge>
                                                )}
                                                {eventDetails.doesRepeat && (
                                                    <>
                                                        <Badge variant="outline" className={`mr-1`}>
                                                            Every {eventDetails.recurrence?.interval} {formatRecurrenceFrequency(eventDetails.recurrence?.frequency)}
                                                        </Badge>
                                                    </>
                                                )}
                                                {eventDetails.doesRepeat && eventDetails.recurrence!.frequency === 'WEEKLY' && eventDetails.recurrence!.daysOfWeek && (
                                                    <Badge variant="outline">
                                                        {formatDaysOfWeek(eventDetails.recurrence!.daysOfWeek)}
                                                    </Badge>
                                                )}
                                                <Separator className="mt-2 mb-1" />
                                                {eventDetails.doesRepeat && (
                                                    <div className="flex justify-between items-center w-full">
                                                        <div className="flex justify-center items-center w-1/2 cursor-pointer" onClick={() => setDeleteConfirmationOpen2(true)}>
                                                            <p className="text-xs text-gray-500">delete occurrence</p>
                                                        </div>
                                                        <Dialog open={deleteConfirmationOpen2} onOpenChange={setDeleteConfirmationOpen2}>
                                                            <DialogContent className="bg-white w-5/12">
                                                                <DialogHeader>
                                                                    <DialogTitle>Confirm Deletion</DialogTitle>
                                                                    <DialogDescription>
                                                                        Are you sure you want to delete this occurrence from the event series?
                                                                    </DialogDescription>
                                                                </DialogHeader>
                                                                <DialogFooter>
                                                                    <DialogClose asChild>
                                                                        <Button type="button" onClick={() => handleSingleDeleteConfirmation(eventDetails.id, dayjs(selectedDate))} variant="destructive">
                                                                            Confirm Delete
                                                                        </Button>
                                                                    </DialogClose>
                                                                    <DialogClose asChild>
                                                                        <Button type="button" onClick={() => setDeleteConfirmationOpen2(false)}>
                                                                            Cancel
                                                                        </Button>
                                                                    </DialogClose>
                                                                </DialogFooter>
                                                            </DialogContent>
                                                        </Dialog>
                                                        <div className="flex justify-center items-center w-1/2 cursor-pointer" onClick={() => setDeleteConfirmationOpen(true)}>
                                                            <p className="text-xs text-gray-500">delete series</p>
                                                        </div>
                                                        <Dialog open={deleteConfirmationOpen} onOpenChange={() => setDeleteConfirmationOpen(false)}>
                                                            <DialogContent className="bg-white w-5/12">
                                                                <DialogHeader>
                                                                    <DialogTitle>Confirm Deletion</DialogTitle>
                                                                    <DialogDescription>
                                                                        Are you sure you want to delete this series of events?
                                                                    </DialogDescription>
                                                                </DialogHeader>
                                                                <DialogFooter>
                                                                    <DialogClose asChild>
                                                                        <Button type="button" onClick={() => handleDeleteConfirmation(eventDetails.id)} variant="destructive">
                                                                            Confirm Delete
                                                                        </Button>
                                                                    </DialogClose>
                                                                    <DialogClose asChild>
                                                                        <Button type="button" onClick={() => setDeleteConfirmationOpen(false)}>
                                                                            Cancel
                                                                        </Button>
                                                                    </DialogClose>
                                                                </DialogFooter>
                                                            </DialogContent>
                                                        </Dialog>
                                                    </div>
                                                )}
                                                {!eventDetails.doesRepeat && (
                                                    <div className="flex justify-center items-center w-full">
                                                        <div className="flex justify-center items-center w-1/2 cursor-pointer" onClick={() => setDeleteConfirmationOpen(true)}>
                                                            <p className="text-xs text-gray-500">delete this occurence</p>
                                                        </div>
                                                        <Dialog open={deleteConfirmationOpen} onOpenChange={() => setDeleteConfirmationOpen(false)}>
                                                            <DialogContent className="bg-white w-5/12">
                                                                <DialogHeader>
                                                                    <DialogTitle>Confirm Deletion</DialogTitle>
                                                                    <DialogDescription>
                                                                        Are you sure you want to delete this one-time event?
                                                                    </DialogDescription>
                                                                </DialogHeader>
                                                                <DialogFooter>
                                                                    <DialogClose asChild>
                                                                        <Button type="button" onClick={() => handleDeleteConfirmation(eventDetails.id)} variant="destructive">
                                                                            Confirm Delete
                                                                        </Button>
                                                                    </DialogClose>
                                                                    <DialogClose asChild>
                                                                        <Button type="button" onClick={() => setDeleteConfirmationOpen(false)}>
                                                                            Cancel
                                                                        </Button>
                                                                    </DialogClose>
                                                                </DialogFooter>
                                                            </DialogContent>
                                                        </Dialog>
                                                    </div>
                                                )}
                                            </Card>
                                        </React.Fragment>
                                    );
                                }
                                return null;
                            })}
                            {events.every(event => {
                                const eventDate = event.date.toDate();
                                return !(
                                    dayjs(eventDate).isSame(selectedDate, 'day')
                                );
                            }) && (
                                <React.Fragment>
                                    <p className="text-xs font-bold mb-1 text-gray-700">
                                        No profile applied.
                                    </p>
                                </React.Fragment>
                            )}
                        </div>
                    ) : null}
                    {/* New Section: Display Happenings */}
                        {selectedDate && (
                        <div className="mb-3">
                           {getHappeningsForDate(selectedDate).length > 0 && (
                                <h2 className="text-md font-bold mb-2">Events & Deals</h2>
                            )}
                            {getHappeningsForDate(selectedDate).length > 0 ? (
                            getHappeningsForDate(selectedDate).map(happening => (
                            <Card key={happening.id} className="pt-2 pl-3 pr-3 pb-2.5 drop-shadow-sm mb-2">
                                <div className="flex items-center">
                                 {happening.variant == "event" && (
                                    <Badge variant="outline" className="text-xs mr-1 mb-1 font-bold bg-green-500 text-white px-2">
                                        EVENT
                                    </Badge>
                                )}
                                {happening.variant == "deal" && (
                                    <Badge variant="outline" className="text-xs mr-1 mb-1 font-bold bg-violet-500 text-white px-2">
                                        EVENT
                                    </Badge>
                                )}
                                <Badge variant="outline" className="text-xs mb-1 px-2">
                                        {getHappeningBadgeText(happening, selectedDate)}
                                    </Badge>
                                <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                    <div className="rounded bg-gray-100 mb-1 ml-2 px-1">
                                    <DotsHorizontalIcon className="h-4 w-4 color-gray align-top" /> 
                                    </div>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent>
                                    <DropdownMenuItem onSelect={() => handleEventEditClick(happening)}>
                                        Edit
                                    </DropdownMenuItem>
                                    <DropdownMenuItem onSelect={() => handleEventDeleteClick(happening)}>
                                    <span className="text-red-500">Delete</span>
                                    </DropdownMenuItem>
                                </DropdownMenuContent>
                                </DropdownMenu>
                                    </div>
                                <h3 className="text-sm font-bold">{happening.name}</h3>
                                <p className="text-xs text-gray-600">{happening.description}</p>
                                {happening.resource.length != 0 && (
                                    <div className="">
                                        <a href={happening.resource} target="_blank" rel="noopener noreferrer" className="text-blue-500 text-xs font-bold">
                                        learn more »
                                        </a>
                                    </div>
                                )}
                                </Card>
                            ))
                            ) : (
                            <p className="text-xs font-bold mb-1 text-gray-700">No events or deals on this day.</p>
                            )}
                        </div>
                        )}

                    {/* Delete Confirmation Dialog */}
                    <Dialog open={eventDeleteConfirmationOpen} onOpenChange={setEventDeleteConfirmationOpen}>
                        <DialogContent className="bg-white w-5/12">
                        <DialogHeader>
                            <DialogTitle>Confirm Deletion</DialogTitle>
                            <DialogDescription>
                            Are you sure you want to delete this event or deal? It will remove it from all days it was created to span.
                            </DialogDescription>
                        </DialogHeader>
                        <DialogFooter>
                            <DialogClose asChild>
                            <Button
                                type="button"
                                onClick={handleEventConfirmDelete}
                                variant="destructive"
                            >
                                Confirm Delete
                            </Button>
                            </DialogClose>
                            <DialogClose asChild>
                            <Button type="button" onClick={handleCancelDelete}>
                                Cancel
                            </Button>
                            </DialogClose>
                        </DialogFooter>
                        </DialogContent>
                    </Dialog>
                    <div className="text-sm">
                        <DayNoteComponent 
                            building={options} 
                            selectedDate={dayjs(selectedDate)} 
                            onUpdateNote={(updatedNotes: DayNote[]) => onUpdate({ ...options, notes: updatedNotes })} 
                        />
                        <p className="text-xs ml-1 mb-4 text-gray-400">
                            Add any notes you want to show for this day.
                        </p>
                    </div>                    
                </div>
            </Card>
        </div>
    );
};

export default CalendarFeature;
