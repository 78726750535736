// src/components/happenings/HappeningDialog.tsx

import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
  } from "../ui/dialog";
  import { Button } from "../ui/button";
  import { Label } from "../ui/label";
  import { Input } from "../ui/input";
  import { Switch } from "../ui/switch";
  import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";
  import { Textarea } from "../ui/textarea";
  import { CrossIcon, PlusCircleIcon } from "lucide-react";
  import { useState, useEffect } from "react";
  import { Building, Resource, Happening } from "@/lib/types";
  import { collection, doc } from "firebase/firestore";
  import { db } from "../../config/firebase";
  import { useToast } from "../ui/use-toast";
  import dayjs, { Dayjs } from "dayjs";
  import { v4 as uuidv4 } from "uuid"
  
  interface HappeningDialogProps {
    building: Building;
    happening?: Happening | null;
    onSave: (updatedBuilding: Building) => void;
    trigger?: React.ReactNode;
    open?: boolean;
    onOpenChange?: (open: boolean) => void;
  }
  
  const HappeningDialog: React.FC<HappeningDialogProps> = ({
    building,
    happening,
    onSave,
    trigger,
    open,
    onOpenChange,
  }) => {
    const [editedHappening, setEditedHappening] = useState<Happening>({
      id: happening?.id || uuidv4(),
      variant: happening?.variant || "event",
      name: happening?.name || "",
      description: happening?.description || "",
      allday: happening?.allday || false,
      starttime: happening?.starttime || dayjs(),
      endtime: happening?.endtime || dayjs().add(1, 'hour'),
      resource: happening?.resource || "",
    });
  
    const { toast } = useToast();
  
    useEffect(() => {
      if (happening) {
        setEditedHappening(happening);
      } else {
        setEditedHappening({
          id: uuidv4(),
          variant: "event",
          name: "",
          description: "",
          allday: false,
          starttime: dayjs(),
          endtime: dayjs().add(1, 'hour'),
          resource: "",
        });
      }
    }, [happening, building.resources]);
  
    const handleInputChange = (
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      const { name, value } = e.target;
      setEditedHappening((prev) => ({
        ...prev,
        [name]: value,
      }));
    };
  
    const handleVariantChange = (value: string) => {
      setEditedHappening((prev) => ({
        ...prev,
        variant: value,
      }));
    };
  
    const handleAlldayChange = (checked: boolean) => {
      setEditedHappening((prev) => ({
        ...prev,
        allday: checked,
        // If all day, reset start and end times to start of day
        starttime: checked ? dayjs(prev.starttime).startOf('day') : prev.starttime,
        endtime: checked ? dayjs(prev.endtime).startOf('day').add(1, 'hour') : prev.endtime,
      }));
    };
  
    const handleSave = () => {

    var updatedBuilding = building;
    if (building.happenings) {
        updatedBuilding = {
            ...building,
            happenings: [...building.happenings, editedHappening]
        };
    } else {
        updatedBuilding = {
            ...building,
            happenings: [editedHappening]
        };
    }

      onSave(updatedBuilding);
      onOpenChange && onOpenChange(false);
      
      toast({
        variant: "default",
        title: "Your event or deal has been saved.",
        description: `Don't forget to save your facility.`,
      });
    };
  
    // Determine if the Save button should be disabled
    const isSaveDisabled =
      editedHappening.name.trim() === "";
  
    return (
      <Dialog open={open} onOpenChange={onOpenChange}>
        {/* Conditionally render the trigger if it's provided */}
        {trigger && <DialogTrigger asChild>{trigger}</DialogTrigger>}
        <DialogContent className="bg-white w-5/12 max-w-2xl overflow-y-scroll max-h-[90vh]">
          <DialogHeader>
            <DialogTitle>
              {happening ? "Edit Event or Deal" : "Add New Event or Deal"}
            </DialogTitle>
            <DialogDescription>
              {happening
                ? "Modify the details of your existing deal or event."
                : "We'll promote your facility and help drive foot traffic."}
            </DialogDescription>
          </DialogHeader>
          <div className="grid grid-cols-1 gap-4 py-2">
            {/* Variant Selection */}
            <div className="grid grid-cols-1 items-center gap-1">
              <Label htmlFor="variant">
                Type<sup>*</sup>
              </Label>
              <Select
                value={editedHappening.variant}
                onValueChange={handleVariantChange}
              >
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="Select variant..." />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="event">Event</SelectItem>
                  <SelectItem value="deal">Deal</SelectItem>
                </SelectContent>
              </Select>
            </div>
  
            {/* Name Input */}
            <div className="grid grid-cols-1 items-center gap-1">
              <Label htmlFor="name">
                Name<sup>*</sup>
              </Label>
              <Input
                id="name"
                name="name"
                required
                value={editedHappening.name}
                onChange={handleInputChange}
                placeholder="e.g. Halloween Celebration"
              />
            </div>
  
            {/* Description Input */}
            <div className="grid grid-cols-1 items-center gap-1">
              <Label htmlFor="description">Description</Label>
              <Textarea
                id="description"
                name="description"
                value={editedHappening.description}
                onChange={handleInputChange}
                placeholder="Provide a brief description of the happening..."
              />
            </div>
  
            {/* All Day Toggle */}
            <div className="flex items-center space-x-2">
              <Label htmlFor="allday">All Day</Label>
              <Switch
                id="allday"
                checked={editedHappening.allday}
                onCheckedChange={handleAlldayChange}
              />
            </div>
  
            {/* Start Time Picker */}
            <div className="grid grid-cols-1 items-center gap-1">
              <Label htmlFor="starttime">Start Time</Label>
              <Input
                type="datetime-local"
                id="starttime"
                name="starttime"
                value={dayjs(editedHappening.starttime).format("YYYY-MM-DDTHH:mm")}
                onChange={(e) =>
                  setEditedHappening((prev) => ({
                    ...prev,
                    starttime: dayjs(e.target.value),
                  }))
                }
                disabled={editedHappening.allday}
              />
            </div>
  
            {/* End Time Picker */}
            <div className="grid grid-cols-1 items-center gap-1">
              <Label htmlFor="endtime">End Time</Label>
              <Input
                type="datetime-local"
                id="endtime"
                name="endtime"
                value={dayjs(editedHappening.endtime).format("YYYY-MM-DDTHH:mm")}
                onChange={(e) =>
                  setEditedHappening((prev) => ({
                    ...prev,
                    endtime: dayjs(e.target.value),
                  }))
                }
                disabled={editedHappening.allday}
              />
            </div>

            <div className="grid grid-cols-1 items-center gap-1">
              <Label htmlFor="name">
                Link<sup>*</sup>
              </Label>
              <Input
                id="resource"
                name="resource"
                required
                value={editedHappening.resource}
                onChange={handleInputChange}
                placeholder="e.g. eventbrite.com/book-now"
              />
            </div>
  
            
          </div>
          <DialogFooter>
            <Button variant="outline" onClick={() => onOpenChange && onOpenChange(false)}>
              Cancel
            </Button>
            <Button onClick={handleSave} disabled={isSaveDisabled}>
              Save
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    );
  };
  
  export default HappeningDialog;
  