import {
    Card,
    CardHeader,
    CardTitle,
  } from "../ui/card"
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
  } from "../ui/table"
import React, { useState, useEffect } from "react"
import { Building, HoursProfile, HoursPeriod } from "../../lib/types"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogClose,
    DialogTrigger,
  } from "../ui/dialog"
  import { Input } from "../ui/input"
  import { Label } from "../ui/label"
import { Button } from "../ui/button"
import { DotsHorizontalIcon, BookmarkFilledIcon } from "@radix-ui/react-icons"
import { useToast } from "../ui/use-toast"
import { v4 as uuidv4 } from "uuid"
import Circle from '@uiw/react-color-circle';
import PeriodsSelector from "./periods-selector"
import { CircleHelp, PlusCircle } from "lucide-react";
import { ScrollArea } from "../ui/scroll-area"
  

interface BuildingProfilesProps {
    options: Building;
    onUpdate: (updatedOptions: Building) => void;
    onUndo: () => void;
}

export default function BuildingProfiles({ options, onUpdate, onUndo }: BuildingProfilesProps) {

    useEffect(() => {
        //console.log(options);
        setEditedOptions(options);
    }, [options]);
    
    const [editedOptions, setEditedOptions] = useState(options);
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const [profileToDelete, setProfileToDelete] = useState<string | null>(null);
    const { toast } = useToast();
    const [hex, setHex] = useState("#f44336");
    const [profilePeriods, setProfilePeriods] = useState<HoursProfile | null>(null);
    const [isSaveDisabled, setIsSaveDisabled] = useState(false);
    const [filterText, setFilterText] = useState("");

    // Function to handle the change in filter text
    const handleFilterTextChange = (e) => {
        setFilterText(e.target.value);
    };

    // Filter the profiles based on the filter text
    const filteredProfiles = editedOptions.profiles.filter(profile =>
        profile.name.toLowerCase().includes(filterText.toLowerCase())
    );

    const handleCreateProfile = (newProfile: HoursProfile) => {
        const updatedOptions = {
            ...options,
            profiles: [...options.profiles, newProfile]
        };
        onUpdate(updatedOptions);
        toast({
            title: "Profile created!",
            description: "It's now available to apply to days.",
        })
    };

    const handleColorChange = (color: string, name: string) => {
        const event: React.ChangeEvent<HTMLInputElement> = {
            target: {
                name: name,
                value: color
            }
        } as React.ChangeEvent<HTMLInputElement>; // Add type assertion here

        handleInputChange(event);
    }

    const handleNameChange = (name: string, id: string) => {
        if (name != "") {
            setIsSaveDisabled(false);
        } else {
            setIsSaveDisabled(true);
        }
    }

    const handlePeriodChange = (periods: HoursPeriod[], name: string) => {
        const event: React.ChangeEvent<HTMLInputElement> = {
            target: {
                name: name,
                value: periods
            }
        } as unknown as React.ChangeEvent<HTMLInputElement>;

        //("in handlePeriodChange");
        //(event.target.value);
        handleInputChange(event);
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        // Split the name to get the field and profile ID
        const [field, profileId] = name.split("---");

        setEditedOptions((prevOptions) => {
            // Find the profile in the array by ID
            const updatedProfiles = prevOptions.profiles.map((profile) =>
                profile.id === profileId
                    ? {
                        ...profile,
                        [field]: value,
                    }
                    : profile
            );
            // Update the state with the modified profiles
            return {
                ...prevOptions,
                profiles: updatedProfiles,
            };
        });
    };

    const handleDeleteClick = (profileId: string) => {
        setProfileToDelete(profileId);
        setDeleteConfirmationOpen(true);
    };

    const handleDeleteConfirmation = () => {
        if (profileToDelete) {
            // Filter out the profile to delete
            const updatedProfiles = editedOptions.profiles.filter((profile) => profile.id !== profileToDelete);
            
            // Filter out events with the profileId matching the profile to delete
            const updatedEvents = editedOptions.events.filter((event) => event.profileId !== profileToDelete);
    
            // Create the updated building options with updated profiles and events
            const updatedOptions: Building = {
                ...editedOptions,
                profiles: updatedProfiles,
                events: updatedEvents,
            };
    
            // Update the state and call the onUpdate callback
            setEditedOptions(updatedOptions);
            onUpdate(updatedOptions);
    
            // Close the confirmation dialog
            setDeleteConfirmationOpen(false);
    
            // Show a toast notification for profile deletion
            toast({
                variant: "destructive",
                title: "Profile deleted.",
                description: "It's been removed from all days where it was applied.",
            });
        }
    };

    const resetEdited = () => {
        ////console.log("options");
        setEditedOptions(options);
    }

    const handleSaveClick = () => {
        // Create a deep copy of editedOptions to avoid mutating the original object
        const updatedOptions: Building = {
            ...editedOptions,
            profiles: editedOptions.profiles.map(profile => ({
                ...profile,
                periods: profile.periods.filter(period => period.name.trim() !== "")
            }))
        };
    
        // Call onUpdate with the updated options
        onUpdate(updatedOptions);
    
        toast({
            title: "Changes made!",
            description: "Your changes have been made.",
        });
    };    

    return (
        <Card className="bg-white">
            <CardHeader>
                <div className="flex space-x-2 w-full">
                    <div className="flex mt-1 items-center space-x-2 w-full">
                    <CardTitle>Daily Profiles</CardTitle>
                    <Dialog>
                        <DialogTrigger asChild>
                            <CircleHelp className="h-4 w-4 mt-1 color-gray align-top" style={{ color: 'gray' }} />
                        </DialogTrigger>
                        <DialogContent className="bg-white w-4/12 max-w-6/12 overflow-y-scroll max-h-[90vh]">
                            <DialogHeader>
                                <DialogTitle>What are profiles?</DialogTitle>
                                <DialogDescription>
                                    Profiles are customizable and reusable daily open-hours templates. You can configure different open hours for each profile. For example, you might add one profile for weekday hours and another for weekend hours. You may then apply them to different days and date ranges using our recurrence options. For example, you might set your "Special Monday Hours" to repeat on the first Monday of each month, or schedule your "Holiday Hours" profile to be active on New Year's Eve. 
                                </DialogDescription>
                            </DialogHeader>
                        </DialogContent>
                    </Dialog>
                    </div>
                    <div className="ml-auto flex space-x-4">
                        <Dialog>
                            <DialogTrigger asChild>
                                <Button className="self-end" variant="outline" onClick={() => setIsSaveDisabled(true)}>
                                    <div className="flex items-center space-x-0">
                                        <PlusCircle className="h-4 w-4 color-gray align-top mr-2" />
                                        <p>Add Profile</p>
                                    </div>
                                </Button>
                            </DialogTrigger>
                            <DialogContent
                            onInteractOutside={(e) => {
                                e.preventDefault();
                            }}
                            className="bg-white w-5/12 max-w-6/12 overflow-y-scroll max-h-[90vh]">
                                <DialogHeader>
                                <DialogTitle>Add Profile</DialogTitle>
                                <DialogDescription>
                                    Create your profile here. Don't forget to click save when you're done.
                                </DialogDescription>
                                </DialogHeader>
                                <div className="grid gap-4 py-4">
                                    <div className="grid grid-cols-5 items-center gap-4">
                                        <Label htmlFor="name" className="text-right">
                                            Swatch
                                        </Label>
                                        <Circle
                                            className="col-span-3"
                                            id="color"
                                            colors={['#f44336', '#e91e63', '#9c27b0', '#673ab7', '#3f51b5', '#2196f3', '#03a9f4', '#00bcd4', '#009688', '#4caf50', '#8bc34a', '#cddc39', '#ffeb3b', '#ffc107', '#ff9800', '#ff5722', '#795548', '#9e9e9e', '#607d8b', '#000000']}
                                            color={hex}
                                            onChange={(color) => {
                                                setHex(color.hex);
                                            }}
                                        />
                                    </div>
                                    <div className="grid grid-cols-5 items-center gap-4">
                                        <Label htmlFor="name" className="text-right">
                                        Name*
                                        </Label>
                                        <Input id="name" required
                                        onChange={(event) => {
                                            handleInputChange(event);
                                            handleNameChange(event.target.value, "");
                                        }}
                                        placeholder="Saturday Hours" className="col-span-3" />
                                    </div>
                                    <div className="grid grid-cols-5 items-center gap-4">
                                        <Label htmlFor="username" className="text-right">
                                        Description
                                        </Label>
                                        <Input id="description" required placeholder="Closed all-day on Saturdays." className="col-span-3" />
                                    </div>
                                    <PeriodsSelector options={profilePeriods} isNew={true} onUpdate={(profile) => {
                                        setProfilePeriods(profile);
                                    }}/>
                                </div>
                                <DialogFooter>
                                <DialogClose asChild>
                                    <Button onClick={() => {
                                        const nameInput = document.getElementById("name") as HTMLInputElement;
                                        const descriptionInput = document.getElementById("description") as HTMLInputElement;
                                        const colorInput = hex;
                                        const periods = profilePeriods?.periods || [];

                                        const newProfile: HoursProfile = {
                                            id: uuidv4(),
                                            name: nameInput.value,
                                            description: descriptionInput.value,
                                            color: colorInput,
                                            periods: periods
                                        };
                                        //(newProfile);

                                        handleCreateProfile(newProfile);
                                        
                                    }}
                                    disabled={isSaveDisabled}>Save</Button>
                                </DialogClose>
                                </DialogFooter>
                            </DialogContent>
                        </Dialog>
                    </div>
                </div>
                {/**<CardDescription>Define profiles that can be applied to this building's calendar.</CardDescription>**/}
                {editedOptions.profiles.length == 0 && (
                    <p className="w-full py-[120px] text-sm text-center align-middle">No profiles defined.</p>
                )}
                {editedOptions.profiles.length > 0 && (
                <div>
                    <div className="mb-4">
                        <Input
                            type="text"
                            placeholder="Filter profiles by name"
                            value={filterText}
                            onChange={handleFilterTextChange}
                            className="w-full mt-3"
                        />
                    </div>
                <ScrollArea className="h-[220px] border rounded-md">
                <Table className="max-w-5/12 text-xs">
                    <TableHeader>
                        <TableRow>
                            <TableHead className="w-[60px]">Swatch</TableHead>
                            <TableHead>Name</TableHead>
                            <TableHead>Description</TableHead>
                            <TableHead>Edit</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {filteredProfiles.map((profile) => (
                            <TableRow key={profile.id}>
                                <TableCell><BookmarkFilledIcon className="h-4 w-5" style={{ color: profile.color }} /></TableCell>
                                <TableCell>{profile.name}</TableCell>
                                <TableCell><div className="line-clamp-2">{profile.description}</div></TableCell>
                                <TableCell>
                                    <Dialog onOpenChange={resetEdited}>
                                        <DialogTrigger asChild>
                                            <Button variant="ghost" className="h-8 w-8 p-0" onClick={() => setIsSaveDisabled(false)}>
                                                <span className="sr-only">Edit</span>
                                                <DotsHorizontalIcon className="h-4 w-4" />
                                            </Button>
                                        </DialogTrigger>
                                        <DialogContent 
                                         onInteractOutside={(e) => {
                                            e.preventDefault();
                                        }}
                                        className="bg-white w-5/12 max-w-6/12 overflow-y-scroll max-h-[90vh]">
                                            <DialogHeader>
                                            <DialogTitle>Edit Profile</DialogTitle>
                                            <DialogDescription>
                                                Changes made here will be seen in all days using this profile. Don't forget to click save when you're done.
                                            </DialogDescription>
                                            </DialogHeader>
                                            <div className="grid gap-4 py-2">
                                            <div className="grid grid-cols-5 items-center gap-4">
                                                <Label htmlFor="name" className="text-right">
                                                    Swatch
                                                </Label>
                                                <Circle
                                                    className="col-span-3"
                                                    colors={['#f44336', '#e91e63', '#9c27b0', '#673ab7', '#3f51b5', '#2196f3', '#03a9f4', '#00bcd4', '#009688', '#4caf50', '#8bc34a', '#cddc39', '#ffeb3b', '#ffc107', '#ff9800', '#ff5722', '#795548', '#9e9e9e', '#607d8b', '#000000']}
                                                    color={profile.color}
                                                    // Simulate input change event
                                                    onChange={(color) => {
                                                        handleColorChange(color.hex, `color---${profile.id}`);
                                                    }}
                                                />
                                            </div>
                                                <div className="grid grid-cols-5 items-center gap-4">
                                                    <Label htmlFor="name" className="text-right">
                                                    Name*
                                                    </Label>
                                                    <Input id="name" name={`name---${profile.id}`} required 
                                                    onChange={(event) => {
                                                        handleInputChange(event);
                                                        handleNameChange(event.target.value, profile.id);
                                                    }}
                                                     placeholder="Saturday Hours" value={profile.name} className="col-span-3" />
                                                </div>
                                                <div className="grid grid-cols-5 items-center gap-4">
                                                    <Label htmlFor="username" className="text-right">
                                                    Description
                                                    </Label>
                                                    <Input id="description" name={`description---${profile.id}`} placeholder="Closed all-day on Saturdays." value={profile.description} required onChange={handleInputChange} className="col-span-3"/>
                                                </div>
                                                <PeriodsSelector options={profile} onUpdate={(profile) => {
                                                    //setProfilePeriods(profile);
                                                    handlePeriodChange(profile.periods, `periods---${profile.id}`);
                                                }}/>
                                            </div>
                                            <DialogFooter>
                                                <DialogClose asChild>
                                                    <Button type="submit" variant="destructive" onClick={() => handleDeleteClick(profile.id)}>Delete</Button>
                                                </DialogClose>
                                                <DialogClose asChild>
                                                    <Button type="submit" disabled={isSaveDisabled} onClick={handleSaveClick}>Save</Button>
                                                </DialogClose>
                                            </DialogFooter>
                                        </DialogContent>
                                    </Dialog>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                </ScrollArea>
                </div>
                )}

                <Dialog open={deleteConfirmationOpen} onOpenChange={() => setDeleteConfirmationOpen(false)}>
                    <DialogContent className="bg-white w-5/12">
                        <DialogHeader>
                            <DialogTitle>Confirm Deletion</DialogTitle>
                            <DialogDescription>
                                Are you sure you want to delete this profile? All days using this profile will be affected.
                            </DialogDescription>
                        </DialogHeader>
                        <DialogFooter>
                            <DialogClose asChild>
                                <Button type="button" onClick={handleDeleteConfirmation} variant="destructive">
                                    Confirm Delete
                                </Button>
                            </DialogClose>
                            <DialogClose asChild>
                                <Button type="button" onClick={() => setDeleteConfirmationOpen(false)}>
                                    Cancel
                                </Button>
                            </DialogClose>
                        </DialogFooter>
                    </DialogContent>
                </Dialog>
            </CardHeader>
        </Card>
    )
}