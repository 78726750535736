import React, { useEffect, useState } from "react";
import { Card, CardHeader, CardTitle, CardDescription } from "../ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tabs";
import { Badge } from "../ui/badge";
import { MapPinIcon } from "lucide-react";
import { Carousel, CarouselContent, CarouselItem } from "../ui/carousel";
import dayjs from "dayjs";
import FacilityHours from "./facility-hours-widget";
import { HoursProfile, CalendarEvent } from "../../lib/types";
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(isSameOrAfter);

// Interfaces for data types
export interface Resource {
  title: string;
  url: string;
}

export interface Happening {
    id: string;
    variant: string;
    name: string;
    description: string;
    allday: boolean;
    starttime: string;
    endtime: string;
    resource: string;
}

export interface Building {
  fullname: string;
  description: string;
  address: string;
  phone: string;
  website: string;
  contact: string;
  banner?: {
    enabled: boolean;
    variant: string;
    message: string;
  };
  profiles: Array<HoursProfile>;
events: Array<CalendarEvent>;
  resources: Resource[];
  happenings: Happening[];
}

// Widget Component
const BuildingInfoWidget: React.FC<{ school: string; facilityId: string }> = ({
  school,
  facilityId,
}) => {
  const [building, setBuilding] = useState<Building | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    // Fetch facility information from API
    const fetchBuildingInfo = async () => {
      try {
        const response = await fetch(
          `https://us-central1-newgrange-b0285.cloudfunctions.net/getFacilityInfo?school=${school}&facilityId=${facilityId}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch building info");
        }
        const data = await response.json();
        setBuilding(data);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setError("Failed to load building information.");
        setLoading(false);
      }
    };

    fetchBuildingInfo();
  }, [school, facilityId]);

  // If data is still loading
  if (loading) {
    return <p>Loading...</p>;
  }

  // If there was an error
  if (error) {
    return <p>{error}</p>;
  }

  // If no building data found
  if (!building) {
    return <p>No building information found.</p>;
  }

  /**
   * export interface Building {
        DONE id: string;
        DONE parentId?: string;
        DONE fullname: string;
        DONE shortname: string;
        DONE description: string;
    address: string;
    phone: string;
    website: string;
    contact: string;
    category: FacilityCategory;
    OPEN HOURS profiles: Array<HoursProfile>;
    OPEN HOURS events: Array<CalendarEvent>;
    happenings: Array<Happening>;
    OPEN HOURS notes: Array<DayNote>;
    resources: Array<Resource>;
        DONE banner: Banner;
   */

        // Filter and sort happenings function
const getFilteredAndSortedHappenings = (happenings: Happening[]) => {
    const today = dayjs().startOf('day');
  
    return happenings
      .filter((happening) => {
        const start = dayjs(happening.starttime);
        const end = dayjs(happening.endtime);
        
        // Include if happening starts today or after, or spans today or later
        if (happening.allday) {
            return (
                start.isSame(today, 'day') || // If the start date is today
                (start.isBefore(today) && end.isSameOrAfter(today, 'day')) // If the event spans today
              );
        } else {
            return end.isAfter(today) || end.isSame(today, 'day');
        }
      })
      .sort((a, b) => dayjs(a.starttime).diff(dayjs(b.starttime)));
  };
  
  // Updated getHappeningBadgeText function
  const getHappeningBadgeText = (happening: Happening): string => {
    const start = dayjs(happening.starttime);
    const end = dayjs(happening.endtime);
  
    if (start.isSame(end, 'day')) {
      // Single-day event
      return happening.allday
        ? `All Day on ${start.format('M/D')}`
        : `${start.format('h:mm A')} to ${end.format('h:mm A')} on ${start.format('M/D')}`;
    } else {
      // Multi-day event
      return `${start.format('h:mm A M/D')} to ${end.format('h:mm A M/D')}`;
    }
  };

  return (
    <Card className="w-full shadow-md">
      <CardHeader>
      <CardTitle>{building.fullname}</CardTitle>
        <CardDescription className="line-clamp-2">{building.description}</CardDescription>
        <CardDescription className="text-xs flex items-center pb-2">
            <MapPinIcon className="h-3 w-3 mr-1 text-gray-500" />
            {building.address}
        </CardDescription>

        {/* Banner */}
        {building.banner?.enabled && (
          <div
            className={`mt-4 px-2 py-1 rounded text-xs ${
              building.banner.variant === "alert"
                ? "bg-red-100 text-red-800"
                : "bg-blue-100 text-blue-800"
            }`}
          >
            
            {building.banner?.variant === "alert" && 
                <span className="font-bold">ALERT: </span>
            }
            {building.banner.message}
          </div>
        )}
      {/* Tabs for More Information and Events/Deals */}
      <Tabs defaultValue="facility-hours" className="py-2">
        <TabsList className="w-full grid grid-cols-3">
            <TabsTrigger value="facility-hours" className="text-center text-xs font-bold">
            Facility Hours
          </TabsTrigger>
          <TabsTrigger value="events-deals" className="text-center text-xs font-semibold">
            Events & Deals
          </TabsTrigger>
          <TabsTrigger value="more-info" className="text-center text-xs font-semibold">
            More Information
          </TabsTrigger>
        </TabsList>

        {/* More Information Tab */}
        <TabsContent value="more-info" className="mt-4">
          <div className="space-y-1">
            
            {/* Resources Section */}
            {building.resources.length > 0 && (
              <div className="mb-4">
             <p className="text-xs font-bold text-gray-400">EXTERNAL RESOURCES</p>
             <ul className="list-disc">
                  {building.resources.map((resource, index) => (
                    <div>
                        <a
                        href={resource.url}
                        className="text-sm font-semibold text-blue-500"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {resource.title}
                      </a>
                      </div>
                  ))}
                </ul>
              </div>
            )}

             <p className="text-xs font-bold text-gray-400">CONTACT INFORMATION</p>
            <p className="text-sm text-gray-500">Phone: <span className="text-black font-semibold">{building.phone ? building.phone : "none"}</span></p>
            <p className="text-sm text-gray-500">
            Website:{" "}<span>
            <a
                href={building.phone ? building.phone : "#"}
                className="text-blue-500 font-semibold"
                target="_blank"
                rel="noopener noreferrer"
            >
                {building.website ? building.website : "none"}
            </a>
            </span>
            </p>
            <p className="text-sm text-gray-500">
            Contact: <span className="text-black font-semibold">{building.contact ? building.contact : "none"}</span>
            </p>

          </div>
        </TabsContent>

        {/* More Information Tab */}
        <TabsContent value="facility-hours" className="mt-4">
            <FacilityHours building={building}/>
        </TabsContent>

        {/* Upcoming Events & Deals Tab */}
        <TabsContent value="events-deals" className="mt-1">
           
          {building.happenings.length > 0 ? (
            <div>
            <span className="ml-2 font-light text-xs text-gray-400">swipe to scroll →</span>
            <Carousel
            opts={{
                align: "start",
                loop: false,
              }}>
            <CarouselContent>
            {getFilteredAndSortedHappenings(building.happenings).map((happening) => (
                <CarouselItem className="basis-1/2">
                <Card
                  key={happening.id}
                  className="pt-2 pl-3 pr-3 pb-2.5 drop-shadow-sm mb-2"
                >
                <div className="flex items-center space-x-1">
                    {happening.variant === "event" && (
                        <Badge variant="outline" className="text-xs mr-1 mb-1 font-bold bg-green-500 text-white px-2">
                        EVENT
                        </Badge>
                    )}
                    {happening.variant === "deal" && (
                        <Badge variant="outline" className="text-xs mr-1 mb-1 font-bold bg-violet-500 text-white px-2">
                        DEAL
                        </Badge>
                    )}
                </div>
                <Badge
                    variant="outline"
                    className="text-xs mb-1 px-2"
                >
                    {getHappeningBadgeText(happening)}
                </Badge>
                  <h3 className="text-sm font-bold">{happening.name}</h3>
                  <p className="text-xs text-gray-600">{happening.description}</p>
                  {happening.resource && (
                    <div>
                        <a href={happening.resource} target="_blank" rel="noopener noreferrer" className="text-blue-500 text-xs font-bold">
                        Learn more »
                        </a>
                    </div>
                    )}
                </Card>
                </CarouselItem>
              ))}
              </CarouselContent>
              </Carousel>
            </div>
          ) : (
            <p className="text-sm text-gray-500 mt-5">No upcoming events or deals.</p>
          )}
        </TabsContent>
      </Tabs>
      <p className="ml-auto font-light text-xs text-gray-400 text-right">powered by <span className="font-semibold">CampusCommons</span></p>
      </CardHeader>
    </Card>
  );
};

export default BuildingInfoWidget;
