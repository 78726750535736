// src/pages/ProtectedRoute/ProtectedRoute.js

import React, { ReactNode } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useAuth } from "../../context/AuthContext";

interface ProtectedRouteProps {
    children: ReactNode;
}  

export const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const { user, loading } = useAuth();
  const { school } = useParams();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  // Check if user's school matches the URL school
  if (user.customClaims.school !== school) {
    return <Navigate to="/not-found" replace />;
  }

  return children;
};