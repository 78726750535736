import MainSidebar from '../../components/main-sidebar';
import '../../App.css';
import MenuBar from '../../components/menu-bar';

function DashboardPage() {
    return (
        <div className='App2 h-screen'>
            <MenuBar />
            
        </div>
    );
}

export default DashboardPage;