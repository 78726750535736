// src/router/index.js

import { createBrowserRouter, Route } from 'react-router-dom';
import App from '../App';
import LoginPage from '../pages/LoginPage/LoginPage';
import NotFoundPage from '../pages/NotFoundPage/NotFoundPage';
import { ProtectedRoute } from '../pages/ProtectedRoute/ProtectedRoute';
import HoursPage from '../pages/HoursPage/HoursPage';
import LandingPage from '../pages/LandingPage/LandingPage';
import PublicRoute from '../pages/PublicRoute/PublicRoute';
import ManagementPage from '../pages/ManagementPage/ManagementPage';
import AdministrationPage from '../pages/AdministrationPage/AdministrationPage';
import UsersAdminPage from '../pages/AdministrationPage/UsersAdminPage';
import OnboardPage from '../pages/OnboardPage/OnboardPage';
import SignupPage from '../pages/SignupPage/SignupPage';
import DashboardPage from '../pages/DashboardPage/DashboardPage';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <NotFoundPage />,
    children: [
      {
        path: '/login',
        element: <PublicRoute element={<LoginPage />} redirectTo="/:school/console" />
      },
      {
        path: '/signup',
        element: <PublicRoute element={<SignupPage />} redirectTo="/:school/console" />
      },
      {
        path: '/onboard',
        element: <PublicRoute element={<OnboardPage />} redirectTo="/:school/console" />
      },
      // Dynamic School Routes
      {
        path: '/:school',
        children: [
          {
            index: true, // This means the default route when "/:school" is hit
            element:  <DashboardPage />, // Can be any default content, dashboard, etc.
          },
          {
            path: 'console',
            element: <ProtectedRoute><ManagementPage /></ProtectedRoute>,
          },
          {
            path: 'hours',
            element: <ProtectedRoute><HoursPage /></ProtectedRoute>
          },
          {
            path: 'admin',
            element: <ProtectedRoute><AdministrationPage /></ProtectedRoute>
          },
          {
            path: 'admin/users',
            element: <ProtectedRoute><UsersAdminPage /></ProtectedRoute>
          },
          // Add more school-specific routes here
        ]
      },
      // Catch-all route
      {
        path: '*',
        element: <NotFoundPage />,
      },
      {
        path: '/console',
        element: <NotFoundPage />,
      },
      {
        path: '/admin',
        element: <NotFoundPage />,
      },
    ]
  }
]);
