// src/components/general/AllAnnouncementsDialog.tsx

import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogClose,
} from "../ui/dialog";
import { Card } from "../ui/card";
import { Badge } from "../ui/badge";
import { ScrollArea } from "../ui/scroll-area";
import { Button } from "../ui/button";
import dayjs from "dayjs";
import { Announcement } from "../../lib/types"; // Adjust the import path based on your project structure
import { db } from "../../config/firebase"; // Import Firestore instance
import { collection, getDocs, query, orderBy } from "firebase/firestore";
import { toast } from "../ui/use-toast"; // Assuming you have a toast utility

interface AllAnnouncementsDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  schoolId: string;
  facilityId: string;
}

const AllAnnouncementsDialog: React.FC<AllAnnouncementsDialogProps> = ({
  open,
  onOpenChange,
  schoolId,
  facilityId,
}) => {
  const [announcements, setAnnouncements] = useState<Announcement[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  // Fetch announcements when the dialog opens
  useEffect(() => {
    const fetchAnnouncements = async () => {
      setLoading(true);
      setError(null);
      try {
        const announcementsRef = collection(
          db,
          "schools",
          schoolId,
          "facilities",
          facilityId,
          "announcements"
        );
        const q = query(announcementsRef, orderBy("createdAt", "desc"));
        const querySnapshot = await getDocs(q);
        const fetchedAnnouncements: Announcement[] = [];
        querySnapshot.forEach((doc) => {
          fetchedAnnouncements.push(doc.data() as Announcement);
        });
        setAnnouncements(fetchedAnnouncements);
      } catch (err) {
        console.error("Error fetching announcements: ", err);
        setError("Failed to fetch announcements.");
        toast({
          title: "Error",
          description: "There was an error fetching announcements.",
          variant: "destructive",
        });
      } finally {
        setLoading(false);
      }
    };

    if (open) {
      fetchAnnouncements();
    }
  }, [open, schoolId, facilityId]);

  // Sort announcements by createdAt, newest first
  const sortedAnnouncements = announcements.sort((a, b) =>
    dayjs(b.createdAt).diff(dayjs(a.createdAt))
  );

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="bg-white w-4/12 max-w-4/12 overflow-y-auto max-h-[90vh]">
        <DialogHeader>
          <DialogTitle>Announcements History</DialogTitle>
          <DialogDescription>
            Announcements that have been dispatched for this facility.
          </DialogDescription>
        </DialogHeader>

        <ScrollArea className="h-[60vh] w-full mt-2">
          {loading ? (
            <p className="text-center text-sm text-gray-500 mt-4">Loading announcements...</p>
          ) : error ? (
            <p className="text-center text-sm text-red-500 mt-4">{error}</p>
          ) : sortedAnnouncements.length === 0 ? (
            <p className="text-center text-sm text-gray-500 mt-4">
              No announcements found.
            </p>
          ) : (
            <div className="space-y-4">
              {sortedAnnouncements.map((announcement) => (
                <Card
                  key={announcement.id}
                  className="pt-2 pl-3 pr-3 pb-2.5 drop-shadow-sm mb-2"
                >
                  <div className="flex items-center justify-between">
                    {announcement.variant == "alert" && (
                        <Badge
                            variant="outline"
                            className="text-xs mr-1 mb-1 font-bold px-2 bg-red-500 text-white"
                        >
                            {announcement.variant.toUpperCase()}
                        </Badge>
                    )}
                    {announcement.variant == "info" && (
                        <Badge
                            variant="outline"
                            className="text-xs mr-1 mb-1 font-bold px-2"
                        >
                            {announcement.variant.toUpperCase()}
                        </Badge>
                    )}
                    <Badge
                      variant="outline"
                      className="text-xs mb-1 px-2"
                    >
                      {dayjs(announcement.createdAt).format("MMM D, YYYY h:mm A")}
                    </Badge>
                  </div>
                  <h3 className="text-sm font-bold">{announcement.title}</h3>
                  <p className="text-xs text-gray-600">{announcement.content}</p>
                </Card>
              ))}
            </div>
          )}
        </ScrollArea>

        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">Close</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default AllAnnouncementsDialog;