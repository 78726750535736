import { ColumnDef } from "@tanstack/react-table"
import { Building } from "@/lib/types"
import { Button } from "../../../components/ui/button"
import { ChevronsUpDownIcon } from "lucide-react"
import { Checkbox } from "../../../components/ui/checkbox"
import BuildingActions from "../../../components/general/building-actions"

// Export columns as a static array
export const getColumns = (handleOpenDialog: (building: Building) => void) => {
  return (refreshBuildings: () => void): ColumnDef<Building>[] => [
    {
      id: "select",
      header: ({ table }) => (
        <Checkbox
          className="ml-1"
          checked={
            table.getIsAllPageRowsSelected() ||
            (table.getIsSomePageRowsSelected() && "indeterminate")
          }
          onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
          aria-label="Select all"
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          className="ml-1"
          checked={row.getIsSelected()}
          onCheckedChange={(value) => row.toggleSelected(!!value)}
          aria-label="Select row"
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "shortname",
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="pl-0"
        >
          Shorthand
          <ChevronsUpDownIcon className="ml-2 h-4 w-4" />
        </Button>
      ),
    },
    {
      accessorKey: "fullname",
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="pl-0"
        >
          Name
          <ChevronsUpDownIcon className="ml-2 h-4 w-4" />
        </Button>
      ),
    },
    {
      accessorKey: "category",
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="pl-0"
        >
          Category
          <ChevronsUpDownIcon className="ml-2 h-4 w-4" />
        </Button>
      ),
      cell: ({ row }) => {
        const category = row.original.category;
    
        const categoryColors = {
          DINING: "bg-green-100 text-green-800",
          CAFE: "bg-teal-100 text-teal-800",
          SHOP: "bg-orange-100 text-orange-800",
          ACADEMIC: "bg-blue-100 text-blue-800",
          RESIDENTIAL: "bg-yellow-100 text-yellow-800",
          RECREATIONAL: "bg-purple-100 text-purple-800",
          ATHLETIC: "bg-red-100 text-red-800",
          ADMINISTRATIVE: "bg-gray-100 text-gray-800",
          OTHER: "bg-indigo-100 text-indigo-800",
        };
    
        const colorClass = categoryColors[category] || "bg-gray-100 text-gray-800"; // Default color if no match
    
        return (
          <span className={`px-2 py-1 rounded-full text-xs font-bold ${colorClass}`}>
            {category}
          </span>
        );
      },
    },
    {
      accessorKey: "parentId",
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="pl-0"
        >
          Parent
          <ChevronsUpDownIcon className="ml-2 h-4 w-4" />
        </Button>
      ),
      cell: ({ row, table }) => {
        const parentId = row.original.parentId; // Get the parentId from the row data
        const facilityOptions = table.options.data; // Access all facility options (buildings list)
        
        // Find the parent building by ID
        const parentBuilding = facilityOptions.find(building => building.id === parentId);
        
        // Return the shorthand if found, otherwise fallback to 'N/A'
        return (
          <span>
            {parentBuilding ? parentBuilding.shortname : "N/A"}
          </span>
        );
      }
    },
    {
      accessorKey: "address",
      header: "Address",
    },
    {
      id: "actions",
      cell: ({ row }) => {
        const building = row.original; // Access building data here
        return (
          <BuildingActions 
            building={building} 
            refreshBuildings={refreshBuildings} 
            handleOpenDialog={handleOpenDialog}
          />
        );
      },
    },    
  ];
};