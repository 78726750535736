import { Dayjs } from "dayjs";
import dayjs from "dayjs";
import { Building, CalendarEvent, Occurence, Conflict } from "./types";  // Adjust the import path as needed

export const detectConflicts = (building: Building, newOccurrences: Occurence[]): Conflict[] => {
    const conflicts: Conflict[] = [];

    console.log("++++++++++++++++++++++");
    console.log("in detectConflicts, eventOccurrences.");   // Debugging
    console.log(building.events, newOccurrences);   // Debugging

    building.events.forEach((event: CalendarEvent) => {
        const eventOccurrences = event.recurrence?.actualOccurences?.map(occurrence => ({
            ...occurrence,
            date: dayjs(occurrence.date)
        })) || [];

        if (!event.doesRepeat) {
            eventOccurrences.push({ date: dayjs(event.startDate) });
        }

        console.log(eventOccurrences);   // Debugging

        newOccurrences.forEach((newOccurrence) => {
            const newOccurrenceDate = dayjs(newOccurrence.date); // Ensure newOccurrence date is Dayjs

            const conflict = eventOccurrences.find(
                (eventOccurrence) => dayjs(eventOccurrence.date).isSame(newOccurrenceDate)
            );

            console.log(newOccurrence.date);   // Debugging

            if (conflict) {
                conflicts.push({
                    profileId: building.profiles.find(profile => profile.id === event?.profileId)?.id || "none",
                    profileName: building.profiles.find(profile => profile.id === event?.profileId)?.name || "name",
                    conflictDate: conflict.date
                });
            }
        });
    });

    console.log("conflicts: ", conflicts);   // Debugging
    console.log("++++++++++++++++++++++");

    return conflicts;
};

export const optionOne = (building: Building, conflicts: Conflict[], event: CalendarEvent): Building => {
    // Create a copy of the building's events to avoid mutating the original
    const updatedEvents = building.events.map(e => ({ ...e }));

    // Find all conflicts that overlap with the new event
    const overlappingConflicts = conflicts;

    // Remove the conflicting occurrences from the new event's actualOccurrences array
    if (event.recurrence && event.recurrence.actualOccurences) {
        event.recurrence.actualOccurences = event.recurrence.actualOccurences.filter(occurrence =>
            !overlappingConflicts.some(conflict =>
                dayjs(conflict.conflictDate).isSame(dayjs(occurrence.date), 'day')
            )
        );
    }

    // If the new event has no actual occurrences left, don't add it
    if (event.recurrence && event.recurrence.actualOccurences && event.recurrence.actualOccurences.length === 0) {
        return { ...building, events: updatedEvents };
    }

    // Add the new event to the building's events
    updatedEvents.push(event);

    // Return the updated building
    return { ...building, events: updatedEvents };
};

const datesOverlap = (start1: Dayjs, end1: Dayjs | undefined, start2: Dayjs, end2: Dayjs | undefined): boolean => {
    if (!end1 && !end2) {
        // Both are one-time events without end dates
        return start1.isSame(start2, 'day');
    } else {
        const end1Date = end1 || start1;
        const end2Date = end2 || start2;
        return start1.isBefore(end2Date) && end1Date.isAfter(start2);
    }
};

export const optionTwo = (building: Building, conflicts: Conflict[], event: CalendarEvent): Building => {
    console.log("Starting optionTwo");
    console.log("Building:", building);
    console.log("Conflicts:", conflicts);
    console.log("Event:", event);

    // Helper function to check if an event conflicts with another based on recurrence options
    const hasConflict = (existingEvent: CalendarEvent, conflict: Conflict): boolean => {
        console.log("Checking conflict between existing event:", existingEvent, "and conflict:", conflict);

        if (existingEvent.doesRepeat && existingEvent.recurrence?.actualOccurences) {
            const conflictExists = existingEvent.recurrence.actualOccurences.some(occurrence =>
                dayjs(occurrence.date).isSame(dayjs(conflict.conflictDate), 'day')
            );
            console.log("Conflict exists for recurring event:", conflictExists);
            return conflictExists;
        } else {
            const conflictExists = datesOverlap(existingEvent.startDate, existingEvent.endDate, conflict.conflictDate, conflict.conflictDate);
            console.log("Conflict exists for one-time event:", conflictExists);
            return conflictExists;
        }
    };

    // Remove specific occurrences of recurring events and one-time conflicting events
    const updatedEvents = building.events.map(existingEvent => {
        console.log("Processing existing event:", existingEvent);

        if (existingEvent.doesRepeat && existingEvent.recurrence?.actualOccurences) {
            console.log("Event is recurring with actual occurrences");

            // Filter out actual occurrences that conflict with the new event
            const filteredOccurrences = existingEvent.recurrence.actualOccurences.filter(occurrence => {
                const eventProfileId = existingEvent?.profileId;
            
                const hasConflictingOccurrence = conflicts.some(conflict => {
                    const conflictProfileId = conflict.profileId;
                    const isSameDay = dayjs(occurrence.date).isSame(dayjs(conflict.conflictDate), 'day');
            
                    // Debugging logs
                    console.log(`Event Profile ID: ${eventProfileId}`);
                    console.log(`Conflict Profile ID: ${conflictProfileId}`);
                    console.log(`Is same day: ${isSameDay}`);
            
                    return conflictProfileId === eventProfileId && isSameDay;
                });
            
                console.log("Occurrence:", occurrence, "has conflict:", hasConflictingOccurrence, "on day ", occurrence.date);
                return !hasConflictingOccurrence;
            });

            console.log("Filtered occurrences:", filteredOccurrences);

            // Return the event with updated actual occurrences
            if (filteredOccurrences.length > 0) {
                return {
                    ...existingEvent,
                    recurrence: {
                        ...existingEvent.recurrence,
                        actualOccurences: filteredOccurrences,
                    },
                };
            } else {
                return null;
            }
        } else if (!existingEvent.doesRepeat && datesOverlap(existingEvent.startDate, existingEvent.endDate, event.startDate, event.endDate)) {
            console.log("Event is one-time and has a conflict with the new event");
            // Remove one-time conflicting events that occur on the same day
            return null;
        } else {
            console.log("Event has no conflict and will be retained");
            return existingEvent;
        }
    }).filter(Boolean);

    console.log("Updated events after conflict resolution:", updatedEvents);

    // Add the new event
    updatedEvents.push(event);
    console.log("Updated events after adding the new event:", updatedEvents);

    // Return the updated building with the new events array
    return {
        ...building,
        events: updatedEvents as CalendarEvent[],
    };
};

export const optionThree = (building: Building, conflicts: Conflict[], event: CalendarEvent): Building => {
    // Helper function to check if an existing event conflicts with the new event
    const hasConflict = (existingEvent: CalendarEvent, conflictDates: Dayjs[]): boolean => {
        // Check if the event is recurring with actual occurrences
        if (existingEvent.doesRepeat && existingEvent.recurrence && existingEvent.recurrence.actualOccurences) {
            return conflictDates.some(conflictDate =>
                existingEvent.recurrence!.actualOccurences!.some(occurrence => dayjs(occurrence.date).isSame(dayjs(conflictDate), 'day'))
            );
        } else {
            return conflictDates.some(conflictDate =>
                datesOverlap(existingEvent.startDate, undefined, conflictDate, undefined)
            );
        }
    };

    // Get all the conflict dates from the conflicts array
    const conflictDates = conflicts.map(conflict => dayjs(conflict.conflictDate));

    // Filter out the events that have any conflict
    const updatedEvents = building.events.filter(existingEvent => !hasConflict(existingEvent, conflictDates));

    // Add the new event
    updatedEvents.push(event);

    // Return the updated building
    return { ...building, events: updatedEvents };
};