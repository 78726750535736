import { collection, deleteDoc, doc, getDoc, getDocs, setDoc } from "firebase/firestore";
import { db } from '../config/firebase';

// Helper function to back up a building's subcollections (profiles, events, notes) to the archive
async function backupBuildingSubcollections(buildingDocRef: any, archiveBuildingDocRef: any) {
  // Backup profiles
  const profilesCollection = collection(buildingDocRef, "profiles");
  const profilesSnapshot = await getDocs(profilesCollection);
  for (const profileDoc of profilesSnapshot.docs) {
    const periodsCollection = collection(profileDoc.ref, "periods");
    const periodsSnapshot = await getDocs(periodsCollection);
    const periodsData = periodsSnapshot.docs.map(periodDoc => ({ id: periodDoc.id, ...periodDoc.data() }));

    // Copy the profile with periods to the archive
    await setDoc(doc(collection(archiveBuildingDocRef, "profiles"), profileDoc.id), {
      ...profileDoc.data(),
      periods: periodsData
    });
  }

  // Backup events
  const eventsCollection = collection(buildingDocRef, "events");
  const eventsSnapshot = await getDocs(eventsCollection);
  for (const eventDoc of eventsSnapshot.docs) {
    await setDoc(doc(collection(archiveBuildingDocRef, "events"), eventDoc.id), eventDoc.data());
  }

  // Backup notes
  const notesCollection = collection(buildingDocRef, "notes");
  const notesSnapshot = await getDocs(notesCollection);
  for (const noteDoc of notesSnapshot.docs) {
    await setDoc(doc(collection(archiveBuildingDocRef, "notes"), noteDoc.id), noteDoc.data());
  }
}

// Helper function to delete a building's subcollections (profiles, events, notes)
async function deleteBuildingSubcollections(buildingDocRef: any) {
  // Delete profiles
  const profilesCollection = collection(buildingDocRef, "profiles");
  const profilesSnapshot = await getDocs(profilesCollection);
  for (const profileDoc of profilesSnapshot.docs) {
    const periodsCollection = collection(profileDoc.ref, "periods");
    const periodsSnapshot = await getDocs(periodsCollection);
    for (const periodDoc of periodsSnapshot.docs) {
      await deleteDoc(doc(periodsCollection, periodDoc.id));
    }
    await deleteDoc(profileDoc.ref);
  }

  // Delete events
  const eventsCollection = collection(buildingDocRef, "events");
  const eventsSnapshot = await getDocs(eventsCollection);
  for (const eventDoc of eventsSnapshot.docs) {
    await deleteDoc(eventDoc.ref);
  }

  // Delete notes
  const notesCollection = collection(buildingDocRef, "notes");
  const notesSnapshot = await getDocs(notesCollection);
  for (const noteDoc of notesSnapshot.docs) {
    await deleteDoc(noteDoc.ref);
  }
}

// Main function to back up and delete buildings
export async function backupAndDeleteFacilities(buildingIds: string[], school: string): Promise<void> {
  try {
    const buildingsCollection = collection(db, "schools", school, "facilities");
    const archiveCollection = collection(db, "phillipsexeter", "facilities_archive", "list"); // Archive collection

    // Iterate over each building ID
    for (const buildingId of buildingIds) {
      const buildingDocRef = doc(buildingsCollection, buildingId);
      const archiveBuildingDocRef = doc(archiveCollection, buildingId); // Corresponding archive document

      // Fetch building data
      const buildingSnapshot = await getDoc(buildingDocRef);
      if (!buildingSnapshot.exists()) {
        console.warn(`Building with ID ${buildingId} does not exist.`);
        continue;
      }

      const buildingData = buildingSnapshot.data();

      // Backup the main building document to the archive
      //await setDoc(archiveBuildingDocRef, buildingData);

      // Backup subcollections (profiles, events, notes) to the archive
      //await backupBuildingSubcollections(buildingDocRef, archiveBuildingDocRef);

      // After backup, delete the building and its subcollections
      await deleteBuildingSubcollections(buildingDocRef);
      await deleteDoc(buildingDocRef); // Delete the main building document

      console.log(`Building with ID ${buildingId} has been backed up and deleted.`);
    }
  } catch (error) {
    console.error("Error backing up and deleting buildings: ", error);
  }
}