// src/components/general/SendAnnouncementDialog.tsx

import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogClose,
} from "../ui/dialog";
import { Button } from "../ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";
import { Textarea } from "../ui/textarea";
import { Label } from "../ui/label";
import { v4 as uuidv4 } from "uuid"; // For generating unique IDs
import { Announcement } from "../../lib/types";
import { Input } from "../ui/input";
import dayjs from "dayjs";

interface SendAnnouncementDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onSave: (announcement: Announcement) => void;
}

const SendAnnouncementDialog: React.FC<SendAnnouncementDialogProps> = ({ open, onOpenChange, onSave }) => {
  const [variant, setVariant] = useState<string>("info");
  const [title, setTitle] = useState<string>("");
  const [content, setContent] = useState<string>("");

  // Handler for saving the announcement
  const handleSave = () => {
    const newAnnouncement: Announcement = {
      id: uuidv4(),
      variant,
      title,
      content,
      createdAt: dayjs().toISOString()
    };
    onSave(newAnnouncement);
    // Reset form fields after saving
    setVariant("info");
    setTitle("");
    setContent("");
  };

  // Handler for closing the dialog (optional: reset fields)
  const handleClose = () => {
    onOpenChange(false);
    // Optionally reset form fields
    setVariant("info");
    setTitle("");
    setContent("");
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="bg-white w-5/12 max-w-lg">
        <DialogHeader>
          <DialogTitle>Send Announcement</DialogTitle>
          <DialogDescription>
            Announcements will notify users subscribed to this facility. They will also be listed on the facility's page.
          </DialogDescription>
        </DialogHeader>

        <div className="grid gap-4 py-4">
          {/* Variant Selection */}
          <div className="grid gap-1">
            <Label htmlFor="variant">Type</Label>
            <Select value={variant} onValueChange={setVariant}>
              <SelectTrigger className="">
                <SelectValue placeholder="Select variant" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="info">Info</SelectItem>
                <SelectItem value="alert">Alert</SelectItem>
              </SelectContent>
            </Select>
          </div>

          {/* Title Input */}
          <div className="grid gap-1">
            <Label htmlFor="title">Title</Label>
            <Input
              type="text"
              id="title"
              className="input" // Use your input styling
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="e.g. Adjusted Hours for Family Weekend"
            />
          </div>

          {/* Content Textarea */}
          <div className="grid gap-1">
            <Label htmlFor="content">Content</Label>
            <Textarea
              id="content"
              className="resize-none" // Prevent resizing if desired
              value={content}
              onChange={(e) => setContent(e.target.value)}
              placeholder="Enter your content here..."
              rows={4}
            />
          </div>
        </div>

        <DialogFooter>
          <DialogClose asChild>
            <Button type="button" onClick={handleSave} variant="default" disabled={!title || !content}>
              Send
            </Button>
          </DialogClose>
          <DialogClose asChild>
            <Button type="button" onClick={handleClose} variant="outline">
              Cancel
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default SendAnnouncementDialog;