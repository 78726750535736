import * as React from "react"
import { Link } from "react-router-dom";
import { cn } from "../lib/utils"
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from "../components/ui/navigation-menu"
import { useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth'; // Make sure to import this if using Firebase Auth
import { auth } from '../config/firebase'; // Import your Firebase Auth instance
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../components/ui/avatar"
import { Button } from "../components/ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from "../components/ui/dropdown-menu"
import { useAuth } from "../context/AuthContext";

export function UserNav() {


  const navigate = useNavigate();
  const { user } = useAuth();
  const role = user?.customClaims?.role;
  const email = user?.email;

  const handleSignOut = async () => {
      try {
          await signOut(auth); // Sign out of Firebase
          navigate('/login'); // Navigate to the login page
      } catch (error) {
          console.error("Sign Out Error", error);
          // Handle sign-out error if needed
      }
  };

  return (
    <>
      {email ? (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" className="relative h-8 w-8 rounded-full">
              <Avatar className="h-10 w-10">
                <AvatarFallback>{email.slice(0, 2).toUpperCase()}</AvatarFallback>
              </Avatar>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-56 bg-white" align="end" forceMount>
            <DropdownMenuLabel className="font-normal">
              <div className="flex flex-col space-y-1">
                <p className="text-xs font-semibold leading-none text-muted-foreground">
                  {role === 'admin'
                    ? 'System Administrator'
                    : user?.role === 'manager'
                    ? 'School Manager'
                    : 'Standard User'}
                </p>
                <p className="text-xs leading-none text-muted-foreground">
                  {email}
                </p>
              </div>
            </DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuGroup>
              <DropdownMenuItem>
                <Link to="/console">My Settings</Link>
              </DropdownMenuItem>
              <DropdownMenuItem onClick={handleSignOut}>
                Log Out
              </DropdownMenuItem>
            </DropdownMenuGroup>
          </DropdownMenuContent>
        </DropdownMenu>
      ) : (
        // When user is logged out, show the login button
        <Button
          variant="outline"
          className="px-4 py-2 bg-black text-white"
          onClick={() => navigate('/login')}
        >
          Login
        </Button>
      )}
    </>
  );  
}