// src/components/general/InviteUserDialog.jsx
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogClose,
    DialogTrigger,
  } from "../ui/dialog";
  import { Button } from "../ui/button";
  import { Label } from "../ui/label";
  import { Input } from "../ui/input";
  import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";
  import { useEffect, useState } from "react";
  import { useToast } from "../ui/use-toast";
  import { useAuth } from "../../context/AuthContext";
  import { httpsCallable, getFunctions } from "firebase/functions";
  import { PlusCircleIcon } from "lucide-react";
  
// Define the props interface
interface InviteUserDialogProps {
    refreshUsers: () => void; // Function to refresh the users list
}
  
const InviteUserDialog: React.FC<InviteUserDialogProps> = ({ refreshUsers }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [email, setEmail] = useState("");
    const [selectedRole, setSelectedRole] = useState("user"); // Default role
    const { toast } = useToast();
    const { user } = useAuth(); // Assuming `useAuth` provides `school`
    const school = user?.customClaims?.school;
    const functions = getFunctions();
    const roleDescriptions: { [key: string]: string } = {
      admin: "Admins have full control over all users, facilities, and billing.",
      manager: "Managers can create/delete all facilities and users.",
      user: "Users can only edit the attributes and hours of the buildings they're assigned to.",
    };
    const [roleDescription, setRoleDescription] = useState(roleDescriptions["user"]); // Default description

    useEffect(() => {
      setRoleDescription(roleDescriptions[selectedRole] || "");
    }, [selectedRole]);
  
    const handleInvite = async () => {
      if (!email) {
        toast({
          variant: "destructive",
          title: "Email Required",
          description: "Please enter a valid email address.",
        });
        return;
      }
  
      // Call the Firebase Function to send the invitation
      const sendInvitation = httpsCallable(functions, "sendInvitationEmail");
  
      try {
        console.log(email + selectedRole + school);
        await sendInvitation({ email, role: selectedRole, school });
        toast({
          variant: "celebratory",
          title: "Invitation Sent",
          description: `An invitation has been sent to ${email}.`,
        });
        setEmail("");
        setSelectedRole("user");
        setIsOpen(false);
        refreshUsers(); // Refresh the users list if necessary
      } catch (error) {
        console.error("Error sending invitation:", error);
        toast({
          variant: "destructive",
          title: "Invitation Failed",
          description: "Failed to send invitation.",
        });
      }
    };
  
    return (
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogTrigger asChild>
          <Button variant="outline" size="sm">
            <PlusCircleIcon className="h-4 w-4 mr-2" />
            Invite User
          </Button>
        </DialogTrigger>
        <DialogContent className="bg-white w-5/12 max-w-2xl overflow-y-scroll max-h-[90vh]">
          <DialogHeader>
            <DialogTitle>Invite New User</DialogTitle>
            <DialogDescription>
              Enter the email address of the user you want to invite and assign them a role.
            </DialogDescription>
          </DialogHeader>
          <div className="grid grid-cols-1 gap-4 py-2">
            {/* Email Field */}
            <div className="grid grid-cols-1 items-center gap-1">
              <Label htmlFor="email" className="mb-0">
                <span>Email Address<sup>*</sup></span>
              </Label>
              <DialogDescription className="text-xs">
                e.g. "john.doe@example.com"
              </DialogDescription>
              <Input
                id="email"
                name="email"
                type="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full mb-1"
              />
            </div>
  
            {/* Role Selection */}
            <div className="grid grid-cols-1 items-center gap-1">
              <Label htmlFor="role" className="mb-0">
                <span>Permission Level<sup>*</sup></span>
              </Label>
              <Select onValueChange={(value) => setSelectedRole(value)} defaultValue="user">
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="Select a role..." />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="admin">Admin</SelectItem>
                  <SelectItem value="manager">Manager</SelectItem>
                  <SelectItem value="user">User</SelectItem>
                </SelectContent>
              </Select>
            </div>
            {/* Role Description */}
          <div className="mt-2 p-2 bg-gray-100 rounded">
            <p className="text-sm text-gray-700">{roleDescription}</p>
          </div>
          </div>
          <DialogFooter>
            <DialogClose asChild>
              <Button type="button" onClick={handleInvite} disabled={!email || !selectedRole}>
                Invite
              </Button>
            </DialogClose>
            <DialogClose asChild>
              <Button type="button" variant="outline">
                Cancel
              </Button>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    );
  };
  
  export default InviteUserDialog;  