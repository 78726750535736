import { auth } from "../config/firebase";
import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../components/ui/card";
import { Label } from "../components/ui/label";
import { Input } from "../components/ui/input";
import { Button } from "../components/ui/button";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import MenuBar from "./menu-bar";
import { Separator } from "@radix-ui/react-dropdown-menu";

export const Auth = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    // Basic frontend validation
    if (!email || !password) {
      setError("Please enter both email and password.");
      setLoading(false);
      return;
    }

    try {
      // Call the login function from AuthContext
      await login(email, password);

      // After successful login, redirect to the user's school console
      const user = auth.currentUser;
      const school = user.customClaims.school;
      console.log(user);
      console.log(school);

      if (school) {
        navigate(`/${school}/console`);
      } else {
        setError("User does not have a school associated.");
      }
    } catch (err) {
      console.error("Error during login:", err);
      setError(err.message || "Failed to log in.");
    }

    setLoading(false);
  };

  return (
    <div className="flex flex-col h-screen">
      <MenuBar />
      <div className="h-screen flex items-center justify-center">
        <Card className="w-[380px] bg-white">
          <CardHeader className="">
            <CardTitle className="truenoTitle text-4xl leading-tight">
              Login
            </CardTitle>
          </CardHeader>
          <CardContent>
            {!!error && (
              <div className="py-1">
                <div
                  className="p-2 bg-indigo-800 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex"
                  role="alert"
                >
                  <span className="flex rounded-full bg-indigo-500 uppercase px-2 py-1 text-xs font-bold mr-3">
                    Alert
                  </span>
                  <span className="font-semibold mr-2 text-xs text-left flex-auto">
                    {error}
                  </span>
                </div>
              </div>
            )}
            <div className="space-y-1">
              <div className="space-y-1">
                <Label htmlFor="email">Email</Label>
                <Input
                  className="bg-white"
                  id="email"
                  name="email"
                  autoComplete="email"
                  placeholder="jphillips@exeter.edu"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  type="email"
                />
              </div>
              <div className="space-y-1">
                <Label htmlFor="password">Password</Label>
                <Input
                  className="bg-white"
                  id="password"
                  name="password"
                  placeholder="••••••••"
                  required
                  autoComplete="current-password"
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                />
              </div>
            </div>
          </CardContent>
          <CardFooter>
            <Button
              disabled={loading}
              className="w-full bg-black text-white"
              onClick={handleSubmit}
            >
              {loading ? "Logging in..." : "Login"}
            </Button>
          </CardFooter>
        </Card>
      </div>
    </div>
  );
};
