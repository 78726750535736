// src/pages/SignupPage.tsx

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { auth, db } from '../../config/firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, getDoc, setDoc, deleteDoc } from 'firebase/firestore';
import { Button } from '../../components/ui/button';
import { Input } from '../../components/ui/input';
import { useToast } from '../../components/ui/use-toast';
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
  } from "../../components/ui/card";
import MenuBar from '../../components/menu-bar';

function SignupPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [inviteToken, setInviteToken] = useState('');
  const [school, setSchool] = useState('');
  const { toast } = useToast();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('inviteToken');
    const schoolParam = params.get('school');
    if (token && schoolParam) {
      setInviteToken(token);
      setSchool(schoolParam.toLowerCase());
    }
  }, [location.search]);

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate('/login'); // Redirect to login page after logout
    } catch (error) {
      console.error('Error during logout:', error);
      toast({
        variant: 'destructive',
        title: 'Logout Failed',
        description: 'An error occurred while logging out.',
      });
    }
  };

  const handleSignup = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!inviteToken || !school) {
      toast({
        variant: 'destructive',
        title: 'Invalid Invitation',
        description: 'No invitation token or school found.',
      });
      return;
    }

    try {
      // Reference to the school's invites collection
      const inviteRef = doc(db, `schools/${school}/invites`, inviteToken);
      const inviteSnap = await getDoc(inviteRef);

      if (!inviteSnap.exists()) {
        toast({
          variant: 'destructive',
          title: 'Invalid or Expired Invitation',
          description: 'The invitation link is invalid or has expired.',
        });
        return;
      }

      const { email: invitedEmail, role, expiresAt } = inviteSnap.data();

      // Check if the invitation has expired
      if (expiresAt && expiresAt.toDate() < new Date()) {
        toast({
          variant: 'destructive',
          title: 'Invitation Expired',
          description: 'The invitation link has expired.',
        });
        return;
      }

      // Optionally verify that the email matches
      if (invitedEmail.toLowerCase() !== email.toLowerCase()) {
        toast({
          variant: 'destructive',
          title: 'Email Mismatch',
          description: 'The email does not match the invitation.',
        });
        return;
      }

      // Create user
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Assign role in Firestore under the correct school
      await setDoc(doc(db, `schools/${school}/users`, user.uid), {
        email,
        role,
        assignedBuildings: [],
        name: '', // Add additional fields as needed
      });

      // Delete invite token to prevent reuse
      await deleteDoc(inviteRef);

      toast({
        variant: 'celebratory',
        title: 'Account Created',
        description: 'Your account has been successfully created. Please log in to continue.',
      });

      // Log out the user to refresh custom claims
      await handleLogout();

    } catch (error: any) {
      console.error('Error during sign-up:', error);
      toast({
        variant: 'destructive',
        title: 'Sign-Up Failed',
        description: error.message || 'An error occurred during sign-up.',
      });
    }
  };

  return (
    <div className="flex flex-col h-screen">
        {/* MenuBar occupies its natural height */}
        <MenuBar />
        <div className="flex justify-center items-center h-screen">
            <Card className="w-1/3 bg-white shadow-sm">
            <CardHeader>
            <CardTitle className="truenoTitle text-4xl leading-tight">
                Accept your invite
            </CardTitle>
            <CardDescription>
                You've been invited to your school's CampusCommons! Managing your campus's facilities has never been easier. Let's go!
            </CardDescription>
            </CardHeader>
            <CardContent>
                <form onSubmit={handleSignup} className="flex flex-col gap-4">
                    <Input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    />
                    <Input
                    type="password"
                    placeholder="Create a password..."
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    />
                    <Button className='mt-2' type="submit">Sign Up</Button>
                </form>
            </CardContent>
        </Card>
        </div>
    </div>
  );
}

export default SignupPage;