"use client";

import {
  ColumnDef,
  flexRender,
  SortingState,
  getSortedRowModel,
  getCoreRowModel,
  ColumnFiltersState,
  getFilteredRowModel,
  useReactTable,
  getPaginationRowModel,
} from "@tanstack/react-table";
import * as React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";
import { Button } from "../../../components/ui/button";
import { Input } from "../../../components/ui/input";
//import UserDialog from "../../../components/general/user-dialog"; // You can create this dialog component
import { User } from "@/lib/types"; // Import your User type
import { getColumns } from "./columns";
import { LoadingSpinner } from "../../../components/general/loading";
import { useToast } from "../../../components/ui/use-toast";
import { useState } from "react";
import InviteUserDialog from "../../../components/general/invite-user-dialog";

interface DataTableProps<TData, TValue> {
  columns: (refreshUsers: () => void) => ColumnDef<TData, TValue>[];
  data: TData[];
  refreshUsers: () => void;
}

export function DataTable<TData, TValue>({
  columns,
  data,
  refreshUsers,
}: DataTableProps<TData, TValue>) {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
  const [rowSelection, setRowSelection] = React.useState({});
  const { toast } = useToast();
  const [isLoading, setIsLoading] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  const table = useReactTable({
    data,
    columns: columns(refreshUsers),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      rowSelection,
    },
  });

  const selectedRowCount = Object.keys(rowSelection).length;
  const selectedUserIds = Object.keys(rowSelection);

  React.useEffect(() => {
    if (data.length) {
      setIsLoading(false);
    }
  }, [data]);

  return (
    <div>
      <div className="flex items-center justify-end space-x-2 py-2 pb-6">
        <div className="flex-1 items-center">
          <Input
            placeholder="Filter by email..."
            value={(table.getColumn("email")?.getFilterValue() as string) ?? ""}
            onChange={(event) => table.getColumn("email")?.setFilterValue(event.target.value)}
            className="max-w-sm"
          />
        </div>
        <div>

        <div>
        <InviteUserDialog refreshUsers={refreshUsers} />

        
        </div>
          
        </div>
        {selectedRowCount > 0 && (
          <>
            <Button
              variant="outline"
              size="sm"
              onClick={() => console.log("Assign roles")}
            >
              Assign Facilities
            </Button>
            <Button
              variant="destructive"
              size="sm"
              onClick={() => console.log("Delete users")}
            >
              Delete
            </Button>
          </>
        )}
      </div>

      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead key={header.id}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={7} className="h-10 w-full text-center">
                  <div className="flex justify-center items-center py-10 h-full">
                    <LoadingSpinner />
                  </div>
                </TableCell>
              </TableRow>
            ) : table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow key={row.id} data-state={row.getIsSelected() && "selected"}>
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} className="h-10 w-full text-center">
                  No users matched that email.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>

      <div className="flex items-center justify-end space-x-2 py-4">
        <p className="flex-1 text-xs pl-1 text-muted-foreground">
          {table.getFilteredSelectedRowModel().rows.length} of {table.getFilteredRowModel().rows.length} row(s) selected.
        </p>
        <Button variant="outline" size="sm" onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
          Previous
        </Button>
        <Button variant="outline" size="sm" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
          Next
        </Button>
      </div>
    </div>
  );
}

/**<UserDialog
user={selectedUser} // Pass null when adding a new user
onSave={async (newUser) => {
  try {
    // Add your save function here
    await refreshUsers(); // Refresh the table after adding a user
    toast({
      variant: "celebratory",
      title: "User created!",
      description: "New user successfully added.",
    });
  } catch (error) {
    console.error("Error adding user:", error);
  }
}}
trigger={
  <Button
    variant="outline"
    size="sm"
    onClick={() => {
      setSelectedUser(null); // Indicate we're adding a new user
      setIsDialogOpen(true);
    }}
  >
    Add User
  </Button>
}
/>**/