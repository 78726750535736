import React, { useState, useImperativeHandle, forwardRef, useEffect } from "react"
import { CaretSortIcon, CheckIcon } from "@radix-ui/react-icons"
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from "../ui/command"
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "../ui/popover"
import { Button } from "../ui/button"
import { cn } from "../../lib/utils"
import { Building } from "../../lib/types"

interface BuildingSelectorProps {
    options: Building[];
    value: string;  // Accept the current selected building ID as a prop
    onBuildingChange: (buildingId: string) => void;
}

export interface BuildingSelectorRef {
    setValue: (buildingId: string) => void;
}

const BuildingSelector = forwardRef<BuildingSelectorRef, BuildingSelectorProps>(({ options, value, onBuildingChange }, ref) => {
    const [open, setOpen] = React.useState(false);

    useImperativeHandle(ref, () => ({
        setValue: (buildingId: string) => {
            onBuildingChange(buildingId); // Update the parent when the value changes
        }
    }));

    const handleBuildingChange = (buildingId: string) => {
        setOpen(false);
        onBuildingChange(buildingId);  // Call parent handler for building change
    }

    return (
        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
                <Button
                    variant="outline"
                    role="combobox"
                    aria-expanded={open}
                    className="w-[160px] justify-between"
                >
                    {options.length > 0
                    ? options.find((option) => option.id === value)?.shortname || "None"
                    : "Loading..."}
                    <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-[160px] p-0 bg-white">
                <Command>
                    <CommandInput placeholder="Search option..." className="h-9" />
                    <CommandEmpty>No option found.</CommandEmpty>
                    <CommandGroup>
                        <CommandList>
                            {options.map((option) => (
                                <CommandItem
                                    key={option.id}
                                    value={option.fullname}
                                    onSelect={() => handleBuildingChange(option.id)}
                                >
                                    {option.shortname}
                                    <CheckIcon
                                        className={cn(
                                            "ml-auto h-4 w-4",
                                            value === option.id ? "opacity-100" : "opacity-0"
                                        )}
                                    />
                                </CommandItem>
                            ))}
                        </CommandList>
                    </CommandGroup>
                </Command>
            </PopoverContent>
        </Popover>
    )
})

export default BuildingSelector;
