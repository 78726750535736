/**TODO:
 * color bug.
 * undo is iffy.
 * typing deletes for daily note.
 * reload warning not for navigate away from page.
 * make confirmation a calendar, not a list (not intuitive).
 * auto-save.
 * too much space between content and footer
**/
import { useEffect, useState, useRef } from 'react';
import { Button } from "../../components/ui/button"
import MenuBar from '../../components/menu-bar';
import BuildingSelector, {BuildingSelectorRef} from '../../components/hours/building-selector';
import BuildingInformation from '../../components/hours/building-information';
import BuildingProfiles from '../../components/hours/building-profiles';
import { Banner, Building, CalendarEvent, DayNote, HoursPeriod, HoursProfile } from '../../lib/types';
import useUndoable from "use-undoable"
import { ResetIcon } from "@radix-ui/react-icons"
import {
    collection,
    getDocs,
    setDoc,
    deleteDoc,
    getDoc,
    doc
  } from "firebase/firestore";
import { Link, useLocation } from 'react-router-dom';
import { db } from '../../config/firebase';
import { useAuth } from "../../context/AuthContext";
import { useToast } from "../../components/ui/use-toast";
import ProfilesApplier from '../../components/hours/profiles-applier';
import CalendarFeature from '../../components/hours/calendar-feature';
import dayjs from 'dayjs';
import Footer from '../../components/footer-main';
import { LoadingButton } from '../../components/ui/loading-button';
import { set } from 'date-fns';
import SkeletonComponent from '../../components/hours/skeleton';
import { fetchBuildings } from '../../lib/fetchbuildings';
import EditResourceLinksDialog from '../../components/general/edit-resources';
import { Description } from '@radix-ui/react-toast';
import { ShareOptionsDialog } from '../../components/general/share-dialog';

export default function HoursPage() {

    const [buildingOptions, setBuildingOptions, { undo, redo, resetInitialState }] = useUndoable<Building[]>([]);
    const [currentBuildingId, setCurrentBuildingId] = useState<string>("-1");
    const buildingSelectorRef = useRef<BuildingSelectorRef>(null);
    const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
    const { toast } = useToast();
    const { user } = useAuth();
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const school = user?.customClaims?.school;

    const [resourceDialogOpen, setResourceDialogOpen] = useState(false);


    const getCurrentDate = () => {
        const now = dayjs();
        const day = now.date();
        let suffix;
    
        if (day % 10 === 1 && day !== 11) {
            suffix = 'st';
        } else if (day % 10 === 2 && day !== 12) {
            suffix = 'nd';
        } else if (day % 10 === 3 && day !== 13) {
            suffix = 'rd';
        } else {
            suffix = 'th';
        }
    
        return now.format(`MMMM D[${suffix}].`);
    };
    
    // Inside your component or wherever you want to fetch buildings

   // Define the function outside useEffect
   const loadBuildings = async () => {
        console.log(school);
        const buildingsList = await fetchBuildings(school); // Use utility to fetch buildings

        if (buildingsList.length !== 0) {
            setBuildingOptions(buildingsList);

            // Try to get the buildingId from the query parameter
            const searchParams = new URLSearchParams(location.search);
            const buildingIdFromQuery = searchParams.get('buildingId');

            if (buildingIdFromQuery) {
                setCurrentBuildingId(buildingIdFromQuery);
                if (buildingSelectorRef.current) {
                    buildingSelectorRef.current.setValue(buildingIdFromQuery);
                }
            } else {
                setCurrentBuildingId(buildingsList[0].id);
                if (buildingSelectorRef.current) {
                    buildingSelectorRef.current.setValue(buildingsList[0].id);
                }
            }

            resetInitialState(buildingsList);
            setLoading(false);
        } else {
            console.error("No buildings found.");
        }
    };

    useEffect(() => {
        console.log(school);
        if (user) {
            console.log("user found");
            loadBuildings(); // Call the function inside useEffect

            const beforeUnloadHandler = (ev: { preventDefault: () => void; returnValue: string; }) => {
                ev.preventDefault();
                ev.returnValue = 'Are you sure you want to close?';
            };

            window.addEventListener("beforeunload", beforeUnloadHandler);

            return () => {
                window.removeEventListener("beforeunload", beforeUnloadHandler);
            };
        }
    }, [user, location.search]);

    const defaultBuilding: Building = {
        id: '-1',
        fullname: 'Facility Not Found.',
        shortname: 'Hogwarts',
        description: 'Select a valid facility from the dropdown to the left.',
        address: '',
        profiles: [],
        phone: '',
        website: '',
        contact: '',
        events: [],
        notes: [],
        happenings: [],
        banner: {
            enabled: false,
            variant: '',
            message: ''
        }
    };
    const [shareDialogOpen, setShareDialogOpen] = useState(false);

    const handleUpdate = (updatedOptions: Building) => {
        // Handle the updated options, for example, update the state
        //('Options updated:', updatedOptions);
        setBuildingOptions((prevOptions) => {
            return prevOptions.map((option) => {
                if (option.id === updatedOptions.id) {
                    return updatedOptions;
                }
                return option;
            });
        });
        setUnsavedChanges(true);
        // You can also perform other actions based on the update
    };

    const handleBuildingChange = (selectedBuildingId: string) => {
        setCurrentBuildingId(selectedBuildingId);
     };

     const handleSave = async () => {
        setButtonLoading(true);
        try {
            const buildingsCollection = collection(db, "schools", school, "facilities");
            
            for (const building of buildingOptions) {
                const buildingDocRef = doc(buildingsCollection, building.id);
    
                // Fetch existing profiles, events, and notes
                const existingProfilesSnapshot = await getDocs(collection(buildingDocRef, "profiles"));
                const existingEventsSnapshot = await getDocs(collection(buildingDocRef, "events"));
                const existingNotesSnapshot = await getDocs(collection(buildingDocRef, "notes"));
                const existingResourcesSnapshot = await getDocs(collection(buildingDocRef, "resources"));
                const existingHappeningsSnapshot = await getDocs(collection(buildingDocRef, "happenings"));

                const existingProfileIds = existingProfilesSnapshot.docs.map(doc => doc.id);
                const existingEventIds = existingEventsSnapshot.docs.map(doc => doc.id);
                const existingNoteIds = existingNotesSnapshot.docs.map(doc => doc.id);
                const existingResourceIds = existingResourcesSnapshot.docs.map(doc => doc.id);
                const existingHappeningsIds = existingHappeningsSnapshot.docs.map(doc => doc.id);

                // Get the new and updated profiles, events, and notes
                const newProfileIds = building.profiles.map(profile => profile.id);
                const newEventIds = building.events.map(event => event.id);
                const newNoteIds = building.notes.map(note => note.id);
                console.log(building.resources);
                const newResourceIds = building.resources.map((_, index) => `resource-${index}`); // Assuming we generate resource ids as "resource-{index}"
                const newHappeningIds = building.happenings.map(happening => happening.id);

                // Determine the IDs to delete
                const profilesToDelete = existingProfileIds.filter(id => !newProfileIds.includes(id));
                const eventsToDelete = existingEventIds.filter(id => !newEventIds.includes(id));
                const notesToDelete = existingNoteIds.filter(id => !newNoteIds.includes(id));
                const resourcesToDelete = existingResourceIds.filter(id => !newResourceIds.includes(id));
                const happeningsToDelete = existingHappeningsIds.filter(id => !newHappeningIds.includes(id));

                // Delete the documents
                for (const profileId of profilesToDelete) {
                    await deleteDoc(doc(collection(buildingDocRef, "profiles"), profileId));
                }
    
                for (const eventId of eventsToDelete) {
                    await deleteDoc(doc(collection(buildingDocRef, "events"), eventId));
                }
    
                for (const noteId of notesToDelete) {
                    await deleteDoc(doc(collection(buildingDocRef, "notes"), noteId));
                }

                for (const resourceId of resourcesToDelete) {
                    await deleteDoc(doc(collection(buildingDocRef, "resources"), resourceId));
                }

                for (const happeningId of happeningsToDelete) {
                    await deleteDoc(doc(collection(buildingDocRef, "happenings"), happeningId));
                }
    
                // Save the building data
                const buildingData = {
                    fullname: building.fullname || "",
                    shortname: building.shortname || "",
                    description: building.description || "",
                    address: building.address || "",
                    contact: building.contact || "",
                    phone: building.phone || "",
                    parentId: building.parentId || "",
                    category: building.category || "",
                    website: building.website || "",
                    banner: building.banner || { enabled: false, variant: "info", message: "" }  // Adding banner field
                };
    
                await setDoc(buildingDocRef, buildingData);
    
                // Save profiles
                for (const profile of building.profiles) {
                    const profileData = {
                        name: profile.name || "",
                        description: profile.description || "",
                        color: profile.color || "",
                    };
    
                    //console.log("setting profiles");    
                    const profileDocRef = doc(collection(buildingDocRef, "profiles"), profile.id);
                    await setDoc(profileDocRef, profileData);
    
                    // Save periods
                    if (Array.isArray(profile.periods)) {
                        const periodsCollectionRef = collection(profileDocRef, "periods");
                        const existingPeriodsSnapshot = await getDocs(periodsCollectionRef);
                        const existingPeriodIds = existingPeriodsSnapshot.docs.map(doc => doc.id);
                        const newPeriodIds = profile.periods.map(period => period.id);
                        const periodsToDelete = existingPeriodIds.filter(id => !newPeriodIds.includes(id));
    
                        for (const periodId of periodsToDelete) {
                            await deleteDoc(doc(periodsCollectionRef, periodId));
                        }
    
                        for (const period of profile.periods) {
                            const periodData = {
                                name: period.name || "",
                                description: period.description || "",
                                starttime: dayjs(period.starttime).toISOString(),
                                endtime: dayjs(period.endtime).toISOString(),
                            };
    
                            //console.log("setting periods");    
                            const periodDocRef = doc(periodsCollectionRef, period.id);
                            await setDoc(periodDocRef, periodData);
                        }
                    }
                }
    
                // Save events
                //console.log("setting events" + building.events);    
                for (const event of building.events) {
                    const eventData = {
                        id: event.id,
                        profileId: event.profileId,
                        title: event.title || "",
                        description: event.description || "",
                        startDate: dayjs(event.startDate).toISOString(),
                        endDate: event.endDate ? dayjs(event.endDate).toISOString() : null,
                        doesRepeat: event.doesRepeat,
                        recurrence: event.recurrence
                            ? {
                                  frequency: event.recurrence.frequency,
                                  interval: event.recurrence.interval,
                                  daysOfWeek: event.recurrence.daysOfWeek || [],
                                  datesOfMonth: event.recurrence.datesOfMonth || [],
                                  computedOccurences: event.recurrence.computedOccurences
                                      ? event.recurrence.computedOccurences.map((occurence) => ({
                                            date: dayjs(occurence.date).toISOString(),
                                        }))
                                      : [],
                                  actualOccurences: event.recurrence.actualOccurences
                                      ? event.recurrence.actualOccurences.map((occurence) => ({
                                            date: dayjs(occurence.date).toISOString(),
                                        }))
                                      : [],
                              }
                            : null,
                    };
    
                    const eventDocRef = doc(collection(buildingDocRef, "events"), event.id);
                    await setDoc(eventDocRef, eventData);
                }
    
                // Save notes
                for (const note of building.notes) {
                    const noteDocRef = doc(collection(buildingDocRef, "notes"), note.id);
                
                    if (note.note.trim() !== "") {
                        const noteData = {
                            date: dayjs(note.date).toISOString(),
                            note: note.note,
                        };
                        await setDoc(noteDocRef, noteData);
                    } else {
                        // Check if the note already exists in Firebase and delete it if it does
                        const noteSnapshot = await getDoc(noteDocRef);
                        if (noteSnapshot.exists()) {
                            await deleteDoc(noteDocRef);
                        }
                    }
                }

                building.resources.forEach(async (resource, index) => {
                    const resourceData = {
                        title: resource.title || "",
                        url: resource.url || "",
                    };
                
                    const resourceDocRef = doc(collection(buildingDocRef, "resources"), `resource-${index}`);
                    await setDoc(resourceDocRef, resourceData); // 'await' can now be used since the function is 'async'
                });

                console.log(building.fullname);
                console.log(building.happenings);
                for (const happening of building.happenings) {
                    const happeningDocRef = doc(collection(buildingDocRef, "happenings"), happening.id);
                    const happeningData = {
                        id: happening.id,
                        name: happening.name,
                        variant: happening.variant,
                        description: happening.description,
                        allday: happening.allday,
                        starttime: dayjs(happening.starttime).toISOString(),
                        endtime: dayjs(happening.endtime).toISOString(),
                        resource: happening.resource
                    }
                    await setDoc(happeningDocRef, happeningData);
                }
                
                
            }
            setButtonLoading(false);    
            //console.log("SAVED BUILDINGS");
            //console.log(buildingOptions);
            toast({
                variant: "celebratory",
                title: "Changes saved!",
                description: "Your changes have been saved successfully.",
            });
            setUnsavedChanges(false); // Reset unsaved changes flag after saving
        } catch (error) {
            console.error("Error saving buildings: ", error);
            setButtonLoading(false);    
        }
    };    
    
    return (
        <div className="flex flex-col h-screen">
            <MenuBar />
            <div className="flex flex-grow mb-20">
            
                {/* Sidebar */}
                <div className="space-y-4 pl-[4rem] pt-10 flex flex-col justify-between h-full w-1/6">
                    <div>
                        <h2 className="text-3xl font-bold tracking-tight">Manage</h2>
                        <p className="text-gray-500 text-sm mb-5 font-semibold">{getCurrentDate()}</p>
                        <BuildingSelector ref={buildingSelectorRef} value={currentBuildingId} options={buildingOptions} onBuildingChange={handleBuildingChange} />
    
                        <nav className="grid gap-3 text-sm pt-5 text-muted-foreground">
                            <Link to="#" className='font-semibold text-black'>Edit Facility Hours</Link>
                            <Link to={`/${school}/admin/users`}>Edit Managers</Link>
                            <Link to="#" onClick={() => setResourceDialogOpen(true)}>Edit Resource Links</Link>
                            <Link to="#" onClick={() => setShareDialogOpen(true)}>Share Options</Link>
                         </nav>
                    </div>
    
                    <div>
                        <nav className="grid gap-4 text-sm pb-[4rem] text-muted-foreground">
                            <Link to="#">Documentation</Link>
                            <Link to="#">Get Support</Link>
                        </nav>
                    </div>
                </div>
    
                {/* Main Content */}
                <div className="space-y-4 pl-[2rem] pt-10 w-full h-90vh">
                    <div className="flex items-center justify-between space-y-2 space-x-2">
                        <div className="flex items-center space-x-5">
                            <h2 className="text-4xl font-bold tracking-tight">Facility Hours</h2>
                        </div>
                        <div className="flex items-center space-x-2 pr-8">
                            {unsavedChanges && <span className="text-gray-500 text-xs pulsate">you have unsaved changes</span>}
                            <Button variant="ghost" onClick={undo}><ResetIcon className="h-4 w-4" /></Button>
                            <Button variant="ghost" onClick={redo}><ResetIcon className="-scale-x-100 h-4 w-4" /></Button>
                            <LoadingButton variant="default" loading={buttonLoading} onClick={handleSave}>Save</LoadingButton>
                        </div>
                    </div>

                    <EditResourceLinksDialog
                        building={buildingOptions.find(building => building.id === currentBuildingId) || defaultBuilding}
                        onUpdate={handleUpdate}
                        isOpen={resourceDialogOpen}
                        setIsOpen={setResourceDialogOpen} // Pass state setter to control dialog
                    />
                    <ShareOptionsDialog 
                    open={shareDialogOpen} 
                    school={school} 
                    facility={(buildingOptions.find(building => building.id === currentBuildingId)) || defaultBuilding} 
                    id={currentBuildingId} 
                    onOpenChange={setShareDialogOpen} 
                    />

                    <div className="grid h-full flex grid-cols-11 gap-4">
                        <div className="col-span-4 h-full space-y-4 flex flex-col">
                            {loading ? (
                                <SkeletonComponent />
                            ) : (
                                <BuildingInformation 
                                    facilityOptions={buildingOptions} 
                                    options={buildingOptions.find(building => building.id === currentBuildingId) || defaultBuilding} 
                                    onUpdate={handleUpdate} 
                                />
                            )}                            
                            <BuildingProfiles 
                                options={buildingOptions.find(building => building.id === currentBuildingId) || defaultBuilding} 
                                onUpdate={handleUpdate} 
                                onUndo={undo} 
                            />
                        </div>
                        <div className="col-span-7 space-y-4 flex flex-col pr-8">
                            <ProfilesApplier 
                                options={buildingOptions.find(building => building.id === currentBuildingId) || defaultBuilding} 
                                onUpdate={handleUpdate} 
                            />
                            <CalendarFeature 
                                options={buildingOptions.find(building => building.id === currentBuildingId) || defaultBuilding} 
                                onUpdate={handleUpdate} 
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}