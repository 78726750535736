import { Auth } from '../../components/auth';
import '../../App.css';
import { CardHeader, CardTitle, CardContent, CardFooter, CardDescription } from '../../components/ui/card';
import { Label } from '@radix-ui/react-dropdown-menu';
import { Card, Input, Button } from 'antd';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router';

import React, { useState } from 'react';
import { auth, functions, httpsCallable } from '../../config/firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { signOut } from 'firebase/auth';
import MenuBar from '../../components/menu-bar';

function OnboardPage() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [school, setSchool] = useState('');
    const [schoolNormal, setSchoolNormal] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);
    const { signup } = useAuth();
    const navigate = useNavigate();

    const handleSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        setError('');
        setLoading(true);
    
        // Basic frontend validation
        if (!email || !password || !school || !schoolNormal) {
          setError('Please fill in all fields.');
          setLoading(false);
          return;
        }
    
        try {
          // Call the signup function from AuthContext
          console.log(email + password + school);
          const schoolnormalized = schoolNormal;
          await signup(email, password, school, schoolnormalized);
    
          // Optionally, navigate to the login page
          navigate('/login');
          //alert('Registration successful! Please log in with your new credentials.');
        } catch (err) {
          console.error("Error during signup:", err);
          setError('Failed to create an account.');
        }
    
        setLoading(false);
      };
    
    return (
        <div className="App">
            <div className="relative">
            <div className="flex flex-col h-screen">
                    {/* MenuBar occupies its natural height */}
                    <MenuBar />

                    {/* The container for the Card takes the remaining space and centers its content */}
                <div className="flex flex-1 items-center justify-center">
                    <Card className="w-5/12 bg-white shadow-sm my-7">
                        <CardHeader className="">
                        <CardTitle className="truenoTitle text-4xl leading-tight">
                            Add your school
                        </CardTitle>
                        <CardDescription className="leading-tight">
                            Get your school on CampusCommons in under 60 seconds.
                        </CardDescription>
                        </CardHeader>
                        <CardContent>
                        {!!error && (
                            <div className="pb-2">
                            <div
                                className="p-1 bg-gray-800 items-center text-gray-100 lg:rounded leading-none w-full flex lg:inline-flex"
                                role="alert"
                            >
                                <span className="flex rounded bg-gray-600 uppercase px-2 py-1 text-xs font-bold mr-3">
                                Alert
                                </span>
                                <span className="font-semibold mr-2 text-xs text-left flex-auto">
                                {error}
                                </span>
                            </div>
                            </div>
                        )}
                        <div className="space-y-1">
                            <h2 className='font-bold'>Step One: All About You</h2>
                            <div className="pb-4 leading-tight text-gray-500 text-xs">First, let's get some information about you. You'll be the first administrator
                                of your school. You'll be able to add other managers and admins after.
                            </div>
                            <div className="space-y-1">
                            <Label>Email</Label>
                            <Input
                                className="bg-white"
                                id="email"
                                name="email"
                                autoComplete="email"
                                placeholder="jphillips@exeter.edu"
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                type="email"
                            />
                            </div>
                            <div className="space-y-1 pt-2">
                            <Label>Password</Label>
                            <Input
                                className="bg-white"
                                id="password"
                                name="password"
                                placeholder="••••••••"
                                required
                                autoComplete="current-password"
                                onChange={(e) => setPassword(e.target.value)}
                                type="password"
                            />
                            </div>
                            <h2 className='font-bold pt-6'>Step Two: Your School</h2>
                            <div className="pb-4 leading-tight text-gray-500 text-xs">Now, let's get the name of your school. We also need a short, "normalized" version of it to use as an identifier in our databases. For example, "phillipsexeter" or "utaustin" — no spaces and no non-alphabetical characters.</div>
                            <Label>Full School Name</Label>
                            <Input
                                className="bg-white"
                                id="school"
                                name="school"
                                placeholder="Phillips Exeter Academy"
                                required
                                onChange={(e) => setSchool(e.target.value)}
                            />
                            <Label className='pt-2'>Normalized School Identifier</Label>
                            <Input
                                className="bg-white"
                                id="schoolnormal"
                                name="schoolnormal"
                                placeholder="phillipsexeter"
                                required
                                onChange={(e) => setSchoolNormal(e.target.value)}
                            />
                        </div>
                        <h2 className='font-bold pt-6'>Step Three: Request Verification</h2>
                            <div className="leading-tight text-gray-500 text-xs">Press the register button and a customer representative will email you within 24 hours to verify your request and complete your onboarding!</div>
                            <Button
                            className="w-full mt-6 bg-black text-white py-5"
                            onClick={handleSubmit}
                        >
                        {loading ? 'Registering...' : 'Register Now'}
                        </Button>
                        </CardContent>
                        
                    </Card>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OnboardPage;