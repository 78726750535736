import { addDoc, collection, doc, setDoc } from "firebase/firestore";
import { db } from "../config/firebase";  // Import your Firebase setup
import { Building } from "./types";

export async function addBuildingToFirebase(building: Building, schoolId: string) {
  const buildingsCollection = collection(db, "schools", schoolId, "facilities");
  await addDoc(buildingsCollection, {
    fullname: building.fullname,
    shortname: building.shortname,
    description: building.description,
    address: building.address,
    phone: building.phone,
    website: building.website,
    contact: building.contact,
    category: building.category,
    banner: building.banner,
    profiles: [],
    events: [],
    notes: [],
    happenings: [],
    resources: [],
  });
}

export async function updateBuildingInFirebase(updatedBuilding: Building, schoolId: string) {
  try {
    const buildingsCollection = doc(db, "schools", schoolId, "facilities", updatedBuilding.id);
    
    // Prepare the building data for update (main fields only, no subcollections)
    const buildingData = {
      fullname: updatedBuilding.fullname || "",
      shortname: updatedBuilding.shortname || "",
      description: updatedBuilding.description || "",
      address: updatedBuilding.address || "",
      contact: updatedBuilding.contact || "",
      phone: updatedBuilding.phone || "",
      parentId: updatedBuilding.parentId || "",
      category: updatedBuilding.category || "",
      website: updatedBuilding.website || "",
      banner: updatedBuilding.banner || { enabled: false, variant: "info", message: "" },  // Banner field if applicable
    };

    // Update the building document in Firestore
    await setDoc(buildingsCollection, buildingData, { merge: true });

    console.log("Building updated successfully:", updatedBuilding.id);
  } catch (error) {
    console.error("Error updating building:", error);
    throw new Error("Failed to update building");
  }
}