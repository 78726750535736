import logo from "./logo.svg";
import "./App.css";
import { Auth } from "./components/auth";
import { Toaster } from "./components/ui/toaster";
import { Outlet } from "react-router";
import { AuthProvider } from "./context/AuthContext";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "./components/ui/card";
import { Button } from "./components/ui/button";
import InfiniteCarousel from "react-leaf-carousel";
import { AppStoreButton } from "react-mobile-app-button";
import { ScrollArea } from "@radix-ui/react-scroll-area";
import Alert from "react-bootstrap/Alert";

function App() {
  return (
    <AuthProvider>
      <Outlet />
      <ScrollArea>
        <div className="App py-10 flex items-center mx-4 justify-center">
          <Card className="w-full lg:w-1/2 md:w-1/2 bg-white mx-4">
            <CardHeader className="">
              <p className="truenoTitle text-4xl leading-tight mb-2">
                CampusCommons
              </p>
              <div
                class="bg-orange-100 border-l-4 border-orange-400 text-orange-700 p-4 text-sm"
                role="alert"
                style={{ borderColor: "orange", marginBottom: 10 }}
              >
                <p>
                  <b>Limited long-term support.</b> Slated to be sunset
                  end-of-year 2024.
                </p>
              </div>
              <InfiniteCarousel
                breakpoints={[
                  {
                    breakpoint: 500,
                    settings: {
                      slidesToShow: 2,
                      slidesToScroll: 1,
                    },
                  },
                  {
                    breakpoint: 768,
                    settings: {
                      slidesToShow: 3,
                      slidesToScroll: 3,
                    },
                  },
                ]}
                dots={false}
                showSides={false}
                sidesOpacity={0.3}
                sideSize={0.1}
                slidesToScroll={1}
                slidesToShow={3}
                scrollOnDevice={true}
                autoCycle={false} // Disable automatic cycling
                swipe={true} // Enable swipe functionality
                arrows={true} // Enable arrows for navigation
                animationDuration={500} // Optional: Set animation duration
              >
                <div>
                  <img
                    alt="Image 1"
                    src="/1.webp"
                    className="rounded-lg" // Add this class for border radius
                    style={{ width: "100%", height: "auto" }}
                  />
                </div>
                <div>
                  <img
                    alt="Image 2"
                    src="/2.webp"
                    className="rounded-lg" // Add this class for border radius
                    style={{ width: "100%", height: "auto" }}
                  />
                </div>
                <div>
                  <img
                    alt="Image 3"
                    src="/3.webp"
                    className="rounded-lg" // Add this class for border radius
                    style={{ width: "100%", height: "auto" }}
                  />
                </div>
                <div>
                  <img
                    alt="Image 4"
                    src="/4.webp"
                    className="rounded-lg" // Add this class for border radius
                    style={{ width: "100%", height: "auto" }}
                  />
                </div>
                <div>
                  <img
                    alt="Image 5"
                    src="/5.webp"
                    className="rounded-lg" // Add this class for border radius
                    style={{ width: "100%", height: "auto" }}
                  />
                </div>
                <div>
                  <img
                    alt="Image 6"
                    src="/6.webp"
                    className="rounded-lg" // Add this class for border radius
                    style={{ width: "100%", height: "auto" }}
                  />
                </div>
              </InfiniteCarousel>
              <p className="leading-snug" style={{ marginTop: 20 }}>
                Used by <b>over 80% of Exonians</b>, CampusCommons is the
                simplest and fastest way for Phillips Exeter students to access
                their class schedule, view live dining hall menus, check
                facility hours, and do so much more — all right from the palm of
                their hands.
              </p>
              <div style={{ marginTop: 20 }}>
                <AppStoreButton
                  url={
                    "https://apps.apple.com/us/app/campuscommons/id1584594419?platform=iphone"
                  }
                  theme={"dark"}
                />
              </div>
              <p style={{ color: "gray", fontSize: 11, marginTop: 20 }}>
                Written in 2021 by{" "}
                <a
                  href="https://www.linkedin.com/in/kevintreehan/"
                  style={{ color: "blue" }}
                >
                  Kevin Treehan
                </a>
                . Limited LTS as of 2024.
              </p>
            </CardHeader>
          </Card>
        </div>
      </ScrollArea>
      <Toaster />
    </AuthProvider>
  );
}

export default App;
