// src/context/AuthContext.js

import React, { useContext, useState, useEffect } from "react";
import { auth, functions, httpsCallable } from "../config/firebase";
import { signInWithEmailAndPassword, getIdTokenResult } from "firebase/auth";

const AuthContext = React.createContext();

/**
 * Custom hook to access the AuthContext
 */
export function useAuth() {
  return useContext(AuthContext);
}

/**
 * AuthProvider component to wrap around your app
 */
export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  /**
   * Signup function that calls the Cloud Function to register a school admin
   * @param {string} email - User's email
   * @param {string} password - User's password
   * @param {string} school - School name
   * @returns {object} - Response from the Cloud Function
   */
  async function signup(email, password, school, schoolnormalized) {
    const registerSchoolAdmin = httpsCallable(functions, "registerSchoolAdmin");
    try {
      console.log(email + password + school + schoolnormalized);
      const result = await registerSchoolAdmin({
        email,
        password,
        school,
        schoolnormalized,
      });
      return result.data;
    } catch (error) {
      // Handle errors from the Cloud Function
      throw error;
    }
  }

  /**
   * Login function using Firebase Authentication
   * @param {string} email - User's email
   * @param {string} password - User's password
   * @returns {object} - User credential
   */
  async function login(email, password) {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const firebaseUser = userCredential.user;

      // Retrieve custom claims
      const tokenResult = await getIdTokenResult(firebaseUser, true);
      firebaseUser.customClaims = tokenResult.claims;

      setUser(firebaseUser);
      return firebaseUser;
    } catch (error) {
      // Handle authentication errors
      throw error;
    }
  }

  /**
   * Logout function using Firebase Authentication
   */
  function logout() {
    return auth.signOut().then(() => setUser(null));
  }

  /**
   * Reset password using Firebase Authentication
   * @param {string} email - User's email
   */
  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email);
  }

  /**
   * Update user's email
   * @param {string} email - New email
   */
  async function updateEmail(email) {
    if (user) {
      await user.updateEmail(email);
      // Optionally, refresh custom claims if email impacts roles
    }
  }

  /**
   * Update user's password
   * @param {string} password - New password
   */
  async function updatePassword(password) {
    if (user) {
      await user.updatePassword(password);
    }
  }

  /**
   * Effect to handle authentication state changes
   */
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (firebaseUser) => {
      if (firebaseUser) {
        try {
          // Force refresh to get the latest custom claims
          const tokenResult = await getIdTokenResult(firebaseUser, true);
          firebaseUser.customClaims = tokenResult.claims;
          setUser(firebaseUser);
        } catch (error) {
          console.error("Error fetching custom claims:", error);
          setUser(null);
        }
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  /**
   * Context value to be provided to consuming components
   */
  const value = {
    user,
    loading,
    signup,
    login,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
