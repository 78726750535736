// src/components/users/columns.tsx

import { ColumnDef } from "@tanstack/react-table";
import { Button } from "../../../components/ui/button";
import { Checkbox } from "../../../components/ui/checkbox";
import { User } from "@/lib/types"; // Ensure User type is correctly defined
import UserActions from "../../../components/general/user-actions";
import { ChevronsUpDownIcon } from "lucide-react";

// Export columns as a static array
export const getColumns = (handleOpenDialog: (user: User) => void) => {
  return (refreshUsers: () => void | Promise<void>): ColumnDef<User>[] => [
    {
      id: "select",
      header: ({ table }) => (
        <Checkbox
          className="ml-1"
          checked={
            table.getIsAllPageRowsSelected() ||
            (table.getIsSomePageRowsSelected() && "indeterminate")
          }
          onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
          aria-label="Select all"
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          className="ml-1"
          checked={row.getIsSelected()}
          onCheckedChange={(value) => row.toggleSelected(!!value)}
          aria-label="Select row"
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "email",
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="pl-0"
        >
          Email
          <ChevronsUpDownIcon className="ml-2 h-4 w-4" />
        </Button>
      ),
    },
    {
      accessorKey: "role",
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="pl-0"
        >
          Role
          <ChevronsUpDownIcon className="ml-2 h-4 w-4" />
        </Button>
      ),
      cell: ({ row }) => {
        const role = row.original.role;

        // Define colors for each role
        const roleColors: { [key: string]: string } = {
          admin: "bg-red-100 text-red-800",
          manager: "bg-green-100 text-green-800",
          user: "bg-blue-100 text-blue-800",
        };

        // Map role to a user-friendly label
        const roleLabels: { [key: string]: string } = {
          admin: "Administrator",
          manager: "Manager",
          user: "User",
        };

        // Type guard to check if the role is a valid key
        const isValidRole = (role: string): role is keyof typeof roleColors => {
          return role in roleColors;
        };

        // Get the appropriate color and label, or default
        const colorClass = isValidRole(role) ? roleColors[role] : "bg-gray-100 text-gray-800"; // Default color if no match
        const roleLabel = isValidRole(role) ? roleLabels[role] : "Unknown"; // Default to 'Unknown' if role is not found

        return (
          <span className={`px-2 py-1 rounded-full text-xs font-bold ${colorClass}`}>
            {roleLabel}
          </span>
        );
      },
    },
    {
      id: "actions",
      header: "Actions",
      cell: ({ row }) => {
        const user = row.original; // Access user data here
        return (
          <UserActions
            user={user}
            refreshUsers={refreshUsers}
            handleOpenDialog={handleOpenDialog}
          />
        );
      },
    },
  ];
};