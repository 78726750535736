import {
    Card,
    CardDescription,
    CardHeader,
    CardTitle,
  } from "../ui/card"
import React, { useState, useEffect } from "react"
import dayjs, { Dayjs } from "dayjs";
import { Building, HoursProfile, CalendarEvent, Occurence, Conflict, Announcement } from "../../lib/types"
import { Calendar } from "../ui/calendar"
import { format, addDays, setDay, set } from "date-fns"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogClose,
    DialogTrigger,
  } from "../ui/dialog"
  import { Label } from "../ui/label"
import { Button } from "../ui/button"
import { DateRange } from "react-day-picker"
import { useToast } from "../ui/use-toast"
import { v4 as uuidv4 } from "uuid"
import { CircleHelp, PlusCircle, Calendar as CalendarIcon, MessageSquarePlusIcon, GoalIcon, Goal} from "lucide-react";
import { CaretSortIcon, CheckCircledIcon, CheckIcon, ExclamationTriangleIcon } from "@radix-ui/react-icons"
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from "../ui/command"
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "../ui/popover"
import { cn } from "../../lib/utils"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tabs"
import { InputNumber } from 'rsuite';
import 'rsuite/InputNumber/styles/index.css';
import { ToggleGroup, ToggleGroupItem } from "../ui/toggle-group"
import { detectConflicts, optionThree, optionTwo } from "../../lib/conflict"
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
  } from "../ui/table"  
  import { Alert, AlertDescription, AlertTitle } from "../ui/alert"
import { RadioGroup, RadioGroupItem } from "../ui/radio-group"
import { ScrollArea } from "../ui/scroll-area"
import { Switch } from "../ui/switch";
import { Textarea } from "../ui/textarea";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
  } from "../ui/select"  
import { Separator } from "../ui/separator";
import HappeningDialog from "../general/happening-dialog";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
  } from "../ui/dropdown-menu"
import { DisplayHappening } from "./calendar-feature";
import AllEventsDialog from "./event-dialog";
import SendAnnouncementDialog from "../general/send-announcement";
import { db } from "../../config/firebase";
import { collection, addDoc } from "firebase/firestore";
import { useAuth } from "../../context/AuthContext";
import AllAnnouncementsDialog from "../general/all-announcements";
  
interface ProfilesApplierProps {
    options: Building;
    onUpdate: (updatedOptions: Building) => void;
}

export default function ProfilesApplier({ options, onUpdate }: ProfilesApplierProps) {

    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [conflictOpen, setConflictOpen] = React.useState(false);
    const [conflicts, setConflicts] = React.useState<Conflict[]>([]);
    const [occurences, setOccurences] = React.useState<Occurence[]>([]);
    const [pendingEvent, setPendingEvent] = React.useState<CalendarEvent>();
    const [pendingEventBlurb, setPendingEventBlurb] = React.useState("");
    const [value, setValue] = React.useState("");
    const [isApplyDisabled, setIsApplyDisabled] = useState(true);
    const { toast } = useToast();
    const [tab, setTab] = useState("onetime");
    //for not repeat
    const [date, setDate] = React.useState<Date>();
    //for repeat
    const [dateRepeat, setDateRepeat] = React.useState<DateRange | undefined>({
        from: new Date(),
        to: addDays(new Date(), 5),
    })
    const frequencies = [ {value: "DAILY", label: "days"}, {value: "WEEKLY", label: "weeks"}]; //REMOVED, {value: "MONTHLY", label: "months"}
    const [value2, setValue2] = React.useState("DAILY");
    const [freqNum, setFreqNum] = React.useState(1);
    //for weekly option 
    const [dayPickerValue, setValueDayPicker] = React.useState<string[]>([]);
    const [pane, setPane] = React.useState(false); //false for options, true for confirmation
    const [conflictOptions, setConflictOptions] = React.useState("option-two");

    const [bannerVariant, setBannerVariant] = React.useState("");
    const [bannerMessage, setBannerMessage] = React.useState("");
    const [bannerEnabled, setBannerEnabled] = useState(false);

    const { user } = useAuth();
    const school = user?.customClaims?.school;

    // New state for controlling the HappeningDialog
    const [isHappeningDialogOpen, setIsHappeningDialogOpen] = useState(false);

    // Handler to open the dialog
    const handleOpenHappeningDialog = () => {
        setIsHappeningDialogOpen(true);
    };

    // Handler to close the dialog
    const handleCloseHappeningDialog = () => {
        setIsHappeningDialogOpen(false);
    };

    const [isAllEventsDialogOpen, setIsAllEventsDialogOpen] = useState(false);
    const [isAllAnnouncementsDialogOpen, setIsAllAnnouncementsDialogOpen] = useState(false);
    const [isSendAnnouncementOpen, setIsSendAnnouncementOpen] = useState(false);

    // Handler to open Send Announcement Dialog
    const handleOpenSendAnnouncement = () => {
        setIsSendAnnouncementOpen(true);
    };

    // Handler to close Send Announcement Dialog
    const handleCloseSendAnnouncement = () => {
        setIsSendAnnouncementOpen(false);
    };

    // Placeholder handler for saving the announcement
    const handleSaveAnnouncement = async (announcement: Announcement) => {
        try {
        // Define the Firestore path
        const announcementsRef = collection(
            db,
            "schools",
            school,
            "facilities",
            options.id,
            "announcements"
        );
    
        // Add the announcement to Firestore
        await addDoc(announcementsRef, announcement);

        // Show success toast
        toast({
            variant: "celebratory",
            title: "Announcement sent!",
            description: "Your announcement has been dispatched.",
        })
    
        // Close the dialog
        setIsSendAnnouncementOpen(false);
        } catch (error) {
        console.error("Error adding announcement: ", error);
        // Show error toast
        toast({
            title: "Error",
            description: "There was an error sending your announcement.",
            variant: "destructive",
        });
        }
    };

    // Inside the ProfilesApplier component

    const groupEventsByDay = (): Record<string, DisplayHappening[]> => {
        const groupedEvents: Record<string, DisplayHappening[]> = {};
      
        options.happenings.forEach((happening) => {
          const startDate = dayjs(happening.starttime).startOf('day');
          const endDate = dayjs(happening.endtime).startOf('day');
      
          // Iterate through each day the event spans
          for (let date = startDate; date.isBefore(endDate) || date.isSame(endDate, 'day'); date = date.add(1, 'day')) {
            const formattedDate = date.format("MM/DD/YYYY");
            if (!groupedEvents[formattedDate]) {
              groupedEvents[formattedDate] = [];
            }
            groupedEvents[formattedDate].push({
              id: happening.id,
              name: happening.name,
              description: happening.description,
              starttime: dayjs(happening.starttime),
              endtime: dayjs(happening.endtime),
              allday: happening.allday,
              resource: happening.resource,
              variant: happening.variant,
            });
          }
        });
      
        // Sort the dates
        const sortedGroupedEvents: Record<string, DisplayHappening[]> = {};
        Object.keys(groupedEvents)
          .sort((a, b) => dayjs(a, "MM/DD/YYYY").unix() - dayjs(b, "MM/DD/YYYY").unix())
          .forEach((date) => {
            sortedGroupedEvents[date] = groupedEvents[date];
          });
      
        return sortedGroupedEvents;
      };      
  

    const onTabChange = (value: React.SetStateAction<string>) => {
        setTab(value);
        if (value == "onetime") {
            setValueDayPicker([]);
        }
    };

    useEffect(() => {
        console.log();
        setBannerEnabled(options.banner?.enabled || false);
        setBannerVariant(options.banner?.variant || "");
        setBannerMessage(options.banner?.message || "");
    }, [options]);

    useEffect(() => {
        ////console.log("Check if disabled is triggered.");
        var shouldBeDisabled = false;
        if (tab == "repeat") {
            ////console.log("does repeat");
            ////console.log(dayPickerValue);
            shouldBeDisabled = !(value && freqNum != 0 && dateRepeat?.from && dateRepeat?.to);
            if (dayPickerValue.length == 0 && value2 == "WEEKLY") {
                shouldBeDisabled = true;
            }
        } else {
            ////console.log("doesn't repeat");
            shouldBeDisabled = !(value && date);
        }
        setIsApplyDisabled(shouldBeDisabled);
    }, [value, date, tab, value2, freqNum, dayPickerValue]);

    const handleCreateNewEvent = (newEvent: CalendarEvent) => {
        const updatedOptions = {
            ...options,
            events: [...options.events, newEvent]
        };
        onUpdate(updatedOptions);
    };

    const handleBannerSave = (open: boolean) => {
        const updatedOptions = {
            ...options,
            banner: {
                enabled: bannerEnabled,
                variant: bannerVariant,
                message: bannerMessage
            }
        };
        onUpdate(updatedOptions);
    }

    const generateDailyOccurrences = (start: Dayjs, end: Dayjs, interval: number): Occurence[] => {
        const occurrences: Occurence[] = [];
        let current = start;

        while (current.isBefore(end) || current.isSame(end, 'day')) {
            occurrences.push({ date: current });
            current = current.add(interval, 'day');
        }

        return occurrences;
    };

    const generateWeeklyOccurrences = (start: Dayjs, end: Dayjs, interval: number, daysSelected: string[]): Occurence[] => {
        const occurrences: Occurence[] = [];
        const dayMapping: { [key: string]: number } = {
            SUNDAY: 0,
            MONDAY: 1,
            TUESDAY: 2,
            WEDNESDAY: 3,
            THURSDAY: 4,
            FRIDAY: 5,
            SATURDAY: 6,
        };

        let current = start.startOf("week");

        // Convert daysSelected to an array of day indices
        const selectedDays = daysSelected.map((day) => dayMapping[day]);

        while (current.isBefore(end) || current.isSame(end, "day")) {
            selectedDays.forEach((day) => {
            const occurrenceDate = current.day(day);
            if (
                (occurrenceDate.isSame(start, "day") || occurrenceDate.isAfter(start, "day")) &&
                (occurrenceDate.isSame(end, "day") || occurrenceDate.isBefore(end, "day"))
            ) {
                occurrences.push({ date: occurrenceDate });
            }
            });
            current = current.add(interval, "week");
        }

        return occurrences;
    };

    return (
        <Card className="bg-white">
            <CardHeader className="!p-3">
                <div className="flex items-center w-full">
                    <div className="flex items-center space-x-2 w-full">
                    <div className="flex items-center space-x-4">
                        <Dialog onOpenChange={(open) => {
                            if (open == false) {
                                setTimeout(() => {
                                    ////console.log("resetting");
                                    setPane(false);
                                    setValueDayPicker([]);
                                    setFreqNum(1);
                                    setValue2("DAILY");
                                    setConflictOptions("option-two");
                                    setDateRepeat({ from: new Date(), to: addDays(new Date(), 5) });
                                    setDate(new Date());
                                    setValue("");
                                    setTab("onetime");
                                    setOpen(false);
                                    setOpen2(false);
                                    setOccurences([]);
                                    setConflicts([]);
                                    setPendingEvent(undefined);
                                }, 100);
                            }    
                        }}>
                            <DialogTrigger asChild>
                                <Button className="self-end" variant="outline">
                                    <div className="flex items-center space-x-0">
                                        <PlusCircle className="h-4 w-4 color-gray align-top mr-2" />
                                        <p>Apply Profile</p>
                                    </div>
                                </Button>
                            </DialogTrigger>
                            <DialogContent className="bg-white w-5/12 max-w-6/12 overflow-y-scroll max-h-[90vh]">
                                <DialogHeader>
                                {pane && (
                                    <DialogTitle>Confirm Application</DialogTitle>
                                )}
                                {!pane && (
                                    <div>
                                    <DialogTitle>Apply Profile</DialogTitle>
                                    <DialogDescription className="mt-1">
                                        You can apply your profiles across as many date ranges as you want. Don't worry: we'll warn you if you're about to overwrite any existing profile assignments.
                                    </DialogDescription>
                                    </div>
                                )}

                                </DialogHeader>

                                {pane && (
                                    <div>

                                    { tab == "repeat" && (
                                        <div>
                                            You're applying "{options.profiles.find(profile => profile.id === pendingEvent?.profileId)?.name}" between {pendingEvent?.startDate.format('MMM D, YYYY')} and {pendingEvent?.endDate?.format('MMM D, YYYY')}. It will repeat {pendingEventBlurb}. This will create {occurences.length} occurrences.
                                            <ScrollArea className="mt-3 h-[200px] rounded-md border">
                                                <div>
                                                <Table className="mt-1">
                                                    <TableHeader>
                                                        <TableRow>
                                                        <TableHead className="w-[100px]">Date</TableHead>
                                                        <TableHead className="w-[100px]">Status</TableHead>
                                                        <TableHead>Conflict</TableHead>
                                                        </TableRow>
                                                    </TableHeader>
                                                    <TableBody>
                                                    {pendingEvent?.recurrence?.computedOccurences?.map((occurence) => {
                                                        const conflict = conflicts.find(conflict => conflict.conflictDate.isSame(occurence.date, 'day'));
                                                        const isConflict = !!conflict;
                                                            return (
                                                                <TableRow>
                                                                    <TableCell className="font-medium">{occurence.date.format("MM/DD/YY")}</TableCell>
                                                                    <TableCell className={isConflict ? "font-bold text-red-500" : "font-bold text-green-500"}>
                                                                        {isConflict ? "CONFLICT" : "GOOD"}
                                                                    </TableCell>
                                                                    <TableCell className="text-gray-400">{isConflict ? "\""+conflict?.profileName+"\" is currently applied." : "no profile currently applied."}</TableCell>
                                                                </TableRow>
                                                            );
                                                    })}
                                                    </TableBody>
                                                </Table>
                                                </div>
                                            </ScrollArea>
                                            { conflicts.length != 0 && (
                                                <div className="mt-5">
                                                    <Alert>
                                                    <ExclamationTriangleIcon className="h-4 w-4" />
                                                    <AlertTitle>Heads up! One or more of the days you're applying this profile to already has a profile applied.</AlertTitle>
                                                    <AlertDescription>
                                                        <RadioGroup className="mt-3 mb-2" value={conflictOptions} onValueChange={(value) => setConflictOptions(value)}>
                                                            <div className="flex items-center space-x-2">
                                                                <RadioGroupItem value="option-one" id="option-one" />
                                                                <Label htmlFor="option-one">Only apply to days without conflicts. Leave other events as is.</Label>
                                                            </div>
                                                            <div className="flex items-center space-x-2">
                                                                <RadioGroupItem value="option-two" id="option-two" />
                                                                <Label htmlFor="option-two">Apply to all listed days. Remove other profiles only on conflicted days.</Label>
                                                            </div>
                                                            <div className="flex items-center space-x-2">
                                                                <RadioGroupItem value="option-three" id="option-three" />
                                                                <Label htmlFor="option-three">Apply to all listed days. Entirely remove all other event(s) which have instances on those days.</Label>
                                                            </div>
                                                        </RadioGroup>
                                                    </AlertDescription>
                                                    </Alert>
                                                </div>
                                            )}
                                        </div>
                                    )}

                                    { tab == "onetime" && (
                                        <div>
                                            You're applying "{options.profiles.find(profile => profile.id === pendingEvent?.profileId)?.name}" to {pendingEvent?.startDate.format('MMM D, YYYY')}. This will create 1 occurrence.
                                            <Table className="mt-1">
                                            <TableHeader>
                                                <TableRow>
                                                <TableHead className="w-[100px]">Date</TableHead>
                                                <TableHead className="w-[100px]">Status</TableHead>
                                                <TableHead>Conflict</TableHead>
                                                </TableRow>
                                            </TableHeader>
                                            <TableBody>
                                            {!pendingEvent?.doesRepeat && pendingEvent?.startDate && (() => {
                                                const conflict = conflicts.find(conflict => conflict.conflictDate.isSame(pendingEvent.startDate, 'day'));
                                                const isConflict = !!conflict;
                                                return (
                                                    <TableRow key={pendingEvent.startDate.toString()}>
                                                        <TableCell className="font-medium">{pendingEvent.startDate.format("MM/DD/YY")}</TableCell>
                                                        <TableCell className={isConflict ? "font-bold text-red-500" : "font-bold text-green-500"}>
                                                            {isConflict ? "CONFLICT" : "GOOD"}
                                                        </TableCell>
                                                        <TableCell className="text-gray-400">{isConflict ? `"${conflict?.profileName}" is currently applied.` : "no profile applied."}</TableCell>
                                                    </TableRow>
                                                );
                                            })()}
                                            </TableBody>
                                            </Table>
                                            { conflicts.length != 0 && (
                                                <div className="mt-5">
                                                    <Alert>
                                                    <ExclamationTriangleIcon className="h-4 w-4" />
                                                    <AlertTitle>Heads up!</AlertTitle>
                                                    <AlertDescription>
                                                        One or more of the days you're applying this profile to already has a profile applied. Feel free to go back and make changes. Or, select how you'd like us to handle these conflicts. 
                                                        <RadioGroup className="mt-3 mb-2" value={conflictOptions} onValueChange={(value) => setConflictOptions(value)}>
                                                            <div className="flex items-center space-x-2">
                                                                <RadioGroupItem value="option-two" id="option-two" />
                                                                <Label htmlFor="option-two">Apply this profile to all listed days. Remove the other profile only on the conflicted days listed above.</Label>
                                                            </div>
                                                            <div className="flex items-center space-x-2">
                                                                <RadioGroupItem value="option-three" id="option-three" />
                                                                <Label htmlFor="option-three">Apply this profile to all listed days. Entirely remove all other event(s) which have instances on those days.</Label>
                                                            </div>
                                                        </RadioGroup>
                                                    </AlertDescription>
                                                    </Alert>
                                                </div>
                                            )}

                                        </div>
                                    )}
      
                                    </div>
                                )}

                                {pane != true && (
                                <div>
                                <div className="items-center">
                                    <Label className="text-right">
                                    </Label>
                                    <Popover open={open} onOpenChange={setOpen}>
                                    <PopoverTrigger asChild>
                                        <Button
                                            variant="outline"
                                            role="combobox"
                                            aria-expanded={open}
                                            className="w-[200px] justify-between"
                                        >
                                            {value
                                                ? options.profiles.find((option) => option.id === value)?.name
                                                : "Select a profile..."}
                                            <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                                        </Button>
                                    </PopoverTrigger>
                                    <PopoverContent className="w-[200px] p-0 bg-white">
                                        <Command>
                                            <CommandInput placeholder="Search option..." className="h-9" />
                                            <CommandEmpty>No profiles found.</CommandEmpty>
                                            <CommandGroup>
                                                <CommandList>
                                                    {options.profiles.map((option) => (
                                                        <CommandItem
                                                            key={option.id}
                                                            value={option.name}
                                                            onSelect={(currentValue) => setValue(option.id)}
                                                        >
                                                            {option.name}
                                                            <CheckCircledIcon
                                                                className={cn(
                                                                    "ml-auto h-4 w-4",
                                                                    value === option.id ? "opacity-100" : "opacity-0",
                                                                )}
                                                                style={{ color: option.color }}
                                                            />
                                                        </CommandItem>
                                                    ))}
                                                </CommandList>
                                            </CommandGroup>
                                        </Command>
                                    </PopoverContent>
                                </Popover>
                                </div>
                                
                                <Tabs value={tab} onValueChange={onTabChange} className="mt-4 w-[400px]">
                                    <TabsList className="bg-gray-100">
                                        <TabsTrigger value="onetime">One-Time</TabsTrigger>
                                        <TabsTrigger value="repeat">Repeats</TabsTrigger>
                                    </TabsList>
                                    <TabsContent value="onetime">
                                        <Popover>
                                            <PopoverTrigger asChild>
                                                <Button
                                                variant={"outline"}
                                                className={cn(
                                                    "mt-2 w-[280px] justify-start text-left font-normal",
                                                    !date && "text-muted-foreground"
                                                )}
                                                >
                                                <CalendarIcon className="mr-2 h-4 w-4" />
                                                {date ? format(date, "PPP") : <span>Pick a date.</span>}
                                                </Button>
                                            </PopoverTrigger>
                                            <PopoverContent className="w-auto p-0">
                                                <Calendar className="bg-white"
                                                mode="single"
                                                selected={date}
                                                onSelect={setDate}
                                                initialFocus
                                                />
                                            </PopoverContent>
                                        </Popover>
                                    </TabsContent>
                                    <TabsContent value="repeat">
                                    
                                    <div className="grid grid-flow-col auto-cols-max items-center">
                                        <Label htmlFor="description" className="text-left mr-2">
                                            Between
                                        </Label>
                                        <div className={cn("grid gap-2")}>
                                            <Popover>
                                                <PopoverTrigger asChild>
                                                <Button
                                                    id="date"
                                                    variant={"outline"}
                                                    className={cn(
                                                    "mt-2 mb-2 w-[300px] justify-start text-left font-normal",
                                                    !date && "text-muted-foreground"
                                                    )}
                                                >
                                                    <CalendarIcon className="mr-2 h-4 w-4" />
                                                    {dateRepeat?.from ? (
                                                    dateRepeat.to ? (
                                                        <>
                                                        {format(dateRepeat.from, "LLL dd, y")} -{" "}
                                                        {format(dateRepeat.to, "LLL dd, y")}
                                                        </>
                                                    ) : (
                                                        format(dateRepeat.from, "LLL dd, y")
                                                    )
                                                    ) : (
                                                    <span>Pick a date.</span>
                                                    )}
                                                </Button>
                                                </PopoverTrigger>
                                                <PopoverContent className="w-auto p-0" align="start">
                                                <Calendar
                                                    className="bg-white"
                                                    initialFocus
                                                    mode="range"
                                                    defaultMonth={dateRepeat?.from}
                                                    selected={dateRepeat}
                                                    onSelect={setDateRepeat}
                                                    numberOfMonths={2}
                                                />
                                                </PopoverContent>
                                            </Popover>
                                            </div>
                                        </div>

                                        <div className="grid grid-flow-col auto-cols-max items-center">
                                            <Label htmlFor="description" className="text-left mr-2">
                                               Repeats every
                                             </Label>
                                            <InputNumber className="mt-2 mb-2" value={freqNum} onChange={(value) => setFreqNum(Number(value) ?? 1)} style={{ width: 75 }} max={365} min={1} />
                                            <Popover open={open2} onOpenChange={setOpen2}>
                                            <PopoverTrigger asChild>
                                                <Button
                                                variant="outline"
                                                role="combobox"
                                                aria-expanded={open2}
                                                className="mt-2 mb-2 ml-2 w-[200px] justify-between"
                                                >
                                                {value2
                                                    ? frequencies.find((framework) => framework.value === value2)?.label
                                                    : "Select frequency..."}
                                                <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                                                </Button>
                                            </PopoverTrigger>
                                            <PopoverContent className="w-[200px] p-0">
                                                <Command>
                                                <CommandList>
                                                    <CommandEmpty>No options found.</CommandEmpty>
                                                    <CommandGroup>
                                                    {frequencies.map((framework) => (
                                                        <CommandItem
                                                        key={framework.value}
                                                        value={framework.value}
                                                        onSelect={(currentValue) => {
                                                            setValue2(currentValue)
                                                            setOpen2(false)
                                                        }}
                                                        >
                                                        {framework.label}
                                                        <CheckIcon
                                                            className={cn(
                                                            "ml-auto h-4 w-4",
                                                            value === framework.value ? "opacity-100" : "opacity-0"
                                                            )}
                                                        />
                                                        </CommandItem>
                                                    ))}
                                                    </CommandGroup>
                                                </CommandList>
                                                </Command>
                                            </PopoverContent>
                                            </Popover>
                                        </div>

                                        {value2 === "WEEKLY" && (
                                            <div className="grid grid-flow-col auto-cols-max items-center">
                                                <Label htmlFor="description" className="text-left mr-2">
                                                On
                                                </Label>
                                                <ToggleGroup onValueChange={(value) => setValueDayPicker(value)} type="multiple" className="ml-2 mt-2 mb-2" variant="outline">
                                                    <ToggleGroupItem value="SUNDAY">S</ToggleGroupItem>
                                                    <ToggleGroupItem value="MONDAY">M</ToggleGroupItem>
                                                    <ToggleGroupItem value="TUESDAY">T</ToggleGroupItem>
                                                    <ToggleGroupItem value="WEDNESDAY">W</ToggleGroupItem>
                                                    <ToggleGroupItem value="THURSDAY">T</ToggleGroupItem>
                                                    <ToggleGroupItem value="FRIDAY">F</ToggleGroupItem>
                                                    <ToggleGroupItem value="SATURDAY">S</ToggleGroupItem>
                                                </ToggleGroup>
                                            </div>
                                        )}
                                        {value2 === "MONTHLY" && (
                                            <div>
                                                This feature is not presently available.
                                            </div>
                                        )}

                                    </TabsContent>
                                </Tabs> 
                                </div>
                                )}
                                
                                <DialogFooter>
                                    { !pane && (
                                    <Button onClick={() => {

                                        if (tab == "onetime") {
                                            const profile = options.profiles.find((option) => option.id === value) as HoursProfile;
                                            const startDate = dayjs(date).startOf("day");
                                            const occurences = [{ date: startDate }];
                                            setOccurences(occurences);
                                            const detectedConflicts = detectConflicts(options, occurences);
                                            setConflicts(detectedConflicts);

                                            const newCalendarEvent: CalendarEvent = {
                                                id: uuidv4(),
                                                profileId: profile.id,
                                                startDate: startDate,
                                                doesRepeat: false
                                            };
                                            
                                            setPendingEventBlurb("");
                                            setPendingEvent(newCalendarEvent);
                                            setPane(true);

                                        } else {
                                            if (value2 == "DAILY") {
                                                const profile = options.profiles.find((option) => option.id === value) as HoursProfile;
                                                const startDate = dayjs(dateRepeat?.from).startOf("day");
                                                const endDate = dayjs(dateRepeat?.to).startOf("day");
                                                const freqRepeat = freqNum;
                                                const occurrences = generateDailyOccurrences(startDate, endDate, freqRepeat);
                                                setOccurences(occurrences);
                                                const detectedConflicts = detectConflicts(options, occurrences);
                                                setConflicts(detectedConflicts);

                                                const newCalendarEvent: CalendarEvent = {
                                                    id: uuidv4(),
                                                    profileId: profile.id,
                                                    startDate: startDate,
                                                    endDate: endDate,
                                                    doesRepeat: true,
                                                    recurrence: {
                                                        frequency: "DAILY",
                                                        interval: freqRepeat,
                                                        computedOccurences: occurrences,
                                                        actualOccurences: occurrences
                                                    }
                                                };

                                                setPendingEventBlurb("every " + freqNum + " day(s)");
                                                setPendingEvent(newCalendarEvent);
                                                setPane(true);

                                                /**if (detectedConflicts.length > 0) {
                                                    //console.log("not creating event");
                                                } else {
                                                    const newCalendarEvent: CalendarEvent = {
                                                        id: uuidv4(),
                                                        profile: profile,
                                                        startDate: startDate,
                                                        endDate: endDate,
                                                        doesRepeat: true,
                                                        recurrence: {
                                                            frequency: "DAILY",
                                                            interval: freqRepeat,
                                                            computedOccurences: occurrences,
                                                            actualOccurences: occurrences
                                                        }
                                                    };
                                                    handleCreateNewEvent(newCalendarEvent);
                                                }**/

                                            } else if (value2 == "WEEKLY") {
                                                const profile = options.profiles.find((option) => option.id === value) as HoursProfile;
                                                const startDate = dayjs(dateRepeat?.from).startOf("day");
                                                const endDate = dayjs(dateRepeat?.to).startOf("day");
                                                const freqRepeat = freqNum;
                                                const daysSelected = dayPickerValue;
                                                const occurrences = generateWeeklyOccurrences(startDate, endDate, freqRepeat, daysSelected);
                                                setOccurences(occurrences);
                                                const detectedConflicts = detectConflicts(options, occurrences);
                                                setConflicts(detectedConflicts);

                                                const newCalendarEvent: CalendarEvent = {
                                                    id: uuidv4(),
                                                    profileId: profile.id,
                                                    startDate: startDate,
                                                    endDate: endDate,
                                                    doesRepeat: true,
                                                    recurrence: {
                                                        frequency: "WEEKLY",
                                                        interval: freqRepeat,
                                                        daysOfWeek: daysSelected as any,
                                                        computedOccurences: occurrences,
                                                        actualOccurences: occurrences
                                                    }
                                                };

                                                setPendingEventBlurb("every " + freqNum + " week(s) on " + dayPickerValue.join(", "));
                                                setPendingEvent(newCalendarEvent);
                                                setPane(true);

                                                /**
                                                const newCalendarEvent: CalendarEvent = {
                                                    id: uuidv4(),
                                                    profile: profile,
                                                    startDate: startDate,
                                                    endDate: endDate,
                                                    doesRepeat: true,
                                                    recurrence: {
                                                        frequency: "WEEKLY",
                                                        interval: freqRepeat,
                                                        daysOfWeek: daysSelected as any,
                                                        computedOccurences: occurrences,
                                                        actualOccurences: occurrences
                                                    }
                                                };
                                                
                                                handleCreateNewEvent(newCalendarEvent);
                                                **/
                                            }
                                        }
                                        
                                    }}
                                    disabled={isApplyDisabled}
                                    >Next</Button>
                                    )}
                                    { pane && (
                                        <div>
                                            <Button onClick={() => {
                                                setPane(false);
                                            }}
                                            variant="outline"
                                            >Back</Button>
                                            <DialogClose asChild>
                                                <Button className="ml-2" disabled={conflicts.length != 0 && conflictOptions == ""} onClick={() => {

                                                    if (conflicts.length != 0 && conflictOptions == "option-two") {
                                                        //apply profile to all listed days. Remove the other profile only on the conflicted days listed above.
                                                        onUpdate(optionTwo(options, conflicts, pendingEvent as CalendarEvent));
                                                    } else if (conflicts.length != 0 && conflictOptions == "option-three") {
                                                        onUpdate(optionThree(options, conflicts, pendingEvent as CalendarEvent));
                                                    } else {
                                                        //done
                                                        handleCreateNewEvent(pendingEvent as CalendarEvent);
                                                    }

                                                    toast({
                                                        title: "Profile applied!",
                                                        description: "The profile has been applied to the selected date(s).",
                                                    })

                                                }}
                                                >Confirm</Button>
                                            </DialogClose>
                                        </div>
                                    )}
                                </DialogFooter>
                            </DialogContent>
                        </Dialog>
                    </div>
        
                        <Dialog>
                            <DialogTrigger asChild>
                                <CircleHelp className="h-4 w-4 mt-1 color-gray align-middle" style={{ color: 'gray' }} />
                            </DialogTrigger>
                            <DialogContent className="bg-white w-4/12 max-w-6/12 overflow-y-scroll max-h-[90vh]">
                                <DialogHeader>
                                    <DialogTitle>What is this?</DialogTitle>
                                    <DialogDescription>
                                        You've created profiles for your building. Now, you can apply them to different date ranges. A building can only have one active profile per day. You can choose to apply a profile to a single day or across a range of dates. For example, you can apply a "School Hours" profile to be active for all weekdays between the start and end of your term. Or, Taco Tuesday can be set to repeat on just the first Tuesday of every month.
                                    </DialogDescription>
                                </DialogHeader>
                            </DialogContent>
                        </Dialog>

                        <Separator orientation="vertical"/>
                        <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button className="self-end" variant="outline">
                            <div className="flex items-center space-x-0">
                                <Goal className="h-4 w-4 color-gray align-top mr-2" />
                                <p>Events & Deals</p>
                            </div>
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent>
                            <DropdownMenuItem onSelect={handleOpenHappeningDialog}>
                            Add new
                            </DropdownMenuItem>
                            <DropdownMenuItem onSelect={() => setIsAllEventsDialogOpen(true)}>
                            View all
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                        </DropdownMenu>

                        {/* Integrate HappeningDialog Outside the Dropdown */}
                        <HappeningDialog
                            building={options}
                            onSave={onUpdate}
                            open={isHappeningDialogOpen}
                            onOpenChange={setIsHappeningDialogOpen}
                        />

                        <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button className="self-end" variant="outline">
                                <div className="flex items-center space-x-0">
                                    <MessageSquarePlusIcon className="h-4 w-4 color-gray align-top mr-2" />
                                    <p>Announcements</p>
                                </div>
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent>
                            <DropdownMenuItem onSelect={handleOpenSendAnnouncement}>
                             Send new
                            </DropdownMenuItem>
                            <DropdownMenuItem onSelect={() => setIsAllAnnouncementsDialogOpen(true)}>
                                View history
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                        </DropdownMenu>
                        
                    </div>
                    <Separator orientation="vertical"/>

                    <Dialog onOpenChange={(open) => handleBannerSave(open)}>
                        <DialogTrigger asChild>
                        <div className="ml-auto flex items-center space-x-4 px-4">
                        <Button variant="outline" className="self-end">
                            <Switch className="mr-3" checked={bannerEnabled}/>
                            Banner
                        </Button>
                        </div>
                        </DialogTrigger>
                        <DialogContent className="bg-white w-5/12 max-w-6/12 overflow-y-scroll max-h-[90vh]">
                            <DialogHeader>
                                <DialogTitle>Banner Message</DialogTitle>
                                <DialogDescription className="pb-2">
                                    Banners are displayed at the top of a facility's page and remain visible until removed or changed. Use them to communicate critical information to users like planned closures or changes in service.
                                </DialogDescription>

                                <div className="grid w-full gap-1.5">
                                    <Label htmlFor="message">Banner enabled</Label>
                                    <Switch
                                        checked={bannerEnabled}
                                        onCheckedChange={setBannerEnabled}
                                    />
                                </div>

                                <div className="grid w-full pt-4 gap-1.5">
                                    <Label htmlFor="message">Banner variant</Label>
                                    <Select value={bannerVariant} onValueChange={(value) => setBannerVariant(value)}>
                                        <SelectTrigger className="w-[180px]">
                                            <SelectValue placeholder="Select one..." />
                                        </SelectTrigger>
                                        <SelectContent>
                                            <SelectItem value="info">Info</SelectItem>
                                            <SelectItem value="alert">Alert</SelectItem>
                                        </SelectContent>
                                    </Select>
                                </div>
                                
                                <div className="grid w-full pt-4 gap-1.5">
                                    <Label htmlFor="message">Your message</Label>
                                    <Textarea
                                        className="min-h-[100px]"
                                        placeholder={"Type your banner message here..."}
                                        id="banner"
                                        value={bannerMessage}
                                        onChange={(e) => setBannerMessage(e.target.value)}
                                    />
                                </div>
                            </DialogHeader>
                        </DialogContent>
                    </Dialog>

                    <AllAnnouncementsDialog
                        open={isAllAnnouncementsDialogOpen}
                        onOpenChange={setIsAllAnnouncementsDialogOpen}
                        schoolId={school}
                        facilityId={options.id}
                    />

                    <SendAnnouncementDialog
                        open={isSendAnnouncementOpen}
                        onOpenChange={setIsSendAnnouncementOpen}
                        onSave={handleSaveAnnouncement}
                    />

                    <AllEventsDialog
                        open={isAllEventsDialogOpen}
                        onOpenChange={setIsAllEventsDialogOpen}
                        groupedEvents={groupEventsByDay()}
                    />
                    
                </div>
            </CardHeader>
        </Card>
    )

}
