import { Auth } from '../../components/auth';
import '../../App.css';

function LoginPage() {
    return (
        <div className="App">
            <div className="relative">
                <div className="relative z-10">
                    <Auth />
                </div>
            </div>
        </div>
    );
}

export default LoginPage;