import React, { useEffect, useRef, useState } from "react";
import { Building, DayNote } from "../../lib/types";
import { Textarea } from "../ui/textarea";
import { Dayjs } from "dayjs";

interface DayNoteProps {
    building: Building;
    selectedDate: Dayjs; // Assuming you have selectedDate available
    onUpdateNote: (updatedNotes: Array<DayNote>) => void; // Function to update notes in parent component
}

const debounce = <T extends (...args: any[]) => void>(func: T, wait: number): (...args: Parameters<T>) => void => {
    let timeout: ReturnType<typeof setTimeout>;
    return (...args: Parameters<T>) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => func(...args), wait);
    };
};

const DayNoteComponent: React.FC<DayNoteProps> = ({ building, selectedDate, onUpdateNote }) => {
    // Find the note for the selected date
    const initialNoteIndex = building.notes.findIndex(note => note.date.isSame(selectedDate, 'day'));
    const initialNote = initialNoteIndex !== -1 ? building.notes[initialNoteIndex].note : '';

    const [note, setNote] = useState(initialNote);
    const [firstClick, setFirstClick] = useState(true); // Track initial click

    // Use a ref to keep track of the latest note value
    const noteRef = useRef(note);
    useEffect(() => {
        noteRef.current = note;
    }, [note]);

    // Debounce function
    const debounceUpdateNote = useRef(debounce((updatedNotes: Array<DayNote>) => {
        onUpdateNote(updatedNotes);
    }, 1000)).current;

    // Effect to update note state when selectedDate or building.notes change
    useEffect(() => {
        const newNoteIndex = building.notes.findIndex(note => note.date.isSame(selectedDate, 'day'));
        const newNote = newNoteIndex !== -1 ? building.notes[newNoteIndex].note : '';
        setNote(newNote);
        noteRef.current = newNote; // Update ref with the new note value
    }, [selectedDate, building.notes]);

    const updateNotes = (updatedNote: string): Array<DayNote> => {
        const newNoteIndex = building.notes.findIndex(note => note.date.isSame(selectedDate, 'day'));
        const updatedNotes = [...building.notes];
        if (newNoteIndex !== -1) {
            updatedNotes[newNoteIndex] = { ...building.notes[newNoteIndex], note: updatedNote };
        } else {
            updatedNotes.push({ id: `${selectedDate.format('YYYY-MM-DD')}-${building.id}`, date: selectedDate, note: updatedNote });
        }
        return updatedNotes;
    };

    // Function to handle note change
    const handleNoteChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const updatedNote = event.target.value;
        setNote(updatedNote);
        if (firstClick) {
            onUpdateNote(updateNotes(updatedNote));
            setFirstClick(false); // Disable first click action after initial update
        } else {
            debounceUpdateNote(updateNotes(updatedNote));
        }
    };

    // Function to handle blur event
    const handleNoteBlur = () => {
        onUpdateNote(updateNotes(noteRef.current));
    };

    return (
        <Textarea
            className="mt-5 min-h-[90px]"
            placeholder={"Type your message here..."}
            id="message-2"
            value={note}
            onChange={handleNoteChange}
            onBlur={handleNoteBlur}
            onClick={() => {
                if (firstClick) {
                    onUpdateNote(updateNotes(noteRef.current));
                    setFirstClick(false); // Disable first click action after initial update
                }
            }}
        />
    );
};

export default DayNoteComponent;