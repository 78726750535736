import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCr6Ojdqy6OI-ebl0kPdAS4T22Qxj-aHL4",
  authDomain: "newgrange-b0285.firebaseapp.com",
  projectId: "newgrange-b0285",
  storageBucket: "newgrange-b0285.appspot.com",
  messagingSenderId: "174550918929",
  appId: "1:174550918929:web:9bd7b29181be58d2f60eb0",
  measurementId: "G-DTSZS04QJR",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app, "us-central1");

export { auth, db, functions, httpsCallable };
