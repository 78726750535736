import '../../App.css';
import MenuBar from '../../components/menu-bar';
import { Link, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import {
    Card,
    CardContent,
  } from "../../components/ui/card"
import { fetchBuildings } from '../../lib/fetchbuildings';
import { Building } from '@/lib/types';
import { useState, useEffect } from 'react';
import { DataTable } from './Facilities/data-table';
import { getColumns } from './Facilities/columns';
import React from 'react';
import BuildingDialog from '../../components/general/building-dialog';
import { updateBuildingInFirebase } from '../../lib/savebuilding';
import { useAuth } from '../../context/AuthContext';

const getCurrentDate = () => {
    const now = dayjs();
    const day = now.date();
    let suffix;

    if (day % 10 === 1 && day !== 11) {
        suffix = 'st';
    } else if (day % 10 === 2 && day !== 12) {
        suffix = 'nd';
    } else if (day % 10 === 3 && day !== 13) {
        suffix = 'rd';
    } else {
        suffix = 'th';
    }

    return now.format(`MMMM D[${suffix}].`);
};

function AdministrationPage() {

    const [buildingOptions, setBuildingOptions] = useState<Building[]>([]);
    const [dialogOpen, setDialogOpen] = useState(false); // Manage dialog open state
    const { user } = useAuth();
    const school = user?.customClaims?.school;

    useEffect(() => {
        async function loadBuildings() {
            const buildings = await fetchBuildings(school);
            setBuildingOptions(buildings);
        }

        loadBuildings();
    }, []);

    const refreshBuildings = async () => {
        const buildings = await fetchBuildings(school);
        setBuildingOptions(buildings);
    };

    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const [selectedBuilding, setSelectedBuilding] = React.useState<Building | null>(null);

    const handleOpenDialog = (building: Building) => {
        setSelectedBuilding(building); // Set the building to be edited
        setIsDialogOpen(true); // Open the dialog
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false); // Close the dialog
        setSelectedBuilding(null); // Clear the selected building
    };

    return (
        <div className="flex flex-col h-screen">
        <MenuBar />
        <div className="flex flex-grow">
    
            <div className="space-y-4 pl-[4rem] pt-10 flex flex-col justify-between h-full w-1/6">
    
                <div>
                    <h2 className="text-3xl font-bold tracking-tight">Admin</h2>
                    <p className="text-gray-500 text-sm font-semibold mb-5">{getCurrentDate()}</p>

                    <nav className="grid gap-3 text-sm pt-4 text-muted-foreground">
                        <Link to="#" className='font-semibold text-black'>Edit Facilities</Link>
                        <Link to={`/${school}/admin/users`}>Edit Users</Link>
                        <Link to="#">Manage Plan</Link>
                    </nav>
                </div>
    
                <div>
                    <nav className="grid gap-4 text-sm pb-[4rem] text-muted-foreground">
                        <Link to="#">Documentation</Link>
                        <Link to="#">Get Support</Link>
                    </nav>
                </div>
    
            </div>
            <div className="space-y-4 pl-[4rem] pt-10 w-full">
                <h2 className="text-4xl font-bold tracking-tight">Edit Facilities</h2>
                <Card className="w-11/12">
                    <CardContent className="mt-7">
                    <DataTable 
                        columns={getColumns(handleOpenDialog)}
                        data={buildingOptions} 
                        refreshBuildings={refreshBuildings} 
                        /> 
                    </CardContent>
                </Card>
                {selectedBuilding && (
                    <BuildingDialog
                    building={selectedBuilding}
                    facilityOptions={buildingOptions} // Pass your facility options here
                    onSave={(updatedBuilding) => {
                        try {
                            updateBuildingInFirebase(updatedBuilding, school); // Save the updated building to Firebase
                            refreshBuildings(); // Refresh the table after saving
                            handleCloseDialog(); // Close the dialog after save
                          } catch (error) {
                            console.error("Error saving building:", error);
                          }
                    }}
                    open={isDialogOpen} // Control the dialog's open state
                    onOpenChange={handleCloseDialog} // Close the dialog when requested
                    />
                )}
            </div>
        </div>
    </div>
    );
}

export default AdministrationPage;