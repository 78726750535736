import { Tabs, TabsList, TabsTrigger, TabsContent } from "../../components/ui/tabs";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter, DialogClose } from "../../components/ui/dialog";
import { Button } from "../../components/ui/button";
import { Building } from "../../lib/types";
import BuildingInfoWidget from "./widget";

// Inside your HoursPage component
export const ShareOptionsDialog: React.FC<{ open: boolean, onOpenChange: (open: boolean) => void, school: string, id: string, facility: Building }> = ({ open, onOpenChange, school, id, facility }) => {
  
    const embedCode = `
    <div class="building-widget" data-building-id="${facility.id}">
      <h3 class="building-name">${facility.fullname}</h3>
      <p class="building-profile">Profile: ${facility.shortname}</p>
    </div>
    <script>
      (function() {
        const buildingId = document.querySelector('.building-widget').getAttribute('data-building-id');
        fetch('https://your-server.com/api/buildings/' + buildingId)
          .then(response => response.json())
          .then(data => {
            document.querySelector('.building-name').textContent = data.fullname;
            document.querySelector('.building-profile').textContent = 'Profile: ' + data.currentProfile;
          });
      })();
    </script>`;

    return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="bg-white w-6/12 max-w-6/12 overflow-y-auto max-h-[90vh]">
        <DialogHeader>
          <DialogTitle>Share Options</DialogTitle>
          <DialogDescription>
            Your facility is up-to-date on our app and website. Below are additional ways to access it.
          </DialogDescription>
        </DialogHeader>
        
        <Tabs defaultValue="embed" className="w-full">
          <TabsList className="grid grid-cols-3 w-full">
            <TabsTrigger value="link" className="text-center text-xs">Link & QR</TabsTrigger>
            <TabsTrigger value="embed" className="text-center text-xs">Embeddable Card</TabsTrigger>
            <TabsTrigger value="api" className="text-center text-xs">API Request</TabsTrigger>
          </TabsList>

          <TabsContent value="link">
            <div className="p-2 text-sm">
              <p>Wait for URL paths to be set up.</p>
              {/* Add more content here later */}
            </div>
          </TabsContent>

          <TabsContent value="embed">
            <div className="m-2 mt-7">
            <BuildingInfoWidget school={school} facilityId={facility.id}/>
            </div>
          </TabsContent>

          <TabsContent value="api">
            <div className="pt-4 text-sm">
              <p><span className="font-semibold text-orange-600">This feature is coming soon.</span> Use our API to fetch data on this facility for your own purposes.</p>
              {/* Add more content here later */}
            </div>
          </TabsContent>
        </Tabs>

        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">Close</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
