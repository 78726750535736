import { useToast } from "../ui/use-toast"; // Import your toast hook
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator } from "../ui/dropdown-menu";
import { Button } from "../ui/button";
import { MoreHorizontal } from "lucide-react";
import { Link } from "react-router-dom";
import { backupAndDeleteFacilities } from "../../lib/deletefacilities";
import { Building } from "../../lib/types";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogClose,
  DialogTrigger,
} from "../ui/dialog"
import { useState } from "react";
import { useAuth } from "../../context/AuthContext";

interface BuildingActionsProps {
    building: Building; // The building object
    refreshBuildings: () => void | Promise<void>; // Can return void or a Promise
    handleOpenDialog: (building: Building) => void; // Function to handle opening the dialog
  }
  
  const BuildingActions: React.FC<BuildingActionsProps> = ({ building, refreshBuildings, handleOpenDialog }) => {
    const { toast } = useToast();
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const { user } = useAuth();
    const school = user?.customClaims?.school;
  
    const handleDelete = async () => {
      try {
        await backupAndDeleteFacilities([building.id], school); // Delete building
        console.log(`Facility ${building.id} has been deleted successfully.`);
        refreshBuildings(); // Refresh the table
        toast({
          variant: "destructive",
          title: "Facility deleted.",
          description: "It's no longer in our system.",
        });
        setDeleteConfirmationOpen(false); // Close the delete confirmation dialog
      } catch (error) {
        console.error(`Error deleting facility ${building.id}:`, error);
      }
    };
  
    return (
      <>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="h-8 w-8 p-0">
              <span className="sr-only">Open menu</span>
              <MoreHorizontal className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuLabel>Actions</DropdownMenuLabel>
            <DropdownMenuItem onClick={() => console.log(building.id)}>
              Edit managers
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem onClick={() => handleOpenDialog(building)}>
              Edit information
            </DropdownMenuItem>
            <Link to={`/${school}/hours?buildingId=${building.id}`}>
              <DropdownMenuItem className="cursor-pointer">
                Edit open hours
              </DropdownMenuItem>
            </Link>
            <DropdownMenuSeparator />
            <DropdownMenuItem
              className="text-red-500 cursor-pointer"
              onClick={() => setDeleteConfirmationOpen(true)}
            >
              Delete facility
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
  
        {/* Render the dialog outside of the DropdownMenu */}
        <Dialog open={deleteConfirmationOpen} onOpenChange={setDeleteConfirmationOpen}>
          <DialogContent className="w-5/12">
            <DialogHeader>
              <DialogTitle>Confirm Deletion</DialogTitle>
              <DialogDescription>
                Are you sure you want to delete this facility? You can't undo this.
              </DialogDescription>
            </DialogHeader>
            <DialogFooter>
              <DialogClose asChild>
                <Button type="button" onClick={handleDelete} variant="destructive">
                  Confirm Delete
                </Button>
              </DialogClose>
              <DialogClose asChild>
                <Button type="button" onClick={() => setDeleteConfirmationOpen(false)}>
                  Cancel
                </Button>
              </DialogClose>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </>
    );
  };
  
  export default BuildingActions;  