import { useAuth } from "../context/AuthContext";
import { MainNav } from "./main-nav"
import { UserNav } from "./user-nav"
import { Link } from "react-router-dom"

export default function MenuBar() {
    
    const { user } = useAuth();
    const school = user?.customClaims?.school;

    return (
        <div className="border-b bg-white">
            <div className="flex h-[4.3em] items-center px-4">
                <Link to={`/${school}`}>
                    <h1 className="truenoTitle text-2xl leading-6 mb-0 mx-6">CampusCommons</h1>
                </Link>
                <MainNav />
                <div className="ml-auto flex items-center space-x-4 px-4">
                    <UserNav />
                </div>
            </div>
        </div>
    )
}